import {GROUP_USERS_URL} from '../../Utils/Constants';
import Tooltip from '@mui/material/Tooltip';
import {Link} from 'react-router-dom';

function ActiveGroup(props) {
    const reference = props.reference;
    const openInNewTab = props.openInNewTab;
    const name = props.name;
    return ( 
        <Tooltip title={`View all ${name} users`}>
        <Link className="lykiocss-activegroup-link" to={`${GROUP_USERS_URL}/${reference}`} target={openInNewTab ? "_blank" : ''} rel="noopener noreferrer">
            <span className="lykiocss-activegroup lykiocss-active-hover lykiocss-text-shadow">{name}</span>
        </Link>
        </Tooltip>
     );
}

export default ActiveGroup;

//onClick={()=> navigate(`${GROUP_USERS_URL}/${reference}`)}