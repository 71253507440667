import { useState, useEffect } from 'react';
import axios from 'axios';
import { AUTHORIZED_URL_PREFIX, SEARCH_URL, AxiosConfig } from '../../Utils/Constants';
import './search.css';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Story from '../stories/Story';
import React from 'react';
import Loading from '../common/Loading';
import { notify, pleaseRefreshError } from "../../Utils/Notificator";
import ProfileCard from '../common/ProfileCard';
import t from "../../Utils/Translation";


const Search = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ searchTerms, setSearchTerms ] = useState([]);
	const [ type, setType ]= useState();
	const [ result, setResult ]= useState();

	useEffect(() => {
		const fetchSearchData = async () => {
			try {
				const { data } = await axios.get(`${AUTHORIZED_URL_PREFIX}${SEARCH_URL}`, AxiosConfig());
				setSearchTerms(data.searchTerms);
				setIsLoading(false);
				if (!data.success) {
					notify(pleaseRefreshError);
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchSearchData();
	}, []);


	return <div className="lykioccs-main-content">
	{isLoading ? (
	  <Loading />
	) : (
		<React.Fragment>
		 	<div style={{marginTop:'1.5em'}}>
		 		<Row className="justify-content-md-center">
		 			<Col xs={12} md={6}>
						<div className='lykiocss-form'>
		 				<Form.Group controlId="title">
		 				<Autocomplete
		 				disablePortal
		 				id="combo-box-searchTerms"
		 				options={searchTerms}
		 				onChange={(event, value) => {
		 					console.log(value ? value.item.type : undefined)
		 					setType(value ? value.item.type : undefined)
		 					setResult(value ? value.item.result : undefined)
		 				}	
		 				}
		 				renderInput={(params) => (
		 					<TextField {...params} label={t("Search for lessons, stories and users")} />
		 				)}
		 				/>
		 				</Form.Group>
						</div>
		 			</Col>
		 		</Row>
		 	</div>

		 	<div className='lykiocss-search-result-center'>
		 		{!result ? <Form className="lykiocss-form lykiocss-form-border lykiocss-form-size">
					 <div className='lykiocss-title-light lykiocss-center'>{t('Selected results will show up here')}</div><br/>
				 </Form>  : <Form className="lykiocss-form lykiocss-form-border lykiocss-form-size">
					 <Result type={type} result={result} />
					 </Form>}
		 	</div>
	 </React.Fragment>
	)}
	 </div>
		
};

const Result = (props)=>{
	const type= props.type;
	const result=props.result;

	return (<div>
		{(type==='story' || type==='unit') && <Story story={result} />}
		{type==='user' && <ProfileCard user={result} showName={true}/>}
		</div>
	)
}

export default Search;
