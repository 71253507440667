import React from 'react';
import { CSVLink } from 'react-csv';
import Button from '@mui/material/Button';
import TextSnippetSharpIcon from '@mui/icons-material/TextSnippetSharp';
import t from "../../Utils/Translation";

const ExportReactCSV = ({csvData, fileName, buttonText}) => {

    return (
        <Button variant="text" className='floatEnd'>
            <CSVLink data={csvData} filename={fileName}><TextSnippetSharpIcon/>&nbsp;{t(buttonText)}</CSVLink>
        </Button>
    )
}

export default ExportReactCSV;


// type of data the export button expects
// [
//     {
//     rank: '1',
//     name: 'john',
//     email: 'john@mailinator.com',
//     logins: '11',
//     totalviews: '12',
//     unitviews: '13',
//     storyviews: '14',
//     group: 'somegroup',
//     department: 'somedepartment'
//     },
//     {
//     rank: '2',
//     name: 'jack',
//     email: 'jack@mailinator.com',
//     logins: '21',
//     totalviews: '22',
//     unitviews: '23',
//     storyviews: '24',
//     group: 'somegroup',
//     department: 'somedepartment'
//     },
// ]