import axios from 'axios';
import { AUTHORIZED_URL_PREFIX, VIEW_UNIT_URL, AxiosConfig } from './Constants';

async function registerUnitViewed({ unitId, progress, score }) {
	try {
		console.log(`registerUnitViewed-before call: about to make a call to /api/private${VIEW_UNIT_URL}`);
		const { data } = await axios.post(
			`${AUTHORIZED_URL_PREFIX}${VIEW_UNIT_URL}`,
			{ unitId, progress, score },
			AxiosConfig()
		);
		if (data.success === true) {
			console.log('Successfully added unit usage');
		}
	} catch (error) {
		console.log('Oops, something went wrong');
	}
}

export default registerUnitViewed;
