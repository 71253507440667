import { withNamespaces } from "react-i18next";
import { Link } from "react-router-dom";
import "./contentManagement.css";
import {
  CONTENT_MANAGEMENT_MODERATE_WITHOUTPARAM_URL,
  CONTENT_MANAGEMENT_CATEGORIES_URL,
  CONTENT_MANAGEMENT_CREATE_URL,
  CONTENT_MANAGEMENT_ADDS3_URL,
  INITIAL_SETUP_URL,
} from "../../Utils/Constants";
import t from "../../Utils/Translation";
import LykioButton from "../common/LykioButton";
import Form from "react-bootstrap/Form";

const ContentManagement = () => {
  const contentModerationUrl = `${CONTENT_MANAGEMENT_MODERATE_WITHOUTPARAM_URL}/System`;

  return (
    <div className="lykioccs-main-content">
      <div className="lykiocss-center">
        <Form className="lykiocss-form-border lykiocss-form-size">
          <h3 className="lykiocss-center">{t("Content Management")}</h3>
          <Form.Label>{t("Use this option to add categories")}</Form.Label>
          <Form.Group className="mb-3" controlId="categories">
            <Link className="lykiocss-unstyled-link" to={CONTENT_MANAGEMENT_CATEGORIES_URL}>
              <LykioButton variant="contained">{t("Add Categories")}</LykioButton>
            </Link>
          </Form.Group>
          <br />

          <Form.Label>
            {t(
              "Use this option to reset all content. Change logo, start off with a new "
            )}
          </Form.Label>
          <Form.Group className="mb-3" controlId="initial">
            <Link className="lykiocss-unstyled-link" to={INITIAL_SETUP_URL}>
              <LykioButton variant="contained">{t("Reset My Lykio")}</LykioButton>
            </Link>
          </Form.Group>
          <br />

          <Form.Label>
            {t(
              "View info for all lessons and stories. Approve/reject uploaded content."
            )}
          </Form.Label>
          <Form.Group controlId="moderate">
            <Link className="lykiocss-unstyled-link" to={contentModerationUrl}>
              <LykioButton variant="contained">{t("Manage Content")}</LykioButton>
            </Link>
          </Form.Group>
          <br />

          <Form.Label>
            {t(
              "Use this to add content hosted on S3, GDrive, DropBox, OneDrive etc."
            )}
          </Form.Label>
          <Form.Group className="mb-3" controlId="s3">
            <Link className="lykiocss-unstyled-link" to={CONTENT_MANAGEMENT_ADDS3_URL}>
              <LykioButton variant="contained">
                {t("Add Cloud Hosted Content")}
              </LykioButton>
            </Link>
          </Form.Group>
          <br />

          <Form.Label>
            {t(
              "Create custom HTML-based content using the Lykio drag and drop tool."
            )}
          </Form.Label>
          <Form.Group className="mb-3" controlId="create">
            <Link className="lykiocss-unstyled-link" to={CONTENT_MANAGEMENT_CREATE_URL}>
              <LykioButton variant="contained">{t("Create System Content")}</LykioButton>
            </Link>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};

export default ContentManagement;
