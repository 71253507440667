import './homeStats.css';
import GroupsIcon from '@mui/icons-material/Groups';
import { STORIES_URL } from '../../Utils/Constants';
import MonochromePhotosIcon from '@mui/icons-material/MonochromePhotos';
import SchoolIcon from '@mui/icons-material/School';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import FeaturedInfoBox from '../common/FeaturedInfoBox';
import t from "../../Utils/Translation";
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function HomeStats(props) {
    const numberOfGroups = props.numberOfGroups;
    const numberOfActiveUsers = props.numberOfActiveUsers;
    const numberOfActiveUnits = props.numberOfActiveUnits;
    const numberOfApprovedStories = props.numberOfApprovedStories;

    return (<FeaturedInfoBox 
					boxIcon1={<MonochromePhotosIcon/>}
					boxFigure1={numberOfApprovedStories}
                    boxMessage1={t('Stories')}
                    boxIcon2={<SchoolIcon/>}
                    boxFigure2={numberOfActiveUnits}
                    boxMessage2={t('Lessons')}
					boxIcon3={<GroupsIcon/>}
					boxFigure3={numberOfGroups}
                    boxMessage3={t('User Groups')}
                    boxIcon4={<AssignmentIndIcon/>}
                    boxFigure4={numberOfActiveUsers}
                    boxMessage4={t('Users')}
					/>
            );
}

export default HomeStats;