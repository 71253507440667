import "./featuredInfoBox.css";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { spacing } from "@mui/system";

export default function FeaturedInfoBox(props) {
  const navigate = useNavigate();

  return (
    <div style={{fontSize:'medium'}}>
      <Tooltip title={props.boxMessage1}>
        <span>
          {props.boxIcon1}
          {props.boxFigure1}
        </span>
      </Tooltip>

      <Tooltip title={props.boxMessage2}>
        <span>
          {props.boxIcon2}
          {props.boxFigure2}
        </span>
      </Tooltip>
      
      <Tooltip title={props.boxMessage3}>
        <span>
          {props.boxIcon3}
          {props.boxFigure3}
        </span>
      </Tooltip>

      <Tooltip title={props.boxMessage4}>
        <span>
          {props.boxIcon4}
          {props.boxFigure4}
        </span>
      </Tooltip>
    </div>
  );
}
