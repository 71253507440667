import ActiveProfileImage from '../common/ActiveProfileImage';
import ActiveGroup from '../common/ActiveGroup';
import t from "../../Utils/Translation";
import './leaderboardTable.css';

const LeaderboardTable = (props) => {
    const users = props.users;
    const value = props.value;
    const showGroupsInLeaderboards = props.showGroupsInLeaderboards;
	return (
    <div className="container">
    <div className="row">
        <div className="col-md-12">
                {users.length > 0 ?  <div className="card lykiocss-leaderboards-no-radius">
                    <div className="table-responsive lykiocss-leaderboards-scrollable-table">
                    <table className="table lykiocss-table-borderless no-wrap user-table mb-0">
                    <tbody>
                    {users.map((user, index) => {
                        let reference; 
                        let name;
                        if(user.group && Array.isArray(user.group) && user.group.length>0){
                            reference = user.tenant.groups.filter((group) => group._id === user.group[0].toString())[0].reference;
                            name = user.tenant.groups.filter((group) => group._id === user.group[0].toString())[0].name;
                        }
                        if(user.group && !Array.isArray(user.group)){
                            reference = user.tenant.groups.filter((group) => group._id === user.group)[0].reference;
                            name = user.tenant.groups.filter((group) => group._id === user.group)[0].name;
                        }

                        return <tr key={index}>
                        <td className="pl-4">{index+1}</td>
                        <td style={{maxWidth:'12em'}}>
                            <ActiveProfileImage user={user} showName={true}/>
                        </td>
                        {showGroupsInLeaderboards &&
                        <td>
                            <span><ActiveGroup reference={reference} name={name}/> </span>
                            <div className="text-muted">{user.department ? `${user.department}` : ''} </div>
                        </td>
                        }
                        <td>
                        <div className="lykiocss-leaderboard-score">
                            {value==='xp' && `${user.xp} XP`}
                            {value==='impact' && `${Math.floor(user.impactIndex)}`}
                            {value==='stories' && `${user.stories ? user.stories.length : 0} ${user.stories.length===1 ? 'story' : 'stories'}`}
                        </div>
                        </td>
                    </tr>
                    })}
                    </tbody>
                    </table>
                </div>
                </div>
                : <div className='lykiocss-center'>{t("No entries yet")}</div>}
        </div>
    </div>
    </div>
	);
};

export default LeaderboardTable;
