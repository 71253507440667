import Navigation from "../common/Navigation";
import './plans.css';
import t from "../../Utils/Translation";

const Plans = () => {

    return <div className="lykioccs-main-content">
    <div className="container p-0">
    <h1 className="h3 mb-3 text-center">Plans &amp; Pricing</h1>
    <div className="row">
        <div className="col-md-10 col-xl-8 mx-auto">
            <h1 className="text-center">{t("We have a plan for everyone")}</h1>
            <p className="lead text-center mb-4">{t("Whether you're a business or an individual, 14-day trial no credit card required.")}</p>
    
            <div className="row justify-content-center mt-3 mb-2">
                <div className="col-auto">
                    <nav className="nav btn-group">
                        <a href="#monthly" className="btn btn-outline-primary active" data-toggle="tab">{t("Monthly billing")}</a>
                        <a href="#annual" className="btn btn-outline-primary" data-toggle="tab">{t("Annual billing")}</a>
                    </nav>
                </div>
            </div>
                <div className="tab-pane" id="annual">
                    <div className="row py-4">
                        <div className="col-sm-4 mb-3 mb-md-0">
                            <div className="card text-center h-100">
                                <div className="card-body d-flex flex-column">
                                    <div className="mb-4">
                                        <h5>{t("Free")}</h5>
                                        <span className="display-4">$0</span>
                                    </div>
                                    <h6>{t("Includes")}</h6>
                                    <ul className="list-unstyled">
                                        <li className="mb-2">
                                            {t("5 users")}
                                        </li>
                                        <li className="mb-2">
                                            {t("unlimited categories and access to all features")}
                                        </li>
                                        <li className="mb-2">
                                            {t("1 GB worth of courses storage")}
                                        </li>
                                    </ul>
                                    <div className="mt-auto">
                                        <a href="#" className="btn btn-lg btn-outline-primary">{t("Sign up")}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4 mb-3 mb-md-0">
                            <div className="card text-center h-100">
                                <div className="card-body d-flex flex-column">
                                    <div className="mb-4">
                                        <h5>{t("Standard")}</h5>
                                        <span className="display-4">$199</span>
                                        <span className="text-small4">{t("/mo")}</span>
                                    </div>
                                    <h6>{t("Includes")}</h6>
                                    <ul className="list-unstyled">
                                        <li className="mb-2">
                                            {t("up to 250 users")}
                                        </li>
                                        <li className="mb-2">
                                            {t("everything in the Free +")}
                                        </li>
                                        <li className="mb-2">
                                            {t("unlimited courses")}
                                        </li>
                                        <li className="mb-2">
                                            {t("unlimited storage")}
                                        </li>
                                    </ul>
                                    <div className="mt-auto">
                                        <a href="#" className="btn btn-lg btn-primary">{t("Try it for free")}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4 mb-3 mb-md-0">
                            <div className="card text-center h-100">
                                <div className="card-body d-flex flex-column">
                                    <div className="mb-4">
                                        <h5>Plus</h5>
                                        <span className="display-4">$399</span>
                                        <span>{t("/mo")}</span>
                                    </div>
                                    <h6>{t("Includes")}</h6>
                                    <ul className="list-unstyled">
                                        <li className="mb-2">
                                            {t("unlimited users")}
                                        </li>
                                        <li className="mb-2">
                                            {t("Everything in Standard +")}
                                        </li>
                                        <li className="mb-2">
                                            {t("custom domain and dedicated servers")}
                                        </li>
                                        <li className="mb-2">
                                            {t("Priority support")}
                                        </li>
                                        <li className="mb-2">
                                            {t("Custom features")}
                                        </li>
                                        <li className="mb-2">
                                            {t("Daily backups")}
                                        </li>
                                        <li className="mb-2">
                                            {t("Custom CSS")}
                                        </li>
                                    </ul>
                                    <div className="mt-auto">
                                        <a href="#" className="btn btn-lg btn-outline-primary">{t("Try it for free")}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <hr/>
    
            <div className="text-center my-4">
                <h2>{t("Frequently asked questions")}</h2>
            </div>
            <div className="row">
                <div className="col-md-4 col-sm-6 ml-auto">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="h6 card-title">{t("Do I need a credit card to sign up?")}</h5>
                            <p className="mb-0">{t("Yup! And it better not be empty...")}</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-6 mr-auto">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="h6 card-title">{t("Do you offer a free trial?")}</h5>
                            <p className="mb-0">{t("Sure thing.")}</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-6 ml-auto">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="h6 card-title">{t("What if I decide to cancel my plan?")}</h5>
                            <p className="mb-0">{t("It's a free world.")}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
}

export default Plans;