import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

const ContentCreationGuide = () => {
  return (
    <div className="lykioccs-main-content" style={{ backgroundColor: "white" }}>
      <Container>
        <Row>
          <Tabs defaultActiveKey="grid" id="" className="mb-3">
            <Tab eventKey="grid" title="Grid">
              <Container>
                <Row>
                  <Col xs={6}>
                    <div className="lykiocss-title-light">Grid</div>
                    <p>You can customize the grid of your page by choosing the number of the columns for each area.
                      You can select among 1 column, 2 columns, 3 columns and 2 columns 3/7.
                    </p>
                  </Col>
                  {/*<Col xs={6}>
                                <Image fluid src='https://lykio-dev-data.s3.eu-central-1.amazonaws.com/assets/guides/01_home_top.png'/>
                            </Col>*/}
                </Row>
              </Container>
            </Tab>
            <Tab eventKey="text" title="Text">
              <Container>
                <Row>
                  <Col>
                    <div className="lykiocss-title-light">Text</div>
                    <p>By selecting 'Typography' you can change the appearance of your text. For example, you can change
                    the colour of your text, if you click on the box on the right under the 'Color' label.</p>
                    <p>By using 'Font family' you can customize the font style of your text.</p>
                    <p>'Text align' option is responsible for the alignment of your text.</p>
                    <p>By selecting 'Decorations' you can change the background colour and also add a border to your text.
                    </p>
                    <p>It is advised to copy and paste your text into a text editor before placing it to your page. The reason is
                    that the copied text retains all its attributes (colour, font size etc).</p>
                  </Col>
                </Row>
              </Container>
            </Tab>
            <Tab eventKey="multimedia" title="Multimedia">
              <Container>
                <Row>
                  <Col>
                    <div className="lykiocss-title-light">Images</div>
                    <p>When you drop an image block on the page, a dialogue box appears. You can choose you image by dropping it on the left area
                    of the dialogue box. You can also double click on the left area of the dialogue box and select your image.
                    To add it on the page just click on it.</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="lykiocss-title-light">Videos</div>
                    <p>When you drop a video block on the page, select it and then press on the 'Settings' (the cog icon) on the right.
                    Copy and paste the ID of your video (it's the character string after the equals sign) on the 'Video ID' area.</p>
                    <p>You can move your video block wherever you wish by adjusting the 'Layout' properties.</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="lykiocss-title-light">Wallpapers</div>
                    <p>If you wish to add a wallpaper, first choose a column block, then select 'Decorations' and then 'Background' at the bottom.
                    Click on 'Images' and select your wallpaper the same way as choosing an image.</p>
                  </Col>
                </Row>
              </Container>
            </Tab>
          </Tabs>
        </Row>
      </Container>
    </div>
  );
};

export default ContentCreationGuide;
