import { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
	AUTHORIZED_URL_PREFIX,
    USER_MANAGEMENT_WITHOUTPARAM_URL,
	AxiosConfig
} from '../../Utils/Constants';
import './userManagement.css';
import t from '../../Utils/Translation';
import { notify, pleaseRefreshError } from '../../Utils/Notificator';
import ProfileCard from '../common/ProfileCard';
import GridLoading from '../stories/GridLoading'


function Group() {
    const [ isLoading, setIsLoading ] = useState(true);
	const [ users, setUsers ] = useState([]);
	const { groupreference } = useParams();
	const [ selectedGroup, setSelectedGroup ] = useState('');

	const fetchUserManagementData = async () => {
		try {
			setIsLoading(true);
			const { data } = await axios.post(
				`${AUTHORIZED_URL_PREFIX}${USER_MANAGEMENT_WITHOUTPARAM_URL}`,
				{ groupReference: groupreference },
				AxiosConfig()
			);
			setUsers(data.users.filter(user=> user.status==='active'));
			setSelectedGroup(data.selectedGroup);
			setIsLoading(false);
			if (!data.success) {
				notify(pleaseRefreshError);
			}
		} catch (error) {
			console.error(error);
			notify(pleaseRefreshError);
		}
	};

    useEffect(
		() => {
			fetchUserManagementData();
		},
		[]
	);

    return ( <div className="lykioccs-main-content">
    {isLoading ? (
        <GridLoading t={t} storiesName={selectedGroup}/>
    ) : (
        <div>
            <div className="lykiocss-stories-title">
                <h1>{selectedGroup}</h1>
            </div>
                <div className="lykiocss-stories-container">
                    {users.length < 1 ? (
                        <div>{t('No users here yet')}</div>
                    ) : (
                        <div className="lykiocss-stories-gallery">
                            {users.map((user) => {
                                return <div style={{width:'20em'}}><ProfileCard user={user} showName={true}/></div>;
                            })}
                        </div>
                    )}
                </div>
        </div>
    )}
    </div>);
}

export default Group;