import { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import lykio_logo from "../../multimedia/Default/white_loading.gif";
import {
  HOME_URL,
  REGISTER_URL,
  PUBLIC_URL_PREFIX,
  UBER_ADMIN_SETTINGS_URL,
  FRONTEND_LOGIN_URL,
  NOTIFICATION_SEVERITY_SUCCESS,
  NOTIFICATION_SEVERITY_WARNING,
  AxiosConfigNoAuth,
} from "../../Utils/Constants";
import "./RegisterScreen.css";
import t from "../../Utils/Translation";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { notify, pleaseRefreshError } from "../../Utils/Notificator";

const RegisterScreen = () => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [organization, setOrganization] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [authScreensLogo, setAuthScreensLogo] = useState();

  const navigate = useNavigate();

  useEffect(async () => {
    const { data } = await axios.get(
      `${PUBLIC_URL_PREFIX}${UBER_ADMIN_SETTINGS_URL}`,
      AxiosConfigNoAuth()
    );
    data.uberSettings && setAuthScreensLogo(data.uberSettings.authScreensLogo);

    const storageAuthTokenValue = localStorage.getItem("authToken");
    if (storageAuthTokenValue) {
      navigate(FRONTEND_LOGIN_URL);
    }
  }, []);

  const registerHandler = async (e) => {
    e.preventDefault();
    if (password.length < 6) {
      notify({
        title: `${t("Password must at least 6 characters long!")}`,
        severity: NOTIFICATION_SEVERITY_WARNING,
        creationDate: "",
      });
    } else if (password !== confirmPassword) {
      notify({
        title: `${t("Password do not match!")}`,
        severity: NOTIFICATION_SEVERITY_WARNING,
        creationDate: "",
      });
    } else {
      try {
        const { data } = await axios.post(
          REGISTER_URL,
          { firstname, lastname, organization, email, password },
          AxiosConfigNoAuth()
        );
        if (data.success) {
          notify({
            title: `${t(
              "You have successfully registered. An activation email was sent to your email."
            )}`,
            severity: NOTIFICATION_SEVERITY_SUCCESS,
            creationDate: "",
          });
        }
        navigate(FRONTEND_LOGIN_URL);
      } catch (error) {
        notify(pleaseRefreshError);
      }
    }
  };

  return (
    <div className="lykiocss-generic-background lykiocss-forms-container">
      <div className="lykiocss-screen-center">
        <Form
          onSubmit={registerHandler}
          className="LykioForm lykiocss-white-background lykiocss-login-form-border lykiocss-form-size"
        >
          <Link to={HOME_URL}>
            <div className="lykiocss-center">
              <img
                className="lykiocss-small-login-logo"
                src={authScreensLogo ? authScreensLogo : lykio_logo}
                alt=""
              />
            </div>
          </Link>
          <h4 className="register-screen__title">
            {t("Sign up your organization for Lykio")}
          </h4>
          <Form.Group className="mb-3" controlId="firstname">
            <FloatingLabel
              controlId="floatingfirstname"
              label={t("First Name")}
            >
              <Form.Control
                required
                type="text"
                placeholder={t("Enter your first name")}
                value={firstname}
                onChange={(e) => {
                  setFirstname(e.target.value);
                }}
              />
            </FloatingLabel>
          </Form.Group>

          <Form.Group className="mb-3" controlId="lastname">
            <FloatingLabel controlId="floatinglastname" label={t("Last Name")}>
              <Form.Control
                required
                type="text"
                placeholder={t("Enter your last name")}
                value={lastname}
                onChange={(e) => {
                  setLastname(e.target.value);
                }}
              />
            </FloatingLabel>
          </Form.Group>

          <Form.Group className="mb-3" controlId="email">
            <FloatingLabel
              controlId="floatingorg"
              label={t("Organization Name")}
            >
              <Form.Control
                required
                type="text"
                placeholder={t("Enter your Organization name")}
                value={organization}
                onChange={(e) => {
                  setOrganization(e.target.value);
                }}
              />
            </FloatingLabel>
          </Form.Group>

          <Form.Group className="mb-3" controlId="email">
            <FloatingLabel controlId="floatingemail" label={t("Email")}>
              <Form.Control
                required
                type="email"
                placeholder="name@example.com"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </FloatingLabel>
          </Form.Group>

          <Form.Group className="mb-3" controlId="password">
            <FloatingLabel controlId="floatingpass" label={t("Password")}>
              <Form.Control
                required
                type="password"
                placeholder={t("Enter password (6 characters or more)")}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </FloatingLabel>
          </Form.Group>

          <Form.Group className="mb-3" controlId="confirmpassword">
            <FloatingLabel
              controlId="floatingpassagain"
              label={t("Confirm Password")}
            >
              <Form.Control
                required
                type="password"
                placeholder={t("Confirm your password")}
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
              />
            </FloatingLabel>
          </Form.Group>

          <Form.Group className="mb-3">
            <Button
              variant="primary"
              type="submit"
              className="float-end"
              size="sm"
            >
              {t("Register")}
            </Button>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};

export default RegisterScreen;
