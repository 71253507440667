import { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import lykio_logo from "../../multimedia/Default/white_loading.gif";
import {
  HOME_URL,
  REGISTER_USER_URL,
  FRONTEND_LOGIN_URL,
  PUBLIC_URL_PREFIX,
  UBER_ADMIN_SETTINGS_URL,
  AxiosConfigNoAuth,
  NOTIFICATION_SEVERITY_SUCCESS,
  NOTIFICATION_SEVERITY_WARNING,
} from "../../Utils/Constants";
import t from "../../Utils/Translation";
import "./RegisterScreen.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { notify, pleaseRefreshError } from "../../Utils/Notificator";

const RegisterSimpleUserScreen = () => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [authScreensLogo, setAuthScreensLogo] = useState();

  const { isTeacher, email, group } = useParams();
  const navigate = useNavigate();

  useEffect(async () => {
    const { data } = await axios.get(
      `${PUBLIC_URL_PREFIX}${UBER_ADMIN_SETTINGS_URL}`,
      AxiosConfigNoAuth()
    );
    data.uberSettings && setAuthScreensLogo(data.uberSettings.authScreensLogo);

    const storageAuthTokenValue = localStorage.getItem("authToken");
    if (storageAuthTokenValue) {
      navigate(FRONTEND_LOGIN_URL);
    }
  }, []);

  const registerHandler = async (e) => {
    e.preventDefault();
    if (password.length < 6) {
      notify({
        title: `${t("Password must at least 6 characters long!")}`,
        severity: NOTIFICATION_SEVERITY_WARNING,
        creationDate: "",
      });
    } else if (password !== confirmPassword) {
      notify({
        title: `${t("Password do not match!")}`,
        severity: NOTIFICATION_SEVERITY_WARNING,
        creationDate: "",
      });
    } else {
      try {
        const { data } = await axios.post(
          REGISTER_USER_URL,
          { firstname, lastname, email, password },
          AxiosConfigNoAuth()
        );
        if (data.success) {
          notify({
            title: `${t(
              "You have successfully completed your registration. Use your credentials to sign in."
            )}`,
            severity: NOTIFICATION_SEVERITY_SUCCESS,
            creationDate: "",
          });
          navigate(FRONTEND_LOGIN_URL);
        } else {
          notify(pleaseRefreshError);
        }
      } catch (error) {
        console.log(error);
        notify(pleaseRefreshError);
      }
    }
  };

  return (
    <div className="lykiocss-generic-background lykiocss-forms-container">
      <div className="lykiocss-screen-center">
        <Form
          onSubmit={registerHandler}
          className="LykioForm lykiocss-white-background lykiocss-login-form-border lykiocss-form-size"
        >
          <Link to={HOME_URL}>
            <div className="lykiocss-center">
              <img
                className="lykiocss-small-login-logo"
                src={authScreensLogo ? authScreensLogo : lykio_logo}
                alt=""
              />
            </div>
          </Link>
          <h4 className="register-screen__title">
            {t("Complete your registration to Lykio")}
          </h4>
          <Form.Group className="mb-3" controlId="firstname">
            <Form.Label>{t("First name")}</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder={t("Enter your first name")}
              value={firstname}
              onChange={(e) => {
                setFirstname(e.target.value);
              }}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="lastname">
            <Form.Label>{t("Last name")}</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder={t("Enter your last name")}
              value={lastname}
              onChange={(e) => {
                setLastname(e.target.value);
              }}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="email">
            <Form.Label>
              {t("Email (Your admin has set this up for you)")}
            </Form.Label>
            <Form.Control required type="email" disabled value={email} />
          </Form.Group>

          <Form.Group className="mb-3" controlId="password">
            <Form.Label>{t("Password")}</Form.Label>
            <Form.Control
              required
              type="password"
              placeholder={t("Enter password (6 characters or more)")}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="confirmpassword">
            <Form.Label>{t("Confirm Password")}</Form.Label>
            <Form.Control
              required
              type="password"
              placeholder={t("Confirm your password")}
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Button
              variant="primary"
              type="submit"
              className="float-end"
              size="sm"
            >
              {t("Register")}
            </Button>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};

export default RegisterSimpleUserScreen;
