import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import './loading.css';
import {Container, Row} from 'react-bootstrap';
import t from '../../Utils/Translation';

const Loading = (props) => {
    return <div className='lykiocss-loading-center'>
    <Container>
    <Row>
    <Loader
    className="lykiocss-center"
    type={props.type ? props.type : "Grid"} // options Grid, Bars, Hearts, TailSpin, ThreeDots
    color="grey"
    height={100}
    width={100}
    />
    </Row>
    <Row>
    {props.delay && <div style={{marginTop:'2em'}} className="lykiocss-center lykiocss-subtitle-light">{t("Going through lots of data. It might take a while....")}</div>}
    </Row>
    </Container>
    </div>;
}

export default Loading;