import { useState, useEffect, useContext } from 'react';
import { LykioContext } from "../routing/LykioAuthorizedRoute";
import axios from 'axios';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import './addUser.css';
import t from "../../Utils/Translation";
import LykioButton from "../common/LykioButton";
import Form from 'react-bootstrap/Form';
import {
	AUTHORIZED_URL_PREFIX,
	USER_EDIT_WITHOUTPARAM_URL,
	GET_FULL_USER_EDIT_INFO_URL,
	NOTIFICATION_SEVERITY_SUCCESS,
	NOTIFICATION_SEVERITY_WARNING,
	AxiosConfig
} from '../../Utils/Constants';
import Loading from '../common/Loading';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { notify, pleaseRefreshError } from '../../Utils/Notificator';
import FloatingLabel from "react-bootstrap/FloatingLabel";

const EditUser = () => {
	const { userId } = useParams();
	const context = useContext(LykioContext);
	const userPermissions = context.userPermissions;
	const navigate = useNavigate();
	const [ group, setGroup ] = useState('');
	const [ userType, setUserType ] = useState('user');
	const [ email, setEmail ] = useState('');
	const [ status, setStatus] = useState('')
	const [firstname, setFirstname] = useState('');
	const [lastname, setLastname] = useState('');
	const [ availableGroups, setAvailableGroups ] = useState([]);
	const [ availableDepartments, setAvailableDepartments ]= useState([]);
	const [ department, setDepartment] = useState();
	const [ isLoading, setIsLoading ] = useState(true);

	useEffect(() => {
		const fetchUserData = async () => {
			try {
				const { data } = await axios.post(`${AUTHORIZED_URL_PREFIX}${GET_FULL_USER_EDIT_INFO_URL}`,{ userId }, AxiosConfig());
				if (data.success) {
					console.log(userPermissions);
					console.log('>>>',data.user);
					let departmentsForAutoComplete = [{item: {}, label: `none`}];
					data.departments.forEach((dept) => {
						departmentsForAutoComplete.push({item: dept, label: `${dept.name}`});
					})
					
					setAvailableDepartments(departmentsForAutoComplete);
					setAvailableGroups(data.groups);
					setFirstname(data.user.firstname);
					setLastname(data.user.lastname);
					data.departments.length===0 ? setDepartment(undefined) : setDepartment(data.user.department);
					setGroup(data.user.group);
					setEmail(data.user.email);
					setUserType(data.user.role.name);
					setStatus(data.user.status);
					setIsLoading(false);
				} else {
					notify(pleaseRefreshError);
				}
			} catch (error) {
				console.error(error);
				notify(pleaseRefreshError);
			}
		};
		fetchUserData();
	}, []);

	const editUser = async (e) => {
		e.preventDefault();
		try {
			group === '' && setGroup(availableGroups[0]._id);
			const { data } = await axios.post(
				`${AUTHORIZED_URL_PREFIX}${USER_EDIT_WITHOUTPARAM_URL}`,
				{email, firstname, lastname, userType, group, department, status},
				AxiosConfig()
			);
			if (data.success === true) {
				notify({
					title: `${t('Your user was modified successfully.')}`,
					severity: NOTIFICATION_SEVERITY_SUCCESS,
					creationDate: ''
				});
			} else {
				if(data.message){
					notify({
						title: data.message,
						severity: NOTIFICATION_SEVERITY_WARNING,
						creationDate: ''
					});
				}else{
					notify(pleaseRefreshError);
				}
			}
		}catch(error) {
			console.log(error);
			notify(pleaseRefreshError);
		}
	};

	const handleGroupChange = (e) => {
		setGroup(e.target.value);
	};

	const handleUserTypeChange = (e) => {
		setUserType(e.target.value);
	};

	return (
		<div className="lykioccs-main-content">
			{isLoading ? (
				<Loading />
			) : (
				<div className="lykiocss-center lykiocss-margin-top-2em">
					<Form onSubmit={editUser} className="lykiocss-form lykiocss-form-border lykiocss-form-size">
						<div className="lykiocss-center lykiocss-title-light">{t('Edit user')}</div>
						<Form.Group className="mb-3 lykiocss-margin-top-1em" controlId="email">
							<FloatingLabel controlId="floatingemail" label={t('Email')}>
							<Form.Control required type="email" placeholder="name@example.com" 
							disabled
							defaultValue={email}
							/>
							</FloatingLabel>
						</Form.Group>

						<Form.Group className="mb-3 lykiocss-margin-top-1em" controlId="email">
							<FloatingLabel controlId="floatingemail" label={t('First Name')}>
							<Form.Control required type="text"  
							defaultValue={firstname}
							onChange={(e) => {
								setFirstname(e.target.value);
							}}
							/>
							</FloatingLabel>
						</Form.Group>

						<Form.Group className="mb-3 lykiocss-margin-top-1em" controlId="email">
							<FloatingLabel controlId="floatingemail" label={t('Last Name')}>
							<Form.Control required type="text"  
							defaultValue={lastname}
							onChange={(e) => {
								setLastname(e.target.value);
							}}
							/>
							</FloatingLabel>
						</Form.Group>

						<Form.Group className="mb-3" controlId="group">
							<FloatingLabel controlId="floatinggroup" label={t('Choose group')}>
							<Form.Select onChange={handleGroupChange}>
								{availableGroups.map((groupItem) => (
										<option selected={groupItem._id===group} key={groupItem._id} value={groupItem._id}>
											{groupItem.name}
										</option>
								))}
							</Form.Select>
							</FloatingLabel>
						</Form.Group>

						<Form.Group>
						<Autocomplete
		 				disablePortal
		 				id="combo-box-searchTerms"
		 				options={availableDepartments}
		 				onChange={(event, value) => {
		 					setDepartment(value ? value.item.name : undefined)
		 				}	
		 				}
		 				renderInput={(params) => (
		 					<TextField {...params} label={`${t('Assigned to')} ${department}`} />
		 				)}
		 				/>
						</Form.Group>

						<Form.Group className="mb-3 lykiocss-margin-top-1em" controlId="group">
							<FloatingLabel controlId="floatinguser" label={t('User type')}>
							<Form.Select onChange={handleUserTypeChange}>
								<option selected={userType==='user'} value="user">{t('Learner')}</option>
								<option selected={userType==='teacher'} value="teacher">{t('Educator')}</option>
								<option selected={userType==='manager'} value="manager">{t('Manager')}</option>
								{!userPermissions.includes('teacherusermanagement') && <option selected={userType==='admin'} value="admin">{t('Administrator')}</option>}
							</Form.Select>
							</FloatingLabel>
						</Form.Group>

						<Form.Group className="mb-3 lykiocss-margin-top-1em" controlId="group">
							<FloatingLabel controlId="floatinguser" label={t('User type')}>
							<Form.Select onChange={(e) => setStatus(e.target.value)}>
								<option selected={status==='active'} value="active">{t('Active')}</option>
								<option selected={status==='pending'} value="pending">{t('Pending')}</option>
								<option selected={status==='locked'} value="locked">{t('Locked')}</option>
							</Form.Select>
							</FloatingLabel>
						</Form.Group>

						<LykioButton variant="contained" type="submit"  className="float-end">
								{t('Save')}
						</LykioButton>
					</Form>
				</div>
			)}
		</div>
	);
};

export default EditUser;
