import { useState, useEffect } from 'react';
import axios from 'axios';
import { AUTHORIZED_URL_PREFIX, MESSAGES_URL, AxiosConfig } from '../../Utils/Constants';
import './messages.css';

const Messages = () => {
	const [ messagesData, setMessagesData ] = useState('');

	useEffect(() => {
		const fetchMessagesData = async () => {
			try {
				const { data } = await axios.get(`${AUTHORIZED_URL_PREFIX}${MESSAGES_URL}`, AxiosConfig());
				setMessagesData(data.messages);
			} catch (error) {
			}
		};

		fetchMessagesData();
	}, []);
	return <div className="lykioccs-main-content">
			<div>{messagesData}</div>
			</div>
};

export default Messages;
