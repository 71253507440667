import { useState } from 'react';
import axios from 'axios';
import LykioButton from "../common/LykioButton";
import Form from 'react-bootstrap/Form';
import {
	AUTHORIZED_URL_PREFIX,
	INITIAL_SETUP_URL,
	NOTIFICATION_SEVERITY_SUCCESS,
	AxiosConfig
} from './../../Utils/Constants';
import './firstAdminScreen.css';
import t from "../../Utils/Translation";
import { useNavigate } from 'react-router';
import { notify, pleaseRefreshError } from './../../Utils/Notificator';

const FirstAdminScreen = ({ history }) => {
	const navigate = useNavigate();
	const [ logo, setLogo ] = useState();
	const [ category, setCategory ] = useState('');
	const [ description, setDescription ] = useState('');

	const initializeTenantHandler = async (e) => {
		e.preventDefault();

		try {
			const formData = new FormData();
			formData.append('logo', logo);
			formData.append('category', category);
			formData.append('description', description);
			console.log(formData);
			const { data } = await axios.post(
				`${AUTHORIZED_URL_PREFIX}${INITIAL_SETUP_URL}`,
				formData,
				AxiosConfig('multipart/form-data')
			);
			if (data.success === true) {
				notify({
					title: `${t('You have successfully initialized your Lykio. You have been redirected to the home page.')}`,
					severity: NOTIFICATION_SEVERITY_SUCCESS,
					creationDate: ''
				});
				navigate("/");
			}
		} catch (error) {
			console.log(error);
			notify(pleaseRefreshError);
		}
	};

	return (
		<div className="lykioccs-main-content">
			<div className="lykiocss-center">
				<Form onSubmit={initializeTenantHandler} className="lykiocss-form lykiocss-form-border lykiocss-form-size">
				<h3 className="lykiocss-center">{t('Lykio Initial Setup')}</h3>
					<Form.Group className="mb-3" controlId="category">
						<Form.Label>
							{t(
								'A Category to kick things off (this is mandatory, any previous entries will be discarded)'
							)}
						</Form.Label>
						<Form.Control
							required
							type="text"
							placeholder={t('Enter category name')}
							defaultValue={category}
							onChange={(e) => {
								setCategory(e.target.value);
							}}
						/>
					</Form.Group>
					<Form.Group className="mb-3" controlId="description">
						<Form.Label>
							{t(
								'Some words about what this category is all about.'
							)}
						</Form.Label>
						<Form.Control
							required
							type="text"
							placeholder={t('Enter category description')}
							defaultValue={description}
							onChange={(e) => {
								setDescription(e.target.value);
							}}
						/>
					</Form.Group>
					<Form.Group controlId="formFile" className="mb-3">
						<Form.Label>{t('My Company Logo (you can change this through settings later)')}</Form.Label>
						<Form.Control
							filename={logo}
							accept="image/*"
							type="file"
							onChange={(e) => setLogo(e.target.files[0])}
						/>
					</Form.Group>
					<LykioButton
						variant="contained"
						type="text"
						className="float-end lykiocss-form-button">
						{t('Customize my Lykio')}
					</LykioButton>
				</Form>
			</div>
		</div>
	);
};

export default FirstAdminScreen;
