import React, { useState, useEffect, useContext } from 'react';
import {Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import axios from "axios";
import {AUTHORIZED_URL_PREFIX, AxiosConfig, CHAT_GROUP_URL, SEARCH_USERS_URL} from "../../Utils/Constants";
import {notify, pleaseRefreshError} from "../../Utils/Notificator";
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {toast} from "react-toastify";

const GroupChatDialog = (props) => {

    const [search, setSearch] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [groupChatName, setGroupChatName] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            props.setOpenGroupDialog(false)
            setGroupChatName('');
            const {data} = await axios.post(`${AUTHORIZED_URL_PREFIX}${CHAT_GROUP_URL}`, {
                name: groupChatName,
                users: JSON.stringify(selectedUsers.map((u) => u.item.result._id))
                }, AxiosConfig())

            props.setChats([data, ...props.chats])
            props.setSelectedChat(data)
        } catch (e) {
            toast({
                title: "Error fetching the chat",
                description: e.message,
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "bottom-left",
            });
        }

}

    const handleSearch = async () => {
        try {
            props.setOpenGroupDialog(true);
            const { data } = await axios.get(`${AUTHORIZED_URL_PREFIX}${SEARCH_USERS_URL}`, AxiosConfig());
            setSearch(data.users);
            if (!data.success) {
                notify(pleaseRefreshError);
            }
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        if(props.openGroupDialog) {
            handleSearch()
        }
    },[props.openGroupDialog])

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize='small'/>
    return (
        <>
            <Dialog
                fullWidth
                maxWidth= 'md'
                open={props.openGroupDialog}
                onClose={() => props.setOpenGroupDialog(false)}
            >
                <form onSubmit={handleSubmit}>
                    <DialogTitle>Create Group Chat</DialogTitle>
                    <DialogContent >
                        <Box>
                            <TextField
                                required
                                label='Chat Name'
                                fullWidth
                                sx={{mt:2}}
                                onChange={(e) => setGroupChatName(e.target.value)}
                            >

                            </TextField>
                            <Autocomplete
                                multiple
                                disableCloseOnSelect
                                sx={{pt:2}}
                                loading
                                id="combo-box-group"
                                options={search ? search : []}
                                getOptionLabel={(search) => search.label}
                                fullWidth
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.label}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} label="Add Users eg: John, George" placeholder="Select Users.." />
                                )}
                                onChange={(event, value) => setSelectedUsers(value ? value : '')}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => props.setOpenGroupDialog(false)}>Cancel</Button>
                        <Button autoFocus type='submit'>
                            ADD
                        </Button>
                    </DialogActions>
                </form>

            </Dialog>
        </>
    )


}

export default GroupChatDialog;