import { useParams } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import { LykioContext } from "../routing/LykioAuthorizedRoute";
import Loading from '../common/Loading';

const Ideology = () => {
	const context = useContext(LykioContext);
	const tenantProperties = context.tenantProperties;
	const guidesS3Url =  tenantProperties && tenantProperties.guidesS3Url ? tenantProperties.guidesS3Url : "Pending";
	const [ isLoading, setIsLoading ] = useState(true);

	useEffect(() => {
			guidesS3Url && setIsLoading(false);
	}, []);

	return <div>
		{isLoading ? <Loading /> : <div>
			<div className="lykiocss-unitiframe-iframe">
				<iframe src={guidesS3Url} />
			</div>
		</div>}
	</div>
};

export default Ideology;
