import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { LykioContext } from "../routing/LykioAuthorizedRoute";
import { AUTHORIZED_URL_PREFIX, LEADERBOARDS_URL, AxiosConfig } from '../../Utils/Constants';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import t from '../../Utils/Translation';
import Loading from '../common/Loading';
import { notify, pleaseRefreshError } from '../../Utils/Notificator';
import LeaderboardTable from './LeaderboardTable';
import LeaderboardGroupsTable from './LeaderboardGroupsTable';
import InfoSharpIcon from '@mui/icons-material/InfoSharp';
import "./leaderboards.css";
import { Tooltip } from '@mui/material';


const Leaderboards = () => {
  const context = useContext(LykioContext);
  const tenantProperties = context.tenantProperties;
  const showLocalLeaderboards = tenantProperties && tenantProperties.showLocalLeaderboards;
  const showGroupLeaderboards = tenantProperties && tenantProperties.showGroupLeaderboards;
  const showGroupsInLeaderboards = tenantProperties && tenantProperties.showGroupsInLeaderboards;
  const groupName = tenantProperties && tenantProperties.groupName;
  const [isLoading, setIsLoading] = useState(true);
  const [usersByXP, setUsersByXP] = useState([]);
  const [usersByStories, setUsersByStories] = useState([]);
  const [localUsersByXP, setLocalsersByXP] = useState([]);
  const [localUsersByStories, setLocalUsersByStories] = useState([]);
  const [impactfulCreators, setImpactfulCreators] = useState([]);
  const [groupsByXP, setGroupsByXP] = useState([]);
  const [groupsByStories, setGroupsByStories] = useState([]);
  const [localGroupName, setLocalGroupName] = useState();

  useEffect(() => {
    const fetchLeaderboardsData = async () => {
      try {
        const { data } = await axios.get(
          `${AUTHORIZED_URL_PREFIX}${LEADERBOARDS_URL}`,
          AxiosConfig()
        );
        if (!data.success) {
          notify(pleaseRefreshError);
        } else {
          setUsersByXP(data.usersByXP);
          setUsersByStories(data.usersByStories);
          setLocalsersByXP(data.localUsersByXP);
          setLocalUsersByStories(data.localUsersByStories);
          setImpactfulCreators(data.impactfulCreators);

          let groupsByXPToBeSorted = data.groupsByXP;
          let groupsByStoriesToBeSorted = data.groupsByStories;
          groupsByXPToBeSorted.sort((a, b) => b.score - a.score);
          groupsByStoriesToBeSorted.sort((a, b) => b.score - a.score);
          setGroupsByXP(groupsByXPToBeSorted);
          setGroupsByStories(groupsByStoriesToBeSorted);
          setLocalGroupName(data.localGroupName);
          setIsLoading(false);
        }
      } catch (error) {
        console.error(error);
        notify(pleaseRefreshError);
      }
    };
    fetchLeaderboardsData();
  }, []);
  return (
    <div className="lykioccs-main-content">
      {isLoading ? (
        <Loading />
      ) : (
        <Container className="lykiocss-leaderboards-container">
          <Row>
            {(usersByXP && usersByXP.length>0) &&
              <Col>
                <div className="lykiocss-center lykiocss-title-light lykiocss-leaderboards-container-header adjustLeaderboardTitle">{t("Top Colleagues Globally")}</div>
                <div>
                  <LeaderboardTable className="lykiocss-leaderboards-no-padding" users={usersByXP} value="xp" showGroupsInLeaderboards={showGroupsInLeaderboards}/>
                </div>
              </Col>
            }

            { ((localUsersByXP && localUsersByXP.length>0) && showLocalLeaderboards) && 
              <Col>
                <div className="lykiocss-center lykiocss-title-light lykiocss-leaderboards-container-header adjustLeaderboardTitle">{t(`Top Colleagues for ${localGroupName}`)}</div>
                <div>
                  <LeaderboardTable users={localUsersByXP} value="xp" showGroupsInLeaderboards={showGroupsInLeaderboards}/>
                </div>
              </Col>
            }
          </Row>
          <Row>
            {(usersByStories && usersByStories.length>0) &&
            <Col>
              <div className="lykiocss-center lykiocss-title-light lykiocss-leaderboards-container-header adjustLeaderboardTitle">{t("Top Creators Globally")}</div>
              <div>
                <LeaderboardTable users={usersByStories} value="stories" showGroupsInLeaderboards={showGroupsInLeaderboards} />
              </div>
            </Col>
            }

            { ((localUsersByStories && localUsersByStories.length>0) && showLocalLeaderboards)  && 
            <Col>
              <div className="lykiocss-center lykiocss-title-light lykiocss-leaderboards-container-header adjustLeaderboardTitle">{t(`Top Creators ${localGroupName ? `for ${localGroupName}` : `Locally`}`)}</div>
              <div>
                <LeaderboardTable users={localUsersByStories} value="stories" showGroupsInLeaderboards={showGroupsInLeaderboards} />
              </div>
            </Col>
            }
          </Row>
          <Row>
            {(impactfulCreators && impactfulCreators.length>0) &&
            <Col>
              <Tooltip title={t("A user's Impact Index is calculated by multiplying the user's stories by the average rating these stories have so far")}>
              <div className="lykiocss-center lykiocss-title-light lykiocss-leaderboards-container-header adjustLeaderboardTitle">{t("Most Impactful Colleagues")} &nbsp; <InfoSharpIcon/></div>
              </Tooltip>
              <div>
                <LeaderboardTable users={impactfulCreators} value="impact" showGroupsInLeaderboards={showGroupsInLeaderboards}/>
              </div>
            </Col>
            }
          </Row>
          <Row>
            {(showGroupLeaderboards && (groupsByXP && groupsByXP.length>0)) &&
            <Col>
              <div className="lykiocss-center lykiocss-title-light lykiocss-leaderboards-container-header adjustLeaderboardTitle">{`${groupName ? groupName : 'Group'}s ${t("Leaderboard By XP (avg)")}`}</div>
              <div>
                <LeaderboardGroupsTable groups={groupsByXP} value="xp" />
              </div>
            </Col>
            }

            {(showGroupLeaderboards && (groupsByStories && groupsByStories.length>0)) &&
            <Col>
              <div className="lykiocss-center lykiocss-title-light lykiocss-leaderboards-container-header adjustLeaderboardTitle">{`${groupName ? groupName : 'Group'}s ${t("Leaderboard By Stories")}`}</div>
              <div>
                <LeaderboardGroupsTable
                  groups={groupsByStories}
                  value="stories"
                />
              </div>
            </Col>
            }
          </Row>
          
        </Container>
      )}
    </div>
  );
};

export default Leaderboards;
