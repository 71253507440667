import friendlyDate from '../../Utils/FriendlyDate';
import t from "../../Utils/Translation";
import './timelineBox.css'

const TimelineBox = (props)=>{
    const userHistoryItems = props.userHistoryItems;
    
    return <div className="col-xl-4 col-md-6 lykiocss-profile-text-color">
            <div className="card">
                <div className="card-block">
                    <div className="row align-items-center justify-content-center">
                        <div className="col">
                            <div className='lykiocss-center lykiocss-subtitle-light'>{t("Timeline (last 5 entries)")}</div>
                        </div>
                    </div>
                    <ul className="task-list">
                        {userHistoryItems && userHistoryItems.slice(0).reverse().map((historyItem, index) =>{
                            if(index<5){
                                return <li>
                                <i className="task-icon bg-c-green"></i>
                                <span className="float-right">{historyItem.message}</span>
                                <p className="text-muted">{t("on")} {friendlyDate(historyItem.creationDate)}</p>
                                </li>
                            }
                        })}
                    </ul>
                </div>
            </div>
        </div>
}

export default TimelineBox;