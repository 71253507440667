import React from 'react';
import friendlyDate from '../../Utils/FriendlyDate';
import t from '../../Utils/Translation';
import './notificationsList.css';
import NotificationItem from './NotificationItem';
import Container from 'react-bootstrap/Container';
import timeSince from '../../Utils/TimeSince';
import {Box, Stack} from "@mui/material";

const NotificationsList = ({ notifications, markNotificationAsRead }) => {

return (<React.Fragment>
		<Box mt={2} >
			<Stack spacing={2}>
				{notifications.map((notification) => {
					return (
						<NotificationItem
							notificationId={notification._id}
							title={notification.title}
							content={notification.content}
							createdOn={friendlyDate(notification.creationDate)}
							timeSince={timeSince(notification.creationDate)}
							markNotificationAsRead={markNotificationAsRead}
							severity={notification.severity}
						/>
					)}
				)}
			</Stack>
		</Box>
	</React.Fragment>
);
};

export default NotificationsList;
