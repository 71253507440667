import { useState, useEffect, useContext } from 'react';
import { LykioContext } from '../routing/LykioAuthorizedRoute';
import axios from 'axios';
import t from "../../Utils/Translation";
import './contentCreation.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import grapesjs from 'grapesjs';
import gjsPresetWebpage from 'grapesjs-preset-webpage';
import LykioButton from "../common/LykioButton";
import {
	AUTHORIZED_URL_PREFIX,
	LANGUAGES_ARRAY,
	CATEGORIES_URL,
	UPLOAD_GRAPES_UNIT_URL,
	NOTIFICATION_SEVERITY_WARNING,
	NOTIFICATION_SEVERITY_SUCCESS,
	AxiosConfig
} from '../../Utils/Constants';
import Form from 'react-bootstrap/Form';
import Loading from '../common/Loading';
import { notify, pleaseRefreshError } from '../../Utils/Notificator';

const GrapesContentCreation = () => {
	const context = useContext(LykioContext);
	const tenantProperties = context.tenantProperties;
	const languages = (tenantProperties && tenantProperties.languages && tenantProperties.languages.length>0) ? tenantProperties.languages : LANGUAGES_ARRAY;	
	const [ language, setLanguage ] = useState(languages[0].code);
	const [ editor, setEditor ] = useState('');
	const [ title, setTitle ] = useState('');
	const [ description, setDescription ] = useState('');
	const [ objective, setObjective ] = useState('');
	const [ duration, setDuration ] = useState('1');
	const [ file, setFile ] = useState();
	const [ category, setCategory ] = useState('');
	const [ categoryreference, setCategoryreference ] = useState('');
	const [ htmlContent, setHtmlContent ] = useState('');
	const [ availableCategories, setAvailableCategories ] = useState([]);
	const [ isLoading, setIsLoading ] = useState(true);

	useEffect(() => {
		const fetchCategoriesData = async () => {
			try {
				const { data } = await axios.get(`${AUTHORIZED_URL_PREFIX}${CATEGORIES_URL}`, AxiosConfig());
				if (data.success) {
					let categories = [];
					data.categories.forEach(category => {
						categories.push(category);
						if(category.subCategories && category.subCategories.length > 0){
							category.subCategories.forEach(subCategory => {
								subCategory.name = `${category.name}-${subCategory.name}`;
								categories.push(subCategory);
							})
						}
					});
					setAvailableCategories(categories);
					setCategory(data.categories[0]._id);
					setCategoryreference(data.categories[0].reference);
					const editor = grapesjs.init({
						container: '#editor',
						plugins: [ gjsPresetWebpage ],
						pluginsOpts: {
							gjsPresetWebpage: {}
						},
						styleManager: {} 
					});
					/*console.log(editor.BlockManager.getAll())*/
					/*editor.BlockManager.getAll().reset();*/
					editor.BlockManager.remove('h-navbar');
					editor.BlockManager.remove('countdown');
					editor.BlockManager.remove('form');
					editor.BlockManager.remove('input');
					editor.BlockManager.remove('textarea');
					editor.BlockManager.remove('select');
					editor.BlockManager.remove('button');
					editor.BlockManager.remove('label');
					editor.BlockManager.remove('checkbox');
					editor.BlockManager.remove('radio');
					
					
					setEditor(editor);
					setIsLoading(false);
				} else {
					notify(pleaseRefreshError);
				}
			} catch (error) {
				console.error(error);
				notify(pleaseRefreshError);
			}
		};

		fetchCategoriesData();
	}, []);

	const handleCategoryChange = (e) => {
		setCategory(e.target.value);
		let ref;
		availableCategories.forEach((category) => {
			if (category._id === e.target.value) {
				ref = category.reference;
			}
		});
		setCategoryreference(ref);
	};

	const unitUploadHandler = async () => {
		let validFormFields = false;
		if (
			title.length > 1 &&
			description.length > 1 &&
			file &&
			category &&
			categoryreference &&
			editor.getHtml().length > 1
		) {
			validFormFields = true;
		}
		try {
			let page = `<!DOCTYPE html>
      <html>
      <head>
      <title>Lykio</title>
      <style>
      ${editor.getCss()}
      </style>
      </head>
      <body>
      ${editor.getHtml()}
      </body>
      </html>`;
			setHtmlContent(page);
			const formData = new FormData();
			formData.append('title', title);
			formData.append('description', description);
			formData.append('objective', objective);
			formData.append('duration', duration);
			formData.append('file', file);
			formData.append('category', category);
			formData.append('categoryreference', categoryreference);
			formData.append('language', language);
			formData.append('htmlContent', page);
			if (validFormFields) {
				const { data } = await axios.post(
					`${AUTHORIZED_URL_PREFIX}${UPLOAD_GRAPES_UNIT_URL}`,
					formData,
					AxiosConfig('multipart/form-data')
				);
				if (data && data.success === true) {
					setTitle('');
					setDescription('');
					setObjective('');
					setDuration('1');
					setFile();
					setCategory('');
					setCategoryreference('');
					setHtmlContent();
					notify({
						title: `${t(
							'You have successfully created a unit. Once it gets approved you will be able to see it in your selected category.'
						)}`,
						severity: NOTIFICATION_SEVERITY_SUCCESS,
						creationDate: ''
					});
				}
			} else {
				notify({
					title: `${t('You are missing some fields. Make sure to fill out all the fields.')}`,
					severity: NOTIFICATION_SEVERITY_WARNING,
					creationDate: ''
				});
			}
		} catch (error) {
			console.error(error);
			notify(pleaseRefreshError);
		}
	};

	return (
		<div>
			{isLoading ? (
				<Loading />
			) : (
				<div className="lykiocss-grapes-dark-background">
					<Row className="lykiocss-contentcreation-form-row">
						<Col>
							<Form.Select onChange={handleCategoryChange}>
								{availableCategories.map((category) => (
										<option key={category._id} value={category._id}>
											{category.name}
										</option>
								))}
							</Form.Select>
						</Col>
						<Col>
							<Form.Select onChange={(e) => setLanguage(e.target.value)}>
								{languages.map((language, index) => (
									<option key={index} value={language.code}>
										{language.name}
									</option>
								))}
							</Form.Select>
						</Col>
						<Col>
							<Form.Control
								type="text"
								maxLength="30"
								placeholder={t('Enter your title')}
								value={title}
								onChange={(e) => {
									setTitle(e.target.value);
								}}
							/>
						</Col>
						<Col>
							<Form.Control
								type="text"
								maxLength="150"
								placeholder={t('Enter your description')}
								value={description}
								onChange={(e) => {
									setDescription(e.target.value);
								}}
							/>
						</Col>
						<Col>
							<Form.Control
								type="text"
								maxLength="150"
								placeholder={t('Enter your objective')}
								value={objective}
								onChange={(e) => {
									setObjective(e.target.value);
								}}
							/>
						</Col>
						<Col>
							<Form.Control
								type="text"
								maxLength="3"
								placeholder={t('Duration in minutes')}
								onChange={(e) => {
									setDuration(e.target.value);
								}}
							/>
						</Col>
						<Col>
							<Form.Control
								filename={file}
								accept="image/*"
								type="file"
								onChange={(e) => setFile(e.target.files[0])}
							/>
						</Col>
						<Col>
							<LykioButton variant="text" onClick={unitUploadHandler}>{t('Save')}</LykioButton>
						</Col>
					</Row>
				</div>
			)}
			<div id="editor" />
		</div>
	);
};

export default GrapesContentCreation;
