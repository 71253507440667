import React from 'react'
import { UNITIFRAME_URL_WITHOUT_PARAM } from '../../Utils/Constants'
import Comments from './Comments'
import { Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import LykioButton from "./LykioButton";
import Image from 'react-bootstrap/Image'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Ratio from 'react-bootstrap/Ratio'
import timeSince from '../../Utils/TimeSince'
import t from '../../Utils/Translation'
import createDOMPurify from 'dompurify';
import Tooltip from '@mui/material/Tooltip';
import CommentSharpIcon from '@mui/icons-material/CommentSharp';
import VisibilitySharpIcon from '@mui/icons-material/VisibilitySharp';
import GradeSharpIcon from '@mui/icons-material/GradeSharp';
import StackedBarChartSharpIcon from '@mui/icons-material/StackedBarChartSharp';
import './unitModal.css'

const UnitModal = (props) => {
  const unit = props.unit
  const show = props.show
  const handleClose = props.handleClose
  const unitIFrameUrl = `${UNITIFRAME_URL_WITHOUT_PARAM}` + unit.id
  const DOMPurify = createDOMPurify()

  const editorJSParseHTML = () => {
    return { __html: DOMPurify.sanitize(unit.userText) }
  }

  return (
    <React.Fragment>
      {unit.type.startsWith('Story') ? (
        <Modal size="lg" centered show={show} onHide={handleClose} closeButton>
          <Modal.Header closeButton>
          <Container>
            <Row>
              <Col xs={9}>
                <div className='lykiocss-unitoverview-unit-title'>{unit.title}</div>
              </Col>
              <Col xs={3}>
                <div className="d-flex justify-content-center lykiocss-margin-top-1em">
                <Tooltip title={`${unit.comments ? unit.comments.length : 0} comments`}>
                <span>
                {unit.comments ? unit.comments.length : 0}
                <CommentSharpIcon/><span>&nbsp;</span>
                </span>
                </Tooltip>

                <Tooltip title={`${unit.ratings.length} times rated`}>
                <span>
                  {unit.ratings.length}
                  <GradeSharpIcon/><span>&nbsp;</span>
                </span>
                </Tooltip>


                <Tooltip title={`${unit.usage ? unit.usage : 0} times viewed`}>
                <span>
                  {unit.usage ? unit.usage : 0}
                  <VisibilitySharpIcon/><span>&nbsp;</span>
                </span>
                </Tooltip>


                <Tooltip title={`${unit.impactIndex} Impact Index`}>
                <span>
                  {unit.impactIndex}
                  <StackedBarChartSharpIcon/><span>&nbsp;</span>
                </span>
                </Tooltip>
              </div>
              </Col>
            </Row>
          </Container>
          
          </Modal.Header>
          <Modal.Body style={{padding:'0rem'}}>
            <Container>
              <div className="lykiocss-unitModal-container">
                <div className="lykiocss-unitModal-media">
                  {unit.type === 'Story-Video' && (
                    <video width="100%" height="288" controls>
                      <source
                        src={`${unit.s3Url}#t=1`}
                        type={unit.mediatype}
                      ></source>
                    </video>
                  )}
                  {unit.type === 'Story-Image' && (
                    <Ratio aspectRatio="1x1">
                      <Image src={unit.s3Url} fluid />
                    </Ratio>
                  )}
                  <div className="lykiocss-unitModal-story-uploader-name">
                    {unit.uploader.firstname}'s Story{' '}
                    <span className="lykiocss-unitModal-story-uploader-date">
                      {t('Created')} {timeSince(unit.creationDate)}
                    </span>
                  </div>
                  <div
                    className={
                      unit.type === 'Story-Video'
                        ? 'lykiocss-unitModal-story-text-video'
                        : 'lykiocss-unitModal-story-text-image'
                    }
                  >
                    <div dangerouslySetInnerHTML={editorJSParseHTML()} />
                  </div>
                </div>
                <div className="lykiocss-unitModal-comments-rating">
                  <Comments story={unit} />
                </div>
              </div>
            </Container>
          </Modal.Body>
        </Modal>
      ) : (
        <Modal size="lg" centered show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              "{unit.title}" {t('uploaded by')} {unit.uploader.fullname},{' '}
              {timeSince(unit.creationDate)}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <div className="lykiocss-unitModal-container">
                <div className="lykiocss-unitModal-media">
                  <Ratio aspectRatio="1x1">
                    <Image src={unit.coverS3Url} fluid />
                  </Ratio>
                  <div>
                    <p className="lykiocss-unitoverview-description">
                      <strong>{t("DESCRIPTION")}</strong>
                      {unit.description}
                    </p>
                    <div className="lykiocss-unitoverview-unit-info">
                      <div className="lykiocss-unitoverview-container-text">
                        <i className="bi bi-clock" /> {unit.duration}'
                      </div>
                      <div className="lykiocss-unitoverview-container-text">
                        <i className="bi bi-eye-fill" /> {unit.usage}
                      </div>
                      <div className="lykiocss-unitoverview-container-text">
                        <i className="bi bi-chat-left-text" />{' '}
                        {unit.comments.length}
                      </div>
                      <div className="lykiocss-unitoverview-container-text">
                        {t("Created")} {timeSince(unit.creationDate)}
                      </div>
                      <Link className="lykiocss-unstyled-link" to={unitIFrameUrl}>
                      <LykioButton                        
                        className="lykiocss-unitoverview-lessonbutton"
                        variant="contained"
                      >
                        {t("Start")}
                      </LykioButton>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="lykiocss-unitModal-comments-rating">
                  <Comments story={unit} />
                </div>
              </div>
            </Container>
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  )
}
export default UnitModal
