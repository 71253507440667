import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';
import { Link } from "react-router-dom";
import t from '../../Utils/Translation';
import ProfileBox from '../common/ProfileBox';
import Image from 'react-bootstrap/Image';


function ProfileNavItem(props) {
  const userDetails = props.userDetails;
  const dismissPopover = () => {
		document.body.click();
	};

  const profileboxPopover = (
		<Popover id="popover-basic" onClick={dismissPopover}>
			<Popover.Header as="h3">
				<Link  className="lykiocss-centered lykiocss-navigation-link-no-decoration" to={`/profile/${userDetails && userDetails.id}`}>
					{t('My Profile')}
				</Link>
			</Popover.Header>
			<Popover.Body>
				<ProfileBox userDetails={userDetails}/>
			</Popover.Body>
		</Popover>
	);

  return (
    <li className={"meta-navigation-profile-icon meta-nav-item "+ (props.leftmargin && ' meta-auto-left-margin')}>
		{props.enableSchools ? (
			<Link to={`/profile/${userDetails && userDetails.id}`}>
				<Tooltip title="View Profile Details">
					<div className="meta-icon-button">
						<Badge color="secondary">
							<Image title={userDetails && `${userDetails.firstname} ${userDetails.lastname}`} src={userDetails && userDetails.profilepicture} className="lykiocss-active-profile-image rounded-circle" />
						</Badge>
					</div>
				</Tooltip>
			</Link>
		) : (
			<OverlayTrigger rootClose transition trigger="click" placement="bottom" overlay={profileboxPopover}>
			<Tooltip title="View Profile Details">
			<div className="meta-icon-button">
			<Badge color="secondary">
			<Image title={userDetails && `${userDetails.firstname} ${userDetails.lastname}`} src={userDetails && userDetails.profilepicture} className="lykiocss-active-profile-image rounded-circle" />
			</Badge>
			</div>
			</Tooltip>
			</OverlayTrigger>
			)}

    </li>
  );
}

export default ProfileNavItem;