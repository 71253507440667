import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "./forgotPaswordScreen.css";
import {
  HOME_URL,
  FORGOTPASS_URL,
  PUBLIC_URL_PREFIX,
  UBER_ADMIN_SETTINGS_URL,
  NOTIFICATION_SEVERITY_SUCCESS,
  NOTIFICATION_SEVERITY_WARNING,
  AxiosConfigNoAuth,
} from "../../Utils/Constants";
import t from "../../Utils/Translation";
import lykio_logo from "../../multimedia/Default/white_loading.gif";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { notify, pleaseRefreshError } from "../../Utils/Notificator";

const ForgotPasswordScreen = () => {
  const [email, setEmail] = useState("");
  const [authScreensLogo, setAuthScreensLogo] = useState();

  useEffect(async () => {
    const { data } = await axios.get(
      `${PUBLIC_URL_PREFIX}${UBER_ADMIN_SETTINGS_URL}`,
      AxiosConfigNoAuth()
    );
    data.uberSettings && setAuthScreensLogo(data.uberSettings.authScreensLogo);
  }, []);

  const forgotPasswordHandler = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        FORGOTPASS_URL,
        { email },
        AxiosConfigNoAuth()
      );
      if (!data.success) {
        if (data.message) {
          notify({
            title: data.message,
            severity: NOTIFICATION_SEVERITY_WARNING,
            creationDate: "",
          });
        } else {
          notify(pleaseRefreshError);
        }
      } else {
        notify({
          title: `${t("We have sent you an email. Check your inbox")}`,
          severity: NOTIFICATION_SEVERITY_SUCCESS,
          creationDate: "",
        });
      }
    } catch (error) {
      console.error(error);
      notify(pleaseRefreshError);
    }
  };

  return (
    <div className="lykiocss-generic-background lykiocss-forms-container">
      <div className="lykiocss-screen-center">
        <Form
          onSubmit={forgotPasswordHandler}
          className="LykioForm lykiocss-white-background lykiocss-login-form-border lykiocss-form-size"
        >
          <Link to={HOME_URL}>
            <div className="lykiocss-center">
              <img
                className="lykiocss-small-login-logo"
                src={authScreensLogo ? authScreensLogo : lykio_logo}
                alt=""
              />
            </div>
          </Link>
          <h3 className="lykiocss-center">{t("Forgot Password")}</h3>
          <Form.Group className="mb-3" controlId="email">
            <p style={{ marginBottom: "0.3em" }}>
              Please enter the email address with which you registered your
              account.
            </p>
            <p>A reset password email will be sent to you.</p>
            <Form.Control
              required
              type="email"
              placeholder="name@example.com"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              tabIndex={1}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Button
              variant="primary"
              type="submit"
              className="lykiocss-center"
              size="sm"
            >
              {t("Send Email")}
            </Button>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};

export default ForgotPasswordScreen;
