import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import './unitModal.css'

const VideoModal = (props) => {
  const unit = props.unit
  const show = props.show
  const handleClose = props.handleClose

  return (
    <React.Fragment>
        <Modal size="lg" centered show={show} onHide={handleClose} closeButton>
          <Modal.Header closeButton>
          {unit.title}
          </Modal.Header>
          <Modal.Body style={{padding:'0rem'}}>
          <Container>
          <video class="ttvideo" width="100%" height="480" controls="true" controlslist="nodownload" poster={unit.coverS3Url}>
              <source type="video/mp4" src={`${unit.s3Url}#t=0.2`}/>
              <track src={unit.subs} label="English" kind="subtitles" srclang="en-us"/>
          </video>
          </Container>
          </Modal.Body>
        </Modal>
    </React.Fragment>
  )
}
export default VideoModal
