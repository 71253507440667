import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import axios from "axios";
import {
  HOME_URL,
  FRONTEND_LOGIN_URL,
  PUBLIC_URL_PREFIX,
  UBER_ADMIN_SETTINGS_URL,
  RESETPASS_URL,
  NOTIFICATION_SEVERITY_SUCCESS,
  NOTIFICATION_SEVERITY_WARNING,
  AxiosConfigNoAuth,
} from "../../Utils/Constants";
import t from "../../Utils/Translation";
import lykio_logo from "../../multimedia/Default/white_loading.gif";
import "./resetPaswordScreen.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { notify, pleaseRefreshError } from "../../Utils/Notificator";

const ResetPasswordScreen = ({ history, match }) => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [authScreensLogo, setAuthScreensLogo] = useState();

  const { resetToken } = useParams();

  useEffect(async () => {
    const { data } = await axios.get(
      `${PUBLIC_URL_PREFIX}${UBER_ADMIN_SETTINGS_URL}`,
      AxiosConfigNoAuth()
    );
    data.uberSettings && setAuthScreensLogo(data.uberSettings.authScreensLogo);
  }, []);

  const resetPasswordHandler = async (e) => {
    e.preventDefault();
    if (password.length < 6) {
      notify({
        title: `${t("Password must at least 6 characters long!")}`,
        severity: NOTIFICATION_SEVERITY_WARNING,
        creationDate: "",
      });
    } else {
      if (password !== confirmPassword) {
        setPassword("");
        setConfirmPassword("");
        notify({
          title: `${t("Passwords don't match")}`,
          severity: NOTIFICATION_SEVERITY_WARNING,
          creationDate: "",
        });
      } else {
        try {
          const { data } = await axios.post(
            RESETPASS_URL,
            { resetToken, password },
            AxiosConfigNoAuth()
          );
          if (data.success) {
            notify({
              title: `${t(
                "Your password was successfully reset. You can now use it to login."
              )}`,
              severity: NOTIFICATION_SEVERITY_SUCCESS,
              creationDate: "",
            });
            navigate(FRONTEND_LOGIN_URL);
          }
        } catch (error) {
          console.log(error);
          notify(pleaseRefreshError);
        }
      }
    }
  };

  return (
    <div className="lykiocss-generic-background lykiocss-forms-container">
      <div className="lykiocss-screen-center">
        <Form
          onSubmit={resetPasswordHandler}
          className="LykioForm lykiocss-white-background lykiocss-login-form-border lykiocss-form-size"
        >
          <Link to={HOME_URL}>
            <div className="lykiocss-center">
              <img
                className="lykiocss-small-login-logo"
                src={authScreensLogo ? authScreensLogo : lykio_logo}
                alt=""
              />
            </div>
          </Link>
          <h3 className="resetpassword-screen__title">
            {t("Forgot Password")}
          </h3>

          <Form.Group className="mb-3" controlId="password">
            <Form.Label>{t("New Password")}</Form.Label>
            <Form.Control
              required
              type="password"
              placeholder={t("Enter password (6 characters or more)")}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="confirmpassword">
            <Form.Label>{t("Confirm Password")}</Form.Label>
            <Form.Control
              required
              type="password"
              placeholder={t("Confirm your password")}
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Button
              variant="primary"
              type="submit"
              className="float-end"
              size="sm"
            >
              {t("Reset Password")}
            </Button>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};

export default ResetPasswordScreen;
