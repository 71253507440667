import React, { useState, useEffect, useContext } from 'react';
import { LykioContext } from "../routing/LykioAuthorizedRoute";
import { useNavigate } from "react-router";
import axios from 'axios';
import t from '../../Utils/Translation';
import './stories.css';
import {
	AUTHORIZED_URL_PREFIX,
	STORIES_URL,
	CREATE_URL,
	NEXT_SET_OF_STORIES_URL,
	NOTIFICATION_SEVERITY_INFO,
	AxiosConfig
} from '../../Utils/Constants';
import Story from './Story';
import { notify, pleaseRefreshError } from '../../Utils/Notificator';
import LykioButton from "../common/LykioButton";
import GridLoading from './GridLoading';
import LoadingButton from '@mui/lab/LoadingButton';
import Loading from './../common/Loading';
import FeaturedStories from '../home/FeaturedStories';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FeaturedStoriesSplider from '../common/FeaturedStoriesSplider';

const Stories = () => {
	const navigate = useNavigate();
	const context = useContext(LykioContext);
	const tenantProperties = context.tenantProperties;
	const storiesName =  tenantProperties && tenantProperties.storiesName ? tenantProperties.storiesName : "Stories";
	const storiesTagLine =  tenantProperties && tenantProperties.storiesTagLine ? tenantProperties.storiesTagLine : "We Create Sensation, We Create Inspiration";
	const makeHeadersDark = tenantProperties && tenantProperties.makeHeadersDark;

	const [ page, setPage ] = useState(0);
	const [ stories, setStories ] = useState([]);
	const [ isLoading, setIsLoading ] = useState(true);
	const [ feedLoading, setFeedLoading ] = useState(false);
	const [ noMoreStories, setNoMoreStories ] = useState(false);
  	const tenantName =  tenantProperties && tenantProperties.name ? tenantProperties.name : "Lykio";

	useEffect(() => {
		const fetchFirstStories = async () => {
			try {
				const { data } = await axios.get(`${AUTHORIZED_URL_PREFIX}${STORIES_URL}`, AxiosConfig());
				setStories(data.stories);
				setIsLoading(false);
				if (!data.success) {
					notify(pleaseRefreshError);
				}
			} catch (error) {
				console.log(error);
				notify(pleaseRefreshError);
			}
		};
		fetchFirstStories();
		
	}, []);

	const fetchMoreStories = async () => {
		setFeedLoading(true);
		try {
			const { data } = await axios.post(
				`${AUTHORIZED_URL_PREFIX}${NEXT_SET_OF_STORIES_URL}`,
				{ page: page + 1 },
				AxiosConfig()
			);
			setPage(page + 1);
			if (data.success === true) {
				setFeedLoading(false);
				setStories([ ...stories, ...data.stories ]);
				if (data.endOfStories) {
					setNoMoreStories(true);
					notify({
						title: `${t('You have reached the end of your feed!')}`,
						severity: NOTIFICATION_SEVERITY_INFO,
						creationDate: ''
					});
				}
			} else {
				notify(pleaseRefreshError);
			}
		} catch (error) {
			console.log(error);
			notify(pleaseRefreshError);
		}
	};

	return (
		<div className="lykioccs-main-content lykiocss-stories">
			{isLoading ? (
				<Loading/>
			) : (
				<Container>
					<Row>
						<Col xs={12}>
							<h1 className={`lykiocss-page-title lykiocss-center ${makeHeadersDark && 'lykiocss-dark-color'}`}>{tenantName} {storiesName}</h1>
							<div className={`lykiocss-stories-tagline lykiocss-center ${makeHeadersDark && 'lykiocss-dark-color'}`}>
								<div>{storiesTagLine}</div>
							</div>

							<div className="lykiocss-center lykiocss-margin-top-1em">
								<LykioButton onClick={() => { navigate(CREATE_URL); }}>
								{t("Create your story")}
								</LykioButton>
							</div>
						</Col>
					</Row>
						
					<Row className="lykiocss-margin-top-2em">
						<Col xs={12}>	
							<div className="lykiocss-stories-container lykiocss-margin-top-2em">
								{stories.length < 1 ? (
									<div className='lykiocss-center'>{t('No stories yet, add something!')}</div>
								) : (
									<div className="lykiocss-stories-gallery">
										{stories.map((story, index) => {
											return <Story story={story} />;
										})}
										
									</div>
								)}
								<div className="lykiocss-stories-loader-center">
								{feedLoading && <LoadingButton loading variant="outlined">
													{t("Submit")}
												</LoadingButton>}
								{noMoreStories ? (
										<span>{t("This is the end of your feed")}</span>
									) : (
										(stories.length > 1 && !feedLoading) && (
											<LykioButton variant="contained"
											className="float-end lykiocss-form-button"
											
												onClick={() => {
													fetchMoreStories();
												}}
											>
												{t('Load More Stories')}
											</LykioButton>
										)
									)}
								</div>
							</div>
						</Col>

					</Row>

					<Row>
						<Col xs={12}>	
							
						</Col>

					</Row>
				</Container>
			)}
		</div>
	);
};


export default Stories;
