function friendlyDate(date) {
    if(typeof(date)==='string' && date===''){
      console.log('no date was given');
      return '';
    }
    if(typeof(date)==='string'){
        date = new Date(date);
    }
    
    let date_month = date.getUTCMonth() + 1;
    let date_day = date.getUTCDate();
    let date_year = date.getUTCFullYear();
    let date_hours = date.getUTCHours()+1;
    let date_minutes = date.getMinutes();
    if(new String(date_minutes).length===1){
      date_minutes='0'+date_minutes;
    }

    return `${date_day}-${date_month}-${date_year} ${date_hours}:${date_minutes}`;
}

export default friendlyDate;