import React, { useState, useEffect } from "react";
import {
  Avatar,
  Message,
  MessageList,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";
import { format } from "date-fns";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import VideoFileIcon from '@mui/icons-material/VideoFile';
import AttachFileIcon from '@mui/icons-material/AttachFile';

const Messages = (props) => {
  const attachmentContentType = (message) => {
    const type = message.attachment.s3url.substring(
      message.attachment.s3url.lastIndexOf(".") + 1,
      message.attachment.s3url.length
    );
    const s3url = message.attachment.s3url;
    const fileName = message.attachment.name;
    switch (type) {
      case "jpeg":
      case "jpg":
      case "png":
        return (
          <a href={s3url} target={"_blank"}>
            <img loading="lazy" src={s3url} alt={`${s3url}`} width={200} />
          </a>
        );
      case "mp4":
      case "m4v":
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        marginRight: 8,
                    }}
                >
                    <a href={s3url} target={"_blank"}>
                        <VideoFileIcon sx={{ fontSize: "50px", color: "white" }} />
                    </a>
                </div>
                <div>
                    <span style={{ color: "#fff" }}>{truncate(fileName)}</span>
                </div>
                <div
                    style={{
                        marginLeft: 8,
                    }}
                >
                    <a href={s3url} target={"_blank"}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="28"
                            height="28"
                            fill="#ffffff"
                            className="bi bi-arrow-down-circle"
                            viewBox="0 0 16 16"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"
                            />
                        </svg>
                    </a>
                </div>
            </div>
        );
      case "pdf":
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginRight: 8,
              }}
            >
              <a href={s3url} target={"_blank"}>
                <PictureAsPdfIcon sx={{ fontSize: "50px", color: "white" }} />
              </a>
            </div>
            <div>
              <span style={{ color: "#fff" }}>{truncate(fileName)}</span>
            </div>
            <div
              style={{
                marginLeft: 8,
              }}
            >
              <a href={s3url} target={"_blank"}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  fill="#ffffff"
                  className="bi bi-arrow-down-circle"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"
                  />
                </svg>
              </a>
            </div>
          </div>
        );
      default:
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        marginRight: 8,
                    }}
                >
                    <a href={s3url} target={"_blank"}>
                        <AttachFileIcon sx={{ fontSize: "50px", color: "white" }} />
                    </a>
                </div>
                <div>
                    <span style={{ color: "#fff" }}>{truncate(fileName)}</span>
                </div>
                <div
                    style={{
                        marginLeft: 8,
                    }}
                >
                    <a href={s3url} target={"_blank"}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="28"
                            height="28"
                            fill="#ffffff"
                            className="bi bi-arrow-down-circle"
                            viewBox="0 0 16 16"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"
                            />
                        </svg>
                    </a>
                </div>
            </div>
        )
    }
  };

  const truncate = (str) => {
    return str.length > 25 ? str.substring(0, 24) + "..." : str;
  };

  const messageModel = (message) => {
    let date = new Date(message.createdAt);

    if (message.attachment) {
      attachmentContentType(message);
      return (
        <Message
          key={message._id}
          model={{
            sentTime: "15 mins ago",
            sender: message.sender.firstname,
            direction:
              message.sender._id === props.loggedUser.id
                ? "outgoing"
                : "incoming",
            position: "first",
          }}
        >
          <Avatar
            src={message.sender.profilepicture}
            name={message.sender.firstname}
          />
          <Message.CustomContent>
            {attachmentContentType(message)}
          </Message.CustomContent>
          <Message.Footer sentTime={format(date, "dd MMM, HH:mm ")} />
        </Message>
      );
    } else {
      return (
        <Message
          key={message._id}
          model={{
            message: message.content,
            sentTime: "15 mins ago",
            sender: message.sender.firstname,
            direction:
              message.sender._id === props.loggedUser.id
                ? "outgoing"
                : "incoming",
            position: "first",
          }}
        >
          <Avatar
            src={message.sender.profilepicture}
            name={message.sender.firstname}
          />
          <Message.Footer sentTime={format(date, "dd MMM, HH:mm ")} />
        </Message>
      );
    }
  };

  return (
    <>
      <MessageList
        typingIndicator={
          props.isTyping ? <TypingIndicator content=" is typing" /> : ""
        }
      >
        {/*<MessageSeparator content="Saturday, 27 April 2022" />*/}
        {props.messages.map((m, i) => messageModel(m))}
      </MessageList>
    </>
  );
};

export default Messages;
