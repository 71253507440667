import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import Story from '../stories/Story';
import { LIBRARY_RESOURCES } from '../../Utils/Constants';


function UnitSplider(props) {
  const hideStatistics = props.hideStatistics; 
  const hideOverviewPage = props.hideOverviewPage;
  const libraryType = props.libraryType;
  console.log('>>>>'+libraryType);
  const options = {
    type: 'slide',
    width: libraryType===LIBRARY_RESOURCES ? '80%': '100%',
    perPage: 5,
    perMove: 3,
    breakpoints: {
      990: {
        perPage: 3,
        perMove: 2
      },
      767: {
        perPage: 2,
        perMove: 1
      },
      640: {
        perPage: 1,
        perMove: 1
      }
    },
    rewind: true,
    gap: '0.1em',
    ...props
  }
    return ( <Splide
        options={ options }>
          {props.units.map((unit, i) => {
            return (
              <SplideSlide key={i}>
                <Story story={unit} libraryType={libraryType} hideStatistics={hideStatistics} hideOverviewPage={hideOverviewPage}/>
              </SplideSlide>
            );
          })}
        </Splide> );
}

export default UnitSplider;