import Skeleton from '@mui/material/Skeleton';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

function GridLoading(props) {
	return (
    <div>
	  <h1 className={`lykiocss-page-title lykiocss-center ${props.makeHeadersDark && 'lykiocss-dark-color'}`}>{props.t(`${props.storiesName}`)}</h1>
        <div className="lykiocss-stories-container">
			<Container>
			<Row>
				<Col md={4}><Skeleton variant="rectangular" width={400} height={400} /></Col>
				<Col md={4}><Skeleton variant="rectangular" width={400} height={400} /></Col>
				<Col md={4}><Skeleton variant="rectangular" width={400} height={400} /></Col>
			</Row>
			<Row style={{marginTop:'20px'}}>
				<Col md={4}><Skeleton variant="rectangular" width={400} height={400} /></Col>
				<Col md={4}><Skeleton variant="rectangular" width={400} height={400} /></Col>
				<Col md={4}><Skeleton variant="rectangular" width={400} height={400} /></Col>
			</Row>
			</Container>
        </div>
    </div>
  );
}

export default GridLoading;