import React from 'react';
import './activeProfileImage.css';
import { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import Spinner from 'react-bootstrap/Spinner';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import {
	AUTHORIZED_URL_PREFIX,
	USER_BY_ID_URL,
	AxiosConfig
} from '../../Utils/Constants';

const ActiveProfileImage = (props)=>{
	const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState(props.user);
    const showName = props.showName;
	const openInNewTab = props.openInNewTab;
	const doubleSize = props.doubleSize;
    const urlToUserProfile = `/profile/${user._id}`;


	useEffect(() => {
			const fetchUser = async () => {
				try {
					const { data } = await axios.post(
						`${AUTHORIZED_URL_PREFIX}${USER_BY_ID_URL}`,
						{ userId: user },
						AxiosConfig()
					);
					if(data.success) {
                        setUser(data.user);
						setIsLoading(false);
					}
				} catch (error) {
					console.error(error);
                }
			};
            if(typeof(user)==='string'){
				setIsLoading(true);
			    fetchUser();
            }
		},
		[]
	);

    return <div className="lykiocss-active-profile-container">
		{isLoading ? <Spinner animation="border" /> : <React.Fragment>
			<Tooltip title={`View ${user.firstname}  ${user.lastname}'s Profile`}>
			<div className='lykiocss-active-profile-with-name'>
			<Link to={urlToUserProfile} target={openInNewTab ? "_blank" : ''} rel="noopener noreferrer">
				<Image src={user.profilepicture} className="lykiocss-active-profile-image rounded-circle" style={doubleSize && {width:'3rem', height: '3rem'}} />
				{showName && <span className="lykiocss-active-profile-name lykiocss-text-shadow">
				&nbsp;{user.firstname} {user.lastname}
				</span>}
			</Link>
			</div>
			</Tooltip>

		</React.Fragment>
		}
	</div>
}

export default ActiveProfileImage;