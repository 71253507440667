import React, { useMemo, useState } from 'react';
import { useSortBy, useTable, usePagination } from 'react-table';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import {
	AUTHORIZED_URL_PREFIX,
	SEND_ACTIVATION_URL,
	MODERATE_USER,
	NOTIFICATION_SEVERITY_SUCCESS,
	AxiosConfig,
	PENDING_USER,
	ACTIVE_USER,
	LOCKED_USER,
	USER_EDIT_WITHOUTPARAM_URL
} from '../../Utils/Constants';
import t from '../../Utils/Translation';
import { notify, pleaseRefreshError } from '../../Utils/Notificator';
import LykioButton from "../common/LykioButton";
import './usersTable.css';
import friendlyDate from '../../Utils/FriendlyDate';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Form from "react-bootstrap/Form";


const UsersTable = (props) => {
	const navigate = useNavigate();
	const assignAdminRole = props.assignAdminRole;
	const users = assignAdminRole ? props.users : props.users.filter(user=> user.role.name!=='admin');
	const [ myTableData, setmyTableData ] = useState(useMemo(() => [ ...users ], []));

	//Table Design Headers/Columns
	const myTableColumns = useMemo(
		() => [
			{
				Header: t('Full Name'),
				accessor: 'fullName'
			},
			{
				Header: t('Email'),
				accessor: 'email'
			},
			{
				Header: t('Signed up'),
				accessor: 'creationDate',
				Cell: ({ value }) => {
					return friendlyDate(new Date(value));
				}
			},
			{
				Header: t('Department'),
				accessor: 'department',
				Cell: ({ value }) => {
					return value ? value : 'none';
				}
			},
			{
				Header: t('Status'),
				accessor: 'status',
				Cell: ({ value }) => {
					return t(value);
				}
			}
		],
		[]
	);

	//Insert column with Actions buttons
	const tableHooks = (hooks) => {
		hooks.visibleColumns.push((columns) => [
			...columns,
			{
				id: 'Actions',
				Header: t('Actions'),
				Cell: ({ row }) => (
					<React.Fragment>
						{getButtons(users[row.id]._id, users[row.id].status, users[row.id].role.name)}
						{/* {userTableButtonComponent('Edit', () => editUnitHandler(units[row.id]._id))} */}
					</React.Fragment>
				)
			}
		]);
	};

	//React Table Setup
	const tableInstance = useTable(
		{ columns: myTableColumns, data: myTableData },
		tableHooks,
		useSortBy,
		usePagination
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		pageOptions,
		gotoPage,
		pageCount,
		setPageSize,
		state,
		prepareRow
	} = tableInstance;

	const { pageIndex, pageSize } = state;

	//LykioButton Component
	const userTableButtonComponent = (label, buttonHandler) => {
		return (<React.Fragment>
			<LykioButton variant="text" onClick={() => {
				buttonHandler();
			}}>
				{t(`${label}`)}
			</LykioButton>&nbsp;
			</React.Fragment>
		);
	};

	const userTableSelectComponent = (userId, userStatus, userRole) => {
		const handleRoleChange = async(selectedRole)=>{
			console.log(`${userId} ${selectedRole}`)
			userModerationHandler(userId, userStatus, selectedRole)
		}

		return (
			<div style={{width: '110px', display:'inline-block'}}>
			<Form.Select onChange={(e) => handleRoleChange(e.target.value)}>
					<option selected={userRole==='user'} value={'user'}>
					{t("Learner")}
					</option>
					<option selected={userRole==='teacher'} value={'teacher'}>
					{t("Educator")}
					</option>
					<option selected={userRole==='manager'} value={'manager'}>
					{t("Manager")}
					</option>
					{assignAdminRole && 
					<option selected={userRole==='admin'} value={'admin'}>
					{t("Admin")}
					</option>}
			</Form.Select>
			</div>
		);
	};

	//Decide what buttons to show
	const getButtons = (userId, userStatus, userRole) => {
		return (
			<React.Fragment>
				<React.Fragment>{userTableSelectComponent(userId, userStatus, userRole)}&nbsp;</React.Fragment>

				{(userStatus === PENDING_USER) && 
				<React.Fragment>
					{userTableButtonComponent('Resend Activation', () => sendActivationHandler(userId))}
				</React.Fragment>
				}
				
				{(userStatus === ACTIVE_USER) && 
				<React.Fragment>{userTableButtonComponent('Lock', () => userModerationHandler(userId, LOCKED_USER)
				)}</React.Fragment>
				}
				
				{(userStatus === LOCKED_USER) && 
				<React.Fragment>{userTableButtonComponent('Activate', () => userModerationHandler(userId, ACTIVE_USER)
				)}</React.Fragment>
				}
				 
				<React.Fragment>{userTableButtonComponent('Edit', () => navigate(`${USER_EDIT_WITHOUTPARAM_URL}/${userId}`)
				)}</React.Fragment>
				
			</React.Fragment>)
	};

	//LykioButton Handlers
	const sendActivationHandler = async (userId) => {
		try {
			const { data } = await axios.post(
				`${AUTHORIZED_URL_PREFIX}${SEND_ACTIVATION_URL}`,
				{ userId },
				AxiosConfig()
			);
			if (data.success === true) {
				notify({
					title: `${t('Activation e-mail sent')}`,
					severity: NOTIFICATION_SEVERITY_SUCCESS,
					creationDate: ''
				});
			} else {
				notify(pleaseRefreshError);
			}
		} catch (error) {
			console.log(error);
			notify(pleaseRefreshError);
		}
	};

	const userModerationHandler = async (userId, newstatus, newrole) => {
		try {
			const { data } = await axios.post(
				`${AUTHORIZED_URL_PREFIX}${MODERATE_USER}`,
				{ userId, newstatus: newstatus, newrole: newrole},
				AxiosConfig()
			);
			if (data.success === true) {
				notify({
					title: `${t('User Updated')}`,
					severity: NOTIFICATION_SEVERITY_SUCCESS,
					creationDate: ''
				});
				const userIndex = users.findIndex((obj) => obj._id === userId);
				users[userIndex].status = newstatus;
				const dataCopy = [ ...users ];
				setmyTableData(dataCopy);
			} else {
				notify(pleaseRefreshError);
			}
		} catch (error) {
			console.log(error);
			notify(pleaseRefreshError);
		}
	};

	return (
		<React.Fragment>
			<Table striped bordered hover size="sm" {...getTableProps()}>
				<TableHead>
					{headerGroups.map((headerGroup) => (
						<TableRow {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<th {...column.getHeaderProps(column.getSortByToggleProps())}>
									{column.render('Header')}
									{column.isSorted ? column.isSortedDesc ? ' ↓' : ' ↑' : ''}
								</th>
							))}
						</TableRow>
					))}
				</TableHead>
				<TableBody {...getTableBodyProps()}>
					{page.map((row) => {
						prepareRow(row);
						return (
							<TableRow {...row.getRowProps()}>
								{row.cells.map((cell) => {
									return <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>;
								})}
							</TableRow>
						);
					})}
				</TableBody>
			</Table>

			{/*Pagination*/}
			<div className="lykiocss-pagination lykiocss-stick-to-bottom">
				<LykioButton variant="contained" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
					{t('First')}
				</LykioButton>&nbsp;
				<LykioButton variant="contained" onClick={() => previousPage()} disabled={!canPreviousPage}>
					{t('Previous')}
				</LykioButton>&nbsp;
				<span className="lykiocss-pageNum">
					{' '}
					{t('Page')} {pageIndex + 1} / {pageOptions.length}{' '}
				</span>&nbsp;
				<span>
					{' '}
					| {t('Page')}{' '}
					<input
						className="lykiocss-pageSelector"
						type="number"
						value={pageIndex + 1}
						onChange={(e) => gotoPage(e.target.value ? Number(e.target.value) - 1 : 0)}
					/>{' '}
				</span>&nbsp;
				<LykioButton variant="contained" onClick={() => nextPage()} disabled={!canNextPage}>
					{t('Next')}
				</LykioButton>&nbsp;
				<LykioButton
					variant="contained"
					onClick={() => gotoPage(pageCount - 1)}
					disabled={!canNextPage}
				>
					{t('Last')}
				</LykioButton>&nbsp;
				<select
					className="lykiocss-items-count-selector"
					value={pageSize}
					onChange={(e) => setPageSize(Number(e.target.value))}
				>
					{[ 5, 10, 25, 50, 100 ].map((pageSize) => (
						<option key={pageSize} value={pageSize}>
							{' '}
							{pageSize}
						</option>
					))}
				</select>
			</div>
		</React.Fragment>
	);
};

export default UsersTable;
