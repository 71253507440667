import { useEffect, useContext } from 'react';
import { LykioContext } from '../routing/LykioAuthorizedRoute';
import { Link, useNavigate } from 'react-router-dom';

import './navigation.css';
import lykio_logo from '../../multimedia/lykio_logo_transparent.png';
import {
	FRONTEND_LOGIN_URL,
	PROFILE_URL_WITHOUT_PARAM,
	CONTENT_MANAGEMENT_URL,
	CREATE_URL,
	USER_MANAGEMENT_WITHOUTPARAM_URL,
	MESSAGES_URL,
	IDEOLOGY_URL,
	SETTINGS_URL,
	GUIDES_URL,
	LEADERBOARDS_URL,
	SEARCH_URL,
	REPORTS_URL,
	NOTIFICATIONS_URL,
	STORIES_URL,
	STORIES_FOLLOWING_URL,
	PLANS_URL,
	STRIPE_PAYMENT_URL,
	LIBRARY_URL_WITHOUT_PARAM,
	PLAYLISTS_URL
} from '../../Utils/Constants';
import Image from 'react-bootstrap/Image';
import Dropdown from 'react-bootstrap/Dropdown';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { toast } from 'react-toastify';
import ProfileBox from './ProfileBox';
import 'react-toastify/dist/ReactToastify.css';
import timeSince from '../../Utils/TimeSince';
import notify from '../../Utils/Notificator';
import t from '../../Utils/Translation';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import MailIcon from '@mui/icons-material/Mail';


toast.configure();
const Navigation = (props) => {
	const context = useContext(LykioContext);
	const userPermissions = context.userPermissions;
	const categories = context.categories;
	const tenantProperties = context.tenantProperties;
	const userDetails = context.userDetails;
	const logo = tenantProperties && tenantProperties.logoS3Url ? tenantProperties.logoS3Url : lykio_logo;
	const navigate = useNavigate();
	const googleSSO = localStorage.getItem('googleSSO');
	const group = context.group;

	useEffect(() => {
		if (!localStorage.getItem('authToken')) {
			navigate({ FRONTEND_LOGIN_URL });
		}
	}, []);

	const logoutHandler = () => {
		localStorage.removeItem('authToken');
		localStorage.removeItem('showToastNotificationsToken');
		localStorage.removeItem('user');
		localStorage.removeItem('userId');
		localStorage.removeItem('teacherbookActivated');
		navigate(FRONTEND_LOGIN_URL);
	};

	const dismissPopover = () => {
		document.body.click();
	};

	const notificationsPopover = (
		<Popover id="popover-basic" onClick={dismissPopover}>
			<Popover.Header as="h3">
				<Link className="lykiocss-navigation-link-no-decoration" to={NOTIFICATIONS_URL}>
					{t('My Notifications')}
				</Link>
			</Popover.Header>
			<Popover.Body>
				{userDetails && userDetails.notifications.length > 0 ? (
					<div>
						{userDetails.notifications.map((notification, i) => {
							if (i < 5) {
								if (localStorage.getItem('showToastNotificationsToken') === null) {
									notify(notification);
									{
										(i === userDetails.notifications.length - 1 || i == 4) &&
											localStorage.setItem('showToastNotificationsToken', false);
									}
								}
								return (
									<div key={i}>
										{notification.title} {timeSince(notification.creationDate)}
										{i < userDetails.notifications.length - 1 && <Dropdown.Divider />}
									</div>
								);
							}
							if (i == 5) {
								return (
									<Link
										key={i}
										className="lykiocss-navigation-link-no-decoration"
										to={NOTIFICATIONS_URL}
									>
										{t('Show All')}
									</Link>
								);
							}
						})}
					</div>
				) : (
					<span>{t('No unread notifications')}</span>
				)}
			</Popover.Body>
		</Popover>
	);

	const profileOptionsPopover = (
		<Popover id="popover-profile" className="Lykiopopover" onClick={dismissPopover}>
			<Popover.Body className="Lykiopopover">
				{userPermissions.includes('profile') && (
					<Dropdown.Item as={Link} to={`${PROFILE_URL_WITHOUT_PARAM}/me`}>
						<i className="bi bi-person-circle" />&nbsp;{t('Profile')}
					</Dropdown.Item>
				)}
				{userPermissions.includes('reports') && (
					<Dropdown.Item className="lykiocss-navigation-hide-on-desktop" as={Link} to={REPORTS_URL}>
						<i className="bi bi-pie-chart-fill" />&nbsp;{t('Reports')}
					</Dropdown.Item>
				)}
				{userPermissions.includes('usermanagement') && (
					<Dropdown.Item
						className="lykiocss-navigation-hide-on-desktop"
						as={Link}
						to={`${USER_MANAGEMENT_WITHOUTPARAM_URL}/${group}`}
					>
						<i className="bi bi-people-fill" />&nbsp;{t('User Management')}
					</Dropdown.Item>
				)}
				{userPermissions.includes('contentmanagement') && (
					<Dropdown.Item
						className="lykiocss-navigation-hide-on-desktop"
						as={Link}
						to={CONTENT_MANAGEMENT_URL}
					>
						<i className="bi bi-pencil-square" />&nbsp;{t('Content Management')}
					</Dropdown.Item>
				)}
				{userPermissions.includes('messages') && (
					<Dropdown.Item as={Link} to={MESSAGES_URL}>
						<i className="bi bi-chat-left-dots" />&nbsp;{t('Messages')}
					</Dropdown.Item>
				)}
				{userPermissions.includes('library') && (
					<Dropdown.Item as={Link} to={PLAYLISTS_URL}>
						<i className="bi bi-bookmark-heart" />&nbsp;{t('Favourites')}
					</Dropdown.Item>
				)}
				{userPermissions.includes('ideology') && (
					<Dropdown.Item as={Link} to={IDEOLOGY_URL}>
						<i className="bi bi-book" />&nbsp;{t('Ideology')}
					</Dropdown.Item>
				)}

				{userPermissions.includes('admin') && (
					<Dropdown.Item as={Link} to={PLANS_URL}>
						<i className="bi bi-credit-card" />&nbsp;{t('Billing')}
					</Dropdown.Item>
				)}
				{userPermissions.includes('admin') && (
					<Dropdown.Item as={Link} to={STRIPE_PAYMENT_URL}>
						<i className="bi bi-credit-card" />&nbsp;{t('Pay Here')}
					</Dropdown.Item>
				)}
				{userPermissions.includes('settings') && (
					<Dropdown.Item as={Link} to={SETTINGS_URL}>
						<i className="bi bi-gear" />&nbsp;{t('Settings')}
					</Dropdown.Item>
				)}
				{userPermissions.includes('guides') && (
					<Dropdown.Item as={Link} to={GUIDES_URL}>
						<i className="bi bi-info-square" />&nbsp;{t('Guides')}
					</Dropdown.Item>
				)}
				{userPermissions.includes('search') && (
					<Dropdown.Item as={Link} to={SEARCH_URL}>
						<i className="bi bi-search" />&nbsp;{t('Search')}
					</Dropdown.Item>
				)}
				<Dropdown.Divider className="Lykiodivider" />
				{!googleSSO && (
					<Dropdown.Item as={Link} to="/" onClick={logoutHandler}>
						<i className="bi bi-box-arrow-right" />&nbsp;{t('Logout')}
					</Dropdown.Item>
				)}
			</Popover.Body>
		</Popover>
	);

	const adminOptionsPopover = (
		<Popover id="popover-profile" className="Lykiopopover" onClick={dismissPopover}>
			<Popover.Body className="Lykiopopover">
				{userPermissions.includes('reports') && (
					<Dropdown.Item as={Link} to={REPORTS_URL}>
						{t('Reports')}
					</Dropdown.Item>
				)}
				{userPermissions.includes('usermanagement') && (
					<Dropdown.Item as={Link} to={`${USER_MANAGEMENT_WITHOUTPARAM_URL}/${group}`}>
						{t('User Management')}
					</Dropdown.Item>
				)}
				{userPermissions.includes('contentmanagement') && (
					<Dropdown.Item as={Link} to={CONTENT_MANAGEMENT_URL}>
						{t('Content Management')}
					</Dropdown.Item>
				)}
			</Popover.Body>
		</Popover>
	);

	const categoriesOptionsPopover = (
		<Popover id="popover-profile" className="Lykiopopover" onClick={dismissPopover}>
			<Popover.Body className="Lykiopopover">
				{props.categories &&
					props.categories.map((category) => {
						return (
							<Dropdown.Item
								key={category._id}
								as={Link}
								to={`${LIBRARY_URL_WITHOUT_PARAM}/${category.reference}`}
							>
								{category.name}
							</Dropdown.Item>
						);
					})}
			</Popover.Body>
		</Popover>
	);

	return (
		<div className="LykioNav lykioccs-navigation-nav-container lykioccs-navigation-fixed-top">
			<Link to="/" className="lykioccs-navigation-navbar-logo-div">
				<Image className="lykioccs-navigation-navbar-logo-image" src={logo} alt="" />
			</Link>

			<div className="lykiocss-navigation-navbar-menu-left">
				{userPermissions.includes('stories') && (
					<Link to={STORIES_URL} className="lykiocss-navigation-navbar-icon-outline">
						<div className="lykiocss-navigation-navbar-text">{t('Stories')}</div>
						<div className="lykiocss-navigation-navbar-icon">
							<i className="bi bi-camera-fill" />
						</div>
					</Link>
				)}
				{userPermissions.includes('library') && (
					<OverlayTrigger rootClose trigger="click" placement="bottom" overlay={categoriesOptionsPopover}>
						<a
							title="Library"
							className="lykiocss-navigation-navbar-content-arrow lykiocss-navigation-navbar-icon-outline"
						>
							<div className="lykiocss-navigation-navbar-text">{t('Library')}</div>
							<div className="lykiocss-navigation-navbar-icon">
								<i className="bi bi-book-fill" />
							</div>
							<i className="bi bi-caret-down-fill" />
						</a>
					</OverlayTrigger>
				)}
				{userPermissions.includes('leaderboards') && (
					<Link to={LEADERBOARDS_URL} className="lykiocss-navigation-navbar-icon-outline">
						<div className="lykiocss-navigation-navbar-text">{t('Leaderboards')}</div>
						<div className="lykiocss-navigation-navbar-icon">
							<i className="bi bi-bar-chart-line" />
						</div>
					</Link>
				)}
				{userPermissions.includes('stories') && (
					<Link to={STORIES_FOLLOWING_URL} className="lykiocss-navigation-navbar-icon-outline">
						<div className="lykiocss-navigation-navbar-text">{t('Feed')}</div>
						<div className="lykiocss-navigation-navbar-icon">
							<i className="bi bi-camera-fill" />
						</div>
					</Link>
				)}
				{userPermissions.includes('admin') && (
					<OverlayTrigger rootClose trigger="click" placement="bottom" overlay={adminOptionsPopover}>
						<div className="lykiocss-navigation-hide-on-mobile">
							<a
								title="Admin"
								className="lykiocss-navigation-navbar-content-arrow lykiocss-navigation-navbar-icon-outline"
							>
								<div className="lykiocss-navigation-navbar-text">{t('Admin')}</div>
								<i className="bi bi-caret-down-fill" />
							</a>
						</div>
					</OverlayTrigger>
				)}
			</div>

			<div className="lykiocss-navigation-navbar-menu-right">
				{userPermissions.includes('create') && (
					<Tooltip title={t("Add a story")}>
						<div>
							<Link
								to={CREATE_URL}
								title={t("Create a Story")}
								className="LykioNav bi-plus-circle lykiocss-navigation-navbar-icon-outline"
								style={{ fontSize: 22 }}
							/>
						</div>
					</Tooltip>
				)}
				{userPermissions.includes('notifications') && (
					<OverlayTrigger rootClose trigger="click" placement="bottom" overlay={notificationsPopover}>
						<Tooltip title={t("Notifications")} placement="right">
							<Badge badgeContent={userDetails.notifications.length} color="primary">
								<MailIcon color="action" />
							</Badge>
						</Tooltip>
					</OverlayTrigger>
				)}
				
				{userDetails && (
					<ProfileBox userDetails={userDetails}/>
				)}
				

				<OverlayTrigger rootClose trigger="click" placement="bottom" overlay={profileOptionsPopover}>
					<div className="lykiocss-navigation-navbar-profile-div" style={{paddingLeft:'0em'}}>
						<Tooltip title={t("Click for more options")} placement="left">
						<Image
							style={{ width: '4em', cursor: 'pointer' }}
							src={userDetails ? userDetails.profilepicture : null}
							fluid
						/>
						</Tooltip>
					</div>
				</OverlayTrigger>
			</div>
		</div>
	);
};

export default Navigation;
