import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { LykioContext } from "../routing/LykioAuthorizedRoute";
import './addPlaylist.css';
import t from "../../Utils/Translation";
import LykioButton from "../common/LykioButton";
import Form from 'react-bootstrap/Form';
import {
	AUTHORIZED_URL_PREFIX,
	PLAYLIST_URL,
	ASSIGN_PLAYLIST_URL,
	NOTIFICATION_SEVERITY_SUCCESS,
	NOTIFICATION_SEVERITY_WARNING,
	AxiosConfig
} from '../../Utils/Constants';
import { notify, pleaseRefreshError } from '../../Utils/Notificator';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Modal from 'react-bootstrap/Modal'
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import PlaylistItemsSplider from './PlaylistItemsSplider';
import AddableUnit from './AddableUnit';
import Playlist from './Playlist';
import Table from "react-bootstrap/Table";
import Loading from "../common/Loading";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const AddPlaylist = () => {
	const context = useContext(LykioContext);
	const userPermissions = context.userPermissions;
	const userDetails = context.userDetails;
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [isLoading, setIsLoading] = useState(true);

	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
	const handleCloseDeleteConfirmation = () => setShowDeleteConfirmation(false);
	const handleShowDeleteConfirmation = () => setShowDeleteConfirmation(true);
	const [playlistToRemove, setPlaylistToRemove] = useState();

	const [name, setName] = useState();
	const [unitsToAdd, setUnitsToAdd] =useState([]);
	const [deadline, setDeadline] = useState()
	const [playlists, setPlaylists] = useState([]);
	const [availableUnits, setAvailableUnits] = useState([]);
	const [groups, setGroups] = useState([]);
 	const [ result, setResult ]= useState();
	const canAssign= ((userPermissions && userPermissions.includes('teacherusermanagement')) || (userPermissions && userPermissions.includes('usermanagement'))) && true;


	useEffect(() => {
		const fetchData = async()=>{		
			const { data } = await axios.get(
			`${AUTHORIZED_URL_PREFIX}${PLAYLIST_URL}`,
			AxiosConfig()
		);
		setAvailableUnits(data.availableUnits);
		setPlaylists(data.playlists);
		if(userPermissions && userPermissions.includes('teacherusermanagement')){
			setGroups(data.groups.filter(element=> element._id.toString()===userDetails.group._id.toString()));
		}
		if(userPermissions && userPermissions.includes('usermanagement')){
			setGroups(data.groups);
		}
		setIsLoading(false);
		}
		fetchData();
	  }, []);

	const modifyUnitsToAdd = (unitToAddOrRemove, add) => {
		console.log(add);
		if(add){
			setUnitsToAdd([ ...unitsToAdd, unitToAddOrRemove ]);
			// setAvailableUnits(availableUnits.filter(unit=> unit._id.toString()!==unitToAddOrRemove._id.toString()));
		}else{
			setUnitsToAdd(unitsToAdd.filter(unit=> unit._id.toString()!==unitToAddOrRemove._id.toString()));
			// setAvailableUnits([ ...availableUnits, unitToAddOrRemove ]);
		}
	}

	const assignToGroupHandler = async(playlist, assignToPlaylist)=>{
		try {
			const { data } = await axios.post(
				`${AUTHORIZED_URL_PREFIX}${ASSIGN_PLAYLIST_URL}`,
				{assignToPlaylist: assignToPlaylist, playlistId: playlist._id, groups: groups},
				AxiosConfig()
			);
			if (data.success) {
				notify({
					title: `${t("You have successfully modified your playlist's assignees.")}`,
					severity: NOTIFICATION_SEVERITY_SUCCESS,
					creationDate: ''
				});
			}else{
				notify({
					title: `${t("An error occurred. Please try again later.")}`,
					severity: NOTIFICATION_SEVERITY_WARNING,
					creationDate: ''
				});
			}
			window.location.reload();
		}catch(error) {
			console.log(error);
			notify(pleaseRefreshError);
		}
	}

	const addPlaylistHandler = async (e) => {
		e.preventDefault();
		try {
			if(unitsToAdd.length>0){
			const { data } = await axios.post(
				`${AUTHORIZED_URL_PREFIX}${PLAYLIST_URL}`,
				{name, unitsToAdd, deadline},
				AxiosConfig()
			);
			if (data.success === true) {
				notify({
					title: `${t("You have successfully added a new playlist.")}`,
					severity: NOTIFICATION_SEVERITY_SUCCESS,
					creationDate: ''
				});
				window.location.reload();
			}
			}else{
				notify({
					title: `${t("You need to add at least one unit to your playlist.")}`,
					severity: NOTIFICATION_SEVERITY_WARNING,
					creationDate: ''
				});
			}
		} catch (error) {
			console.log(error);
			notify(pleaseRefreshError);
		}
	};

	const deletePlaylistHandler = async (playlistId) => {
		try {
			const { data } = await axios.post(
				`${AUTHORIZED_URL_PREFIX}${PLAYLIST_URL}`,
				{deletePlaylist: true, playlistId},
				AxiosConfig()
			);
			if (data.success === true) {
				notify({
					title: `${t("You have successfully deleted a playlist.")}`,
					severity: NOTIFICATION_SEVERITY_SUCCESS,
					creationDate: ''
				});
				window.location.reload();
			}
		} catch (error) {
			console.log(error);
			notify(pleaseRefreshError);
		}
	};

	return (isLoading ? <div><Loading/></div> :
		<div className="lykioccs-main-content">
			<div className="lykiocss-center lykiocss-margin-top-2em">
				<Container>
					<Row>
						<Col>
						<Form onSubmit={addPlaylistHandler} className="lykiocss-form lykiocss-form-border lykiocss-form-size" style={{paddingBottom:'4rem'}}>
						<div className="lykiocss-center lykiocss-title-light">{t('Add a new playlist')}</div>
							<Form.Group className="mb-3 lykiocss-margin-top-1em" controlId="name">
							<FloatingLabel controlId="floatingname" label={`Name`}>
							<Form.Control
								type="text"
								maxLength="30"
								required
								onChange={(e) => {
								setName(e.target.value);
								}}
							/>
							</FloatingLabel>
							</Form.Group>

							<Form.Group className="mb-3" controlId="group">
							<FloatingLabel controlId="floatingunits" label={t('All the units you have selected so far')}>
							<Form.Select>
								{unitsToAdd.length>0 ?
								unitsToAdd.map((unit) => (
								<option
									value={unit._id}
								>
									{unit.title}
								</option>
								)) : <option>{t("None yet")}</option>
							}
							</Form.Select>
							</FloatingLabel>
							</Form.Group>

							<Form.Group className="mb-3" controlId="group">
							<div className="lykiocss-center">
							<Tooltip title={t('Click to preview')}>
						  		<Button variant='text' onClick={()=>{handleShow()}}>{t(`${unitsToAdd.length} units added so far`)}</Button>
					  		</Tooltip>
							</div>
							</Form.Group>

							<div className='lykiocss-form'>
							<Form.Group controlId="title">
							<Autocomplete
							disablePortal
							id="combo-box-availableUnits"
							options={availableUnits}
							onChange={(event, value) => {
								console.log(value ? value.item.type : undefined)
								setResult(value ? value.item.result : undefined)
							}	
							}
							renderInput={(params) => (
								<TextField {...params} label={t("Search for lessons and stories to add to your playlist")} />
							)}
							/>
							</Form.Group>
							</div>

							<Form.Group>
							{result && 
							<div className="lykiocss-center lykiocss-margin-top-1em">
							<div className="lykiocss-center" style={{maxWidth:'15em'}}>
								<AddableUnit result={result} unitsToAdd={unitsToAdd} modifyUnitsToAdd={modifyUnitsToAdd}/>
								</div>		
							</div>
							}
							</Form.Group>


							{/* {canAssign && 
							<Form.Group className="mb-3 lykiocss-margin-top-1em" controlId="deadline">
							<Form.Label>{t('Complete Playlist By')}</Form.Label>
							<DatePicker selected={deadline} onChange={(date) => setDeadline(date)} />
							</Form.Group>
							} */}

							<LykioButton
								variant="contained"
								type="text"
								className="float-end lykiocss-form-button lykiocss-margin-top-half-em">
								{t('Save Playlist')}
							</LykioButton>
						</Form>
						</Col>
						<Col>
						<Form className="lykiocss-form lykiocss-form-border lykiocss-form-size">
						<div className="lykiocss-center lykiocss-title-light">{t(`My Playlists`)}</div>
						<div className="table-responsive lykiocss-scrollable-table-400 lykiocss-margin-top-1em">
							<Table striped bordered hover>
								<thead>
								<tr>
									<th>#</th>
									<th>{t("Name")}</th>
									<th>{t("Actions")}</th>
								</tr>
								</thead>
								<tbody>
								{playlists.map((playlist, i) => {
								return <tr>
								<td>{i + 1}</td>
								<td><Playlist playlist={playlist} groups={groups}/></td>
								<td><Tooltip title={t("Delete this playlist")}>
									<Button onClick={()=>{
									setPlaylistToRemove(playlist);
									handleShowDeleteConfirmation();
									}}>{t("Delete")}</Button></Tooltip>
									
									{/* {canAssign && <React.Fragment>
										{playlist.groupsAssigned.length>0 ?
										<Tooltip title={t("Remove from all my groups")}>
										<Button onClick={()=>{
										assignToGroupHandler(playlist, true);
										}}>{t("Unassign")}</Button>
										</Tooltip> : 
										<Tooltip title={t("Assign to all my groups")}>
										<Button onClick={()=>{
										assignToGroupHandler(playlist, false);
										}}>{t("Assign")}</Button></Tooltip>
										}
									</React.Fragment>} */}
									
								</td>
								</tr>
								})
								}
								</tbody>
							</Table>
							{playlists && playlists.length<1 && <p className="lykiocss-center">No playlists yet</p>}
							</div>
						</Form>
						</Col>
					</Row>

					<Modal size="lg" scrollable={true} centered show={show} onHide={handleClose} closeButton>
					<Modal.Header closeButton>
					<div className='lykiocss-title-light'>{t("The units you have added so far")}</div>
					</Modal.Header>
					<Modal.Body>
					{unitsToAdd.length>0 ? 
					<div>
						<div className='lykiocss-subtitle-light'>{t("Included in this playlist")}</div>
						<PlaylistItemsSplider units={unitsToAdd} unitsToAdd={unitsToAdd} addable={true} modifyUnitsToAdd={modifyUnitsToAdd}/>
					</div> 
					: <div className='lykiocss-center lykiocss-subtitle-light'>{t("No units added")}</div>}
					</Modal.Body>
					</Modal>

					<Modal size="sm" centered show={showDeleteConfirmation} onHide={handleCloseDeleteConfirmation} closeButton>
					<Modal.Body>
					<div className='lykiocss-text-showcase-light'>{t("Are you sure you want to delete")} <b>{playlistToRemove && playlistToRemove.name}</b>? {t("There will not be any way to recover it.")}</div>
					<div className='lykiocss-center' onClick={()=>{deletePlaylistHandler(playlistToRemove._id)}}>
					<Button variant='text'><DeleteForeverRoundedIcon />&nbsp;<span>{t("Yes, delete it.")}</span></Button>
					</div>
					</Modal.Body>
					</Modal>
				</Container>

				
			</div>
		</div>
	);
};

export default AddPlaylist;
