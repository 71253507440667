import Story from '../stories/Story';
import t from "../../Utils/Translation";
import QueuePlayNextSharpIcon from '@mui/icons-material/QueuePlayNextSharp';
import RemoveFromQueueSharpIcon from '@mui/icons-material/RemoveFromQueueSharp';

function AddableUnit(props) {
    const result = props.result;
	const unitsToAdd = props.unitsToAdd;
	const modifyUnitsToAdd = props.modifyUnitsToAdd;

	return (<Story story={result} addableUnit={true}>
					{modifyUnitsToAdd && (
                    unitsToAdd.filter(unit => unit._id.toString()===result._id.toString()).length>0 ? 
					<div className='lykiocss-playlist-icon' onClick={()=>{modifyUnitsToAdd(result, false)}}>
						<RemoveFromQueueSharpIcon />&nbsp;<span>{t("Remove")}</span>
					</div>
					: 
					<div className='lykiocss-playlist-icon' onClick={()=>{modifyUnitsToAdd(result, true)}}>
						<QueuePlayNextSharpIcon />&nbsp;<span>{t("Add")}</span>
					</div>)}
				</Story>
	)
}

export default AddableUnit;