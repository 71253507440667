import { useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
// import { Link } from "react-router-dom";
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import {
    NOTIFICATIONS_URL
} from "../../Utils/Constants";
import timeSince from '../../Utils/TimeSince';
import notify from '../../Utils/Notificator';
import t from '../../Utils/Translation';

function NotificationsNavItem(props) {
  const userDetails = props.userDetails;
  const dismissPopover = () => {
		document.body.click();
	};

  const notificationsPopover = (
		<Popover id="popover-basic" onClick={dismissPopover}>
			<Popover.Header as="h3">
				<Link underline="none" color="inherit" href={NOTIFICATIONS_URL} >
					{t('My Notifications')}
				</Link>
			</Popover.Header>
			<Popover.Body>
				{userDetails && userDetails.notifications.length > 0 ? (
					<div>
						{userDetails && userDetails.notifications.map((notification, i) => {
							if (i < 5) {
								if (localStorage.getItem('showToastNotificationsToken') === null) {
									notify(notification);
									{
										(i === userDetails.notifications.length - 1 || i == 4) &&
											localStorage.setItem('showToastNotificationsToken', false);
									}
								}
								return (
									<div key={i}>
										{notification.title} {timeSince(notification.creationDate)}
										{i < userDetails.notifications.length - 1 && <Dropdown.Divider />}
									</div>
								);
							}
							if (i == 5) {
								return (
                                        <Button size='small' href={NOTIFICATIONS_URL} variant="outlined">{t('Show All')}</Button>
								);
							}
						})}
					</div>
				) : (
					<span>{t('No unread notifications')}</span>
				)}
			</Popover.Body>
		</Popover>
	);

  return (
    <li className={"meta-nav-item "+ (props.leftmargin && ' meta-auto-left-margin')}>
      <OverlayTrigger rootClose transition trigger="click" placement="bottom" overlay={notificationsPopover}>
        <div className="meta-icon-button">
        <Tooltip title={t("Notifications")} placement="left">
          <Badge badgeContent={userDetails && userDetails.notifications.length} color="primary">
            <NotificationsNoneIcon color="action" />
          </Badge>
        </Tooltip>
        </div>
      </OverlayTrigger>
    </li>
  );
}

export default NotificationsNavItem;