import React, {useState} from "react";
import LykioButton from "../common/LykioButton";
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import ActiveProfileImage from '../common/ActiveProfileImage';
import Tooltip from '@mui/material/Tooltip';
import t from "../../Utils/Translation";

const ProfileStats = (props) => {
  const user = props.user;
  const [showFollowersModal, setShowFollowersModal] = useState(false);
  const [showFollowingModal, setShowFollowingModal] = useState(false);

  return (
    <React.Fragment>
    <div className="card-body text-center lykiocss-profile-text-color">
      <div className="row">
        <div className="col-4 border-end border-light">
          <h5 className="text-muted mt-1 mb-2 fw-normal">{t("XP")}</h5>
          <h5 className="mb-0 fw-bold">{user.xp} <i className="bi bi-bullseye"></i></h5>
        </div>
        
        <div className="col-4 border-end border-light">
          <h5 className="text-muted mt-1 mb-2 fw-normal">{t("Stories")}</h5>
          <h4 className="mb-0 fw-bold">{user.stories ? user.stories.length : 0} <i className="bi bi-collection"></i></h4>
        </div>
        
        <div className="col-4">
          <h5 className="text-muted mt-1 mb-2 fw-normal">{t("Impact")}</h5>
          <h4 className="mb-0 fw-bold">{Math.floor(user.impactIndex)} <i className="bi bi-award"></i></h4>
        </div>
      </div>
      <div className="row" style={{marginTop:'1em'}}>
        <div className="col-6 border-end border-light">
          <h5 className="text-muted mt-1 mb-2 fw-normal">{t("Followers")}</h5>
          <h4 className="mb-0 fw-bold">{user.followers ? user.followers.length : 0}
          <Tooltip title={t("Show my followers")} placement="bottom">
          <i onClick={() => {
                  setShowFollowersModal(true);
          }} className="lykiocss-active-on-hover bi bi-person-check-fill"></i>
          </Tooltip>
          </h4>
        </div>
        
        <div className="col-6 border-end border-light">
          <h5 className="text-muted mt-1 mb-2 fw-normal">{t("Following")}</h5>
          <h4 className="mb-0 fw-bold">{user.following ? user.following.length : 0} 
          <Tooltip title={t("Show all people I follow")} placement="bottom">
          <i onClick={() => {
                  setShowFollowingModal(true);
          }} className="lykiocss-active-on-hover bi bi-person-lines-fill"></i>
          </Tooltip>
          </h4>
        </div>
        

      </div>
    </div>

    <Modal
    centered
    show={showFollowersModal}
    onHide={() => {
      setShowFollowersModal(false);
    }}
    >
    <Modal.Body>
      <Container>
        <div className="lykiocss-subtitle-light">{t("My Followers")}</div>
        <div className="lykiocss-scrollable-table">
        {(user.followers && user.followers.length>0) ? 
        user.followers.map(follower => {
          return <div style={{marginBottom:'0.5em'}}>
            <ActiveProfileImage user={follower} showName={true}/> 
          </div>
        }) : <div>{t("You don't have any followers yet")}</div>
        }
        </div>
      </Container>
    </Modal.Body>
    </Modal>

    <Modal
    centered
    show={showFollowingModal}
    onHide={() => {
      setShowFollowingModal(false);
    }}
    >
    <Modal.Body>
      <Container>
        <div className="lykiocss-subtitle-light">{t("People I Follow")}</div>
        <div className="lykiocss-scrollable-table">
        {(user.following && user.following.length>0) ? 
        user.following.map(following => {
          return <div style={{marginBottom:'0.5em'}}>
            <ActiveProfileImage user={following} showName={true}/> 
          </div>
        }) : <div>{t("You are not following anybody yet")}</div>
        }
        </div>
      </Container>
    </Modal.Body>
    </Modal>
</React.Fragment>
);
};

export default ProfileStats;
