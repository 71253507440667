import React from 'react';
import ActiveGroup from '../common/ActiveGroup';
import './leaderboardTable.css';
import t from "../../Utils/Translation";

const LeaderboardGroupsTable = (props) => {
    const groups = props.groups;
    const value = props.value;
	return (
    <div className="container">
    <div className="row">
        <div className="col-md-12">
                {groups.length > 0 ?  <div className="card">
                    <div className="table-responsive lykiocss-leaderboards-scrollable-table">
                    <table className="table lykiocss-table-borderless no-wrap user-table mb-0">
                    <tbody>
                    {groups.map((group, index) => {
                        if(group.score>0){
                        return <tr key={index}>
                        <td className="pl-4">{index+1}</td>
                        <td>
                            <ActiveGroup reference={group.reference} name={group.name}/>
                        </td>
                        <td>
                        <div className='lykiocss-leaderboard-score'>
                            {value==='xp' && `${group.score} XP`}
                            {value==='stories' && `${group.score} ${group.score===1 ? 'story' : 'stories'}`}</div>
                        </td>
                        </tr>}else{return <React.Fragment></React.Fragment>}
                    })}
                    </tbody>
                    </table>
                </div>
                </div>
                : <div className='lykiocss-center'>{t("No entries yet")}</div>}
        </div>
    </div>
    </div>
	);
};

export default LeaderboardGroupsTable;
