import React from 'react';
import './activeProfileImage.css';
import { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import axios from 'axios';
import {
	AUTHORIZED_URL_PREFIX,
	USER_BY_ID_URL,
	AxiosConfig
} from '../../Utils/Constants';
import Skeleton from '@mui/material/Skeleton';
import Ratio from 'react-bootstrap/Ratio'


const ProfileCard = (props)=>{
	const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState(props.user);
    const showName = props.showName;
	const openInNewTab = props.openInNewTab;
    const urlToUserProfile = `/profile/${user._id}`;

	useEffect(
		() => {
			const fetchUser = async () => {
				try {
					const { data } = await axios.post(
						`${AUTHORIZED_URL_PREFIX}${USER_BY_ID_URL}`,
						{ userId: user },
						AxiosConfig()
					);
					if(data.success) {
                        setUser(data.user);
						setIsLoading(false);
					}
				} catch (error) {
					console.error(error);
                }
			};
            if(typeof(user)==='string'){
				setIsLoading(true);
			    fetchUser();
            }
		},
		[]
	);

    return <div>
		{isLoading ? <Skeleton variant="rectangular" width={400} height={400} /> : <React.Fragment>
			<div className="lykiocss-profile-card-container">
				<Link to={urlToUserProfile} target={openInNewTab ? "_blank" : ''} rel="noopener noreferrer">
				<Ratio aspectRatio="1x1">
					<Image src={user.profilepicture} className="lykiocss-profile-card-image" />
				</Ratio>
				</Link>
				<div className="lykiocss-profile-card-overlay">{`${user.firstname} ${user.lastname}`}</div>
			</div>
		</React.Fragment>
		}
	</div>
}

export default ProfileCard;