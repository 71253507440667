import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
  
console.log('btn>>>',getComputedStyle(document.documentElement).getPropertyValue('--primary-color'))

const LykioButton = styled(Button)(() => ({
backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--primary-color'),
color: getComputedStyle(document.documentElement).getPropertyValue('--secondary-color'),
borderColor: getComputedStyle(document.documentElement).getPropertyValue('--secondary-color'),
'&:hover': {
    backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--secondary-color'),
    color: getComputedStyle(document.documentElement).getPropertyValue('--primary-color'),
    borderColor: getComputedStyle(document.documentElement).getPropertyValue('--primary-color')
},
}));

export default LykioButton;