import React, { useState, useEffect, useContext } from 'react';
import { LykioContext } from "../routing/LykioAuthorizedRoute";
import axios from 'axios';
import t from "../../Utils/Translation";
import {
	AUTHORIZED_URL_PREFIX,
    PLAYLISTS_URL,
	AxiosConfig
} from '../../Utils/Constants';
import Loading from '../common/Loading';
import Units from '../home/Units';
import { notify, pleaseRefreshError } from '../../Utils/Notificator';
import HelpOutlineSharpIcon from '@mui/icons-material/HelpOutlineSharp';
import { Tooltip } from '@mui/material';
import friendlyDate from '../../Utils/FriendlyDate';
import ProgressBar from 'react-bootstrap/ProgressBar';


const Playlists = () => {
	const context = useContext(LykioContext);
	const tenantProperties = context.tenantProperties;
	const makeHeadersDark = tenantProperties && tenantProperties.makeHeadersDark;
	const [ units, setUnits ] = useState([]);
	const [playlists, setPlaylists] = useState([]);
	const [playlistsAssignedToMe, setPlaylistsAssignedToMe] = useState([])
	const [ isLoading, setIsLoading ] = useState(true);


	useEffect(() => {
		const fetchPlaylists = async () => {
			try {
				const { data } = await axios.get(`${AUTHORIZED_URL_PREFIX}${PLAYLISTS_URL}`, AxiosConfig());
				setUnits(data.units);
				let filteredAssignedPlaylists = data.playlistsAssignedToMe;
				for (let i = 0; i < data.playlistsAssignedToMe.length; i++) {
					for (let j = 0; j < data.playlists.length; j++) {
						data.playlistsAssignedToMe[i]._id===data.playlists[j]._id && (filteredAssignedPlaylists=filteredAssignedPlaylists.filter(playlist => playlist._id!==data.playlistsAssignedToMe[i]._id));
					}

				}
				setPlaylists(data.playlists);
				setPlaylistsAssignedToMe(filteredAssignedPlaylists);
				setIsLoading(false);
				if (!data.success) {
					notify(pleaseRefreshError);
				}
			} catch (error) {
				console.log(error);
				notify(pleaseRefreshError);
			}
		};
		fetchPlaylists();
	}, []);

	return (
		<div className="lykioccs-main-content lykiocss-category-bg">
			{isLoading ? (
				<Loading />
			) : (
				<div>
					<React.Fragment>
					
					{playlists.map(playlist => {
						return <React.Fragment>
							<h1 className={`lykiocss-slider-title lykiocss-center ${makeHeadersDark && 'lykiocss-dark-color'}`}>{playlist.name}</h1>
							<div className="lykiocss-center">
								<Tooltip title={t('Your progress for this collection')}>
									<div style={{marginBottom:'1em', minWidth:'10em'}}><ProgressBar now={playlist.completionPercentage} label={`${playlist.completionPercentage}%`} /></div>
								</Tooltip>
							</div>
							<div>
							<Units units={playlist.units} hideTitle={true} makeHeadersDark={makeHeadersDark}/>
							</div>
						</React.Fragment>
					})}

					{playlistsAssignedToMe.map(playlist => {
						return <React.Fragment>
							<Tooltip title={playlist.deadline ? `assigned to me by ${playlist.creator.firstname} ${playlist.creator.lastname}. Must be completed by ${friendlyDate(new Date(playlist.deadline))}.` : `assigned to me by ${playlist.creator.firstname} ${playlist.creator.lastname}.`}>
								<h1 className={`lykiocss-slider-title lykiocss-center ${makeHeadersDark && 'lykiocss-dark-color'}`}>{playlist.name}</h1>
							</Tooltip>
							<div>
							<Units units={playlist.units} hideTitle={true} makeHeadersDark={makeHeadersDark}/>
							</div>
						</React.Fragment>
					})}

					<h1 className={`lykiocss-slider-title lykiocss-center ${makeHeadersDark && 'lykiocss-dark-color'}`} style={{marginBottom:'0em'}}>{t('Favourites')}</h1>
					{units.length < 1 ? (
						<div className={`lykiocss-center ${makeHeadersDark && 'lykiocss-dark-color'}`}>{t('No favourites yet, add something!')}</div>
					) : (
						<div>
							<Units units={units} hideTitle={true} title={t('My Favourites')} makeHeadersDark={makeHeadersDark}/>
						</div>
					)}
					</React.Fragment>		
					</div>
			)}
		</div>
	);
};

export default Playlists;
