import { useState, useEffect, useContext } from 'react';
import { LykioContext } from '../routing/LykioAuthorizedRoute';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import t from "../../Utils/Translation";
import './contentS3Upload.css';
import LykioButton from "../common/LykioButton";
import Form from 'react-bootstrap/Form';
import {
	LANGUAGES_ARRAY,
	AUTHORIZED_URL_PREFIX,
    UNIT_EDIT_WITHOUTPARAM_URL,
    UNIT_EDIT_URL,
	GET_FULL_UNIT_EDIT_INFO_URL,
	CATEGORIES_URL,
	CONTENT_MANAGEMENT_ADDS3_URL,
	NOTIFICATION_SEVERITY_SUCCESS,
	AxiosConfig
} from '../../Utils/Constants';
import Loading from '../common/Loading';
import { notify, pleaseRefreshError } from '../../Utils/Notificator';
import FloatingLabel from "react-bootstrap/FloatingLabel";

const EditUnit = () => {
	const { unitId } = useParams();
	const context = useContext(LykioContext);
	const tenantProperties = context.tenantProperties;
	const languages = (tenantProperties && tenantProperties.languages && tenantProperties.languages.length>0) ? tenantProperties.languages : LANGUAGES_ARRAY;
    const [ title, setTitle ] = useState('');
	const [ description, setDescription ] = useState('');
	const [ objective, setObjective ] = useState('');
	const [ duration, setDuration ] = useState();
	const [ selectedCategory, setSelectedCategory ] = useState('');
	const [ language, setLanguage ] = useState('');
	const [ categoryreference, setCategoryreference ] = useState('');
	const [ availableCategories, setAvailableCategories ] = useState([]);
	const [ isLoading, setIsLoading ] = useState(true);

	useEffect(() => {
		const fetchUnitData = async () => {
			try {
				const { data } = await axios.post(`${AUTHORIZED_URL_PREFIX}${GET_FULL_UNIT_EDIT_INFO_URL}`,{unitId}, AxiosConfig());
				if (data.success) {
					let categories = [];
					data.categories.forEach(category => {
						categories.push(category);
						if(category.subCategories && category.subCategories.length > 0){
							category.subCategories.forEach(subCategory => {
								subCategory.name = `${category.name}-${subCategory.name}`;
								categories.push(subCategory);
							})
						}
					});
                    console.log(data.unit);
					setAvailableCategories(categories);
					setCategoryreference(data.unit.categoryreference);
                    setTitle(data.unit.title);
                    setDescription(data.unit.description);
					setObjective(data.unit.objective);
                    setLanguage(data.unit.defaultLanguage);
                    setDuration(data.unit.duration);
					setIsLoading(false);
				} else {
					notify(pleaseRefreshError);
				}
			} catch (error) {
				console.error(error);
				notify(pleaseRefreshError);
			}
		};
		fetchUnitData();
	}, []);

	const editUnit = async (e) => {
		e.preventDefault();
		try {
			const { data } = await axios.post(
				`${AUTHORIZED_URL_PREFIX}${UNIT_EDIT_WITHOUTPARAM_URL}`,
				{ unitId, categoryreference, language, title, description, duration, objective },
				AxiosConfig()
			);
			if (data.success === true) {
				notify({
					title: `${t('Your unit was modified successfully.')}`,
					severity: NOTIFICATION_SEVERITY_SUCCESS,
					creationDate: ''
				});
			} else {
				notify(pleaseRefreshError);
			}
		} catch (error) {
			console.log(error);
			notify(pleaseRefreshError);
		}
	};

	const handleCategoryChange = (e) => {
		setSelectedCategory(e.target.value);
		let ref;
		availableCategories.forEach((category) => {
			if (category._id === e.target.value) {
				ref = category.reference;
			}
		});
		setCategoryreference(ref);
	};

	const handleLanguageChange = (e) => {
		setLanguage(e.target.value);
	};

	return (
		<div className="lykioccs-main-content">
			{isLoading ? (
				<Loading />
			) : (
				<div className="lykiocss-center lykiocss-margin-top-2em">
					<Form onSubmit={editUnit} className="lykiocss-form lykiocss-form-border lykiocss-form-size">
						<div className="lykiocss-center lykiocss-title-light">
							{t('Edit Unit')}
						</div>
						<Form.Group className="mb-3" controlId="categories">
							<FloatingLabel controlId="floatingcats" label={t('Category')}>
							<Form.Select onChange={handleCategoryChange}>
								{availableCategories.map(
									(category) => {
									return <option selected={category.reference===categoryreference} key={category._id} value={category._id}>
										{category.name}
									</option>
									}
								)}
								{availableCategories.map(category => {
									if(category.subCategories && category.subCategories.length>0){
										category.subCategories.map((subCategory) => {
											return <option selected={subCategory.reference===categoryreference} key={subCategory._id} value={subCategory._id}>
												{subCategory.name}
											</option>
										})
									}	
								})}
							</Form.Select>
							</FloatingLabel>
						</Form.Group>
						<Form.Group className="mb-3" controlId="categories">
							<FloatingLabel controlId="floatinglangs" label={t('Language')}>
							<Form.Select onChange={handleLanguageChange}>
								{languages.map((languageItem, index) => (
									<option selected={languageItem.code===language} key={index} value={languageItem.code}>
										{languageItem.name}
									</option>
								))}
							</Form.Select>
							</FloatingLabel>
						</Form.Group>
						<Form.Group className="mb-3" controlId="title">
							<FloatingLabel controlId="floatingtitle" label={t('Title')}>
							<Form.Control
								required
								type="text"
								placeholder={t('Enter your title')}
								defaultValue={title}
								onChange={(e) => {
									setTitle(e.target.value);
								}}
							/>
							</FloatingLabel>
						</Form.Group>

						<Form.Group className="mb-3" controlId="description">
							<FloatingLabel controlId="floatingdesc" label={t('Description')}>
							<Form.Control
								required
								type="text"
								placeholder={t('Enter your description')}
								defaultValue={description}
								onChange={(e) => {
									setDescription(e.target.value);
								}}
							/>
							</FloatingLabel>
						</Form.Group>

						<Form.Group className="mb-3" controlId="objective">
							<FloatingLabel controlId="floatingobj" label={t('Objective')}>
							<Form.Control
								required
								type="text"
								placeholder={t('Enter your objective')}
								defaultValue={objective}
								onChange={(e) => {
									setObjective(e.target.value);
								}}
							/>
							</FloatingLabel>
						</Form.Group>

						<Form.Group className="mb-3" controlId="duration">
						<FloatingLabel controlId="floatingdur" label={t('Duration in minutes')}>
						<Form.Control
								type="number"
								maxLength="3"
								placeholder={t('Duration in minutes')}
								onChange={(e) => {
									setDuration(e.target.value);
								}}
							/>
						</FloatingLabel>
						</Form.Group>

						<LykioButton
							variant="contained"
							type="submit"
							className="float-end lykiocss-form-button"
						>
							{t('Update')}
						</LykioButton>
						</Form>
				</div>
			)}
		</div>
	);
};

export default EditUnit;
