import { useState, useEffect, useContext } from 'react';
import { LykioContext } from '../routing/LykioAuthorizedRoute';
import axios from 'axios';
import { useNavigate } from 'react-router';
import t from "../../Utils/Translation";
import './contentCreation.css';
import LykioButton from "../common/LykioButton";
import {
	AUTHORIZED_URL_PREFIX,
	CATEGORIES_URL,
	LANGUAGES_ARRAY,
	UPLOAD_ZIPPED_UNIT_URL,
	NOTIFICATION_SEVERITY_WARNING,
	NOTIFICATION_SEVERITY_INFO,
	NOTIFICATION_SEVERITY_SUCCESS,
	AxiosConfig
} from '../../Utils/Constants';
import Form from 'react-bootstrap/Form';
import Loading from '../common/Loading';
import { notify, pleaseRefreshError } from '../../Utils/Notificator';
import FloatingLabel from "react-bootstrap/FloatingLabel";


const ZipContentUpload = () => {
	const navigate = useNavigate();
	const context = useContext(LykioContext);
	const tenantProperties = context.tenantProperties;
	const languages = (tenantProperties && tenantProperties.languages && tenantProperties.languages.length>0) ? tenantProperties.languages : LANGUAGES_ARRAY;
	const [ title, setTitle ] = useState('');
	const [ description, setDescription ] = useState('');
	const [ objective, setObjective ] = useState('');
	const [ duration, setDuration ] = useState('1');
	const [ file, setFile ] = useState();
	const [ category, setCategory ] = useState('');
	const [ categoryreference, setCategoryreference ] = useState('');
	const [ availableCategories, setAvailableCategories ] = useState([]);
	const [ isLoading, setIsLoading ] = useState(true);
	const [startingFile, setStartingFile] = useState('index.html');
	const [coverImageUrl, setCoverImageUrl] = useState('cover.png');
	const [ language, setLanguage ] = useState(languages[0].code);


	useEffect(() => {
		const fetchCategoriesData = async () => {
			try {
				const { data } = await axios.get(`${AUTHORIZED_URL_PREFIX}${CATEGORIES_URL}`, AxiosConfig());
				if (data.success) {
					let categories = [];
					data.categories.forEach(category => {
						categories.push(category);
						if(category.subCategories && category.subCategories.length > 0){
							category.subCategories.forEach(subCategory => {
								subCategory.name = `${category.name}-${subCategory.name}`;
								categories.push(subCategory);
							})
						}
					});
					setAvailableCategories(categories);
					setCategory(data.categories[0]._id);
					setCategoryreference(data.categories[0].reference);
					setIsLoading(false);
				} else {
					notify(pleaseRefreshError);
				}
			} catch (error) {
				console.error(error);
				notify(pleaseRefreshError);
			}
		};

		fetchCategoriesData();
	}, []);

	const handleCategoryChange = (e) => {
		setCategory(e.target.value);
		let ref;
		availableCategories.forEach((category) => {
			if (category._id === e.target.value) {
				ref = category.reference;
			}
		});
		setCategoryreference(ref);
	};

	const handleLanguageChange = (e) => {
		setLanguage(e.target.value);
	};

	const unitUploadHandler = async (e) => {
		setIsLoading(true);
		e.preventDefault();
		let validFormFields = false;
		if (
			title.length > 1 &&
			description.length > 1 &&
			file &&
			category &&
			categoryreference &&
			startingFile.length > 1
		){
			validFormFields = true;
		}
		try {
			const formData = new FormData();
			formData.append('title', title);
			formData.append('description', description);
			formData.append('objective',objective);
			formData.append('language', language);
			formData.append('duration', duration);
			formData.append('file', file);
			formData.append('category', category);
			formData.append('categoryreference', categoryreference);
			formData.append('startingFile',startingFile);
			formData.append('coverImageUrl',coverImageUrl);

			if (validFormFields) {
				const { data } = await axios.post(
					`${AUTHORIZED_URL_PREFIX}${UPLOAD_ZIPPED_UNIT_URL}`,
					formData,
					AxiosConfig('multipart/form-data')
				);
				notify({
					title: `${t(
						'Your unit is being uploaded. Please stay on this page and you will be notified once the process is completed.'
					)}`,
					severity: NOTIFICATION_SEVERITY_INFO,
					creationDate: ''
				});
				if (data && data.success === true) {
					setTitle('');
					setDescription('');
					setDuration('1');
					setFile();
					setIsLoading(false); 
					notify({
						title: `${t(
							'You have successfully created a unit. Once it gets approved you will be able to see it in your selected category.'
						)}`,
						severity: NOTIFICATION_SEVERITY_SUCCESS,
						creationDate: ''
					});
				}
			} else {
				notify({
					title: `${t('You are missing some fields. Make sure to fill out all the fields.')}`,
					severity: NOTIFICATION_SEVERITY_WARNING,
					creationDate: ''
				});
			}
		} catch (error) {
			console.error(error);
			notify(pleaseRefreshError);
		}
		setIsLoading(false);
	};

	return (
		<div className="lykioccs-main-content">
			{isLoading ? (
				<Loading type='TailSpin' />
			) : (
				<div className="lykiocss-center lykiocss-margin-top-2em">
				<Form onSubmit={unitUploadHandler} className="lykiocss-form lykiocss-form-border lykiocss-form-size">
					<div className="lykiocss-center lykiocss-title-light">
						{t('Add Zipped Content')}
					</div>
					<Form.Group className="mb-3" controlId="categories">
						<FloatingLabel controlId="floatingcats" label={t('Category')}>
						<Form.Select onChange={handleCategoryChange}>
							{availableCategories.map(
								(category) => {
								return <option key={category._id} value={category._id}>
									{category.name}
								</option>
								}
							)}
							{availableCategories.map(category => {
								if(category.subCategories && category.subCategories.length>0){
									category.subCategories.map((subCategory) => {
										return <option key={subCategory._id} value={subCategory._id}>
											{subCategory.name}
										</option>
									})
								}	
							})}
						</Form.Select>
						</FloatingLabel>
					</Form.Group>
					<Form.Group className="mb-3" controlId="categories">
						<FloatingLabel controlId="floatinglangs" label={t('Language')}>
						<Form.Select onChange={handleLanguageChange}>
							{languages.map((language, index) => (
								<option key={index} value={language.code}>
									{language.name}
								</option>
							))}
						</Form.Select>
						</FloatingLabel>
					</Form.Group>
					<Form.Group className="mb-3" controlId="title">
						<FloatingLabel controlId="floatingtitle" label={t('Title')}>
						<Form.Control
							required
							type="text"
							placeholder={t('Enter your title')}
							defaultValue={title}
							onChange={(e) => {
								setTitle(e.target.value);
							}}
						/>
						</FloatingLabel>
					</Form.Group>
					<Form.Group className="mb-3" controlId="description">
						<FloatingLabel controlId="floatingdesc" label={t('Description')}>
						<Form.Control
							required
							type="text"
							placeholder={t('Enter your description')}
							defaultValue={description}
							onChange={(e) => {
								setDescription(e.target.value);
							}}
						/>
						</FloatingLabel>
					</Form.Group>

					<Form.Group className="mb-3" controlId="objective">
						<FloatingLabel controlId="floatingobj" label={t('Objective')}>
						<Form.Control
							type="text"
							placeholder={t('Enter your objective')}
							defaultValue={objective}
							onChange={(e) => {
								setObjective(e.target.value);
							}}
						/>
						</FloatingLabel>
					</Form.Group>

					<Form.Group className="mb-3" controlId="duration">
						<FloatingLabel controlId="floatingdur" label={t('Duration in minutes')}>
						<Form.Control
								type="number"
								maxLength="3"
								placeholder={t('Duration in minutes')}
								onChange={(e) => {
									setDuration(e.target.value);
								}}
							/>
						</FloatingLabel>
					</Form.Group>

					<Form.Group className="mb-3" controlId="startpage">
						<FloatingLabel controlId="floatingsf" label={t(`Starting file's relative path (e.g. 'index.html')`)}>
						<Form.Control
							required
							type="text"
							placeholder={t(`Enter your start page's relative path`)}
							defaultValue={startingFile}
							onChange={(e) => {
								setStartingFile(e.target.value);
							}}
						/>
						</FloatingLabel>
					</Form.Group>

					<Form.Group className="mb-3" controlId="coverimage">
						<FloatingLabel controlId="floatingci" label={t(`Cover's relative path (e.g. 'media/cover.png')`)}>
						<Form.Control
							required
							type="text"
							placeholder={t(`Enter your cover's relative path`)}
							defaultValue={coverImageUrl}
							onChange={(e) => {
								setCoverImageUrl(e.target.value);
							}}
						/>
						</FloatingLabel>
					</Form.Group>

					<Form.Control
								filename={file}
								accept=".zip"
								type="file"
								onChange={(e) => setFile(e.target.files[0])}
					/>

					<LykioButton
						variant="contained"
						type="submit"
						className="float-end lykiocss-form-button lykiocss-margin-top-half-em"
					>
						{t('Upload System Unit')}
					</LykioButton>
					</Form>
			</div>
			)}
		</div>
	);
};

export default ZipContentUpload;
