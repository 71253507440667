import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'grapesjs/dist/css/grapes.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

const Index = () => {
  return(
    <App />
  )
}

ReactDOM.render(
  <React.StrictMode>
    <Index />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
