import { useState, useEffect } from 'react';
import axios from 'axios';
import t from "../../Utils/Translation";
import LykioButton from "../common/LykioButton";
import Form from 'react-bootstrap/Form';
import {
	AUTHORIZED_URL_PREFIX,
	RESET_LEADERBOARDS_URL,
	NOTIFICATION_SEVERITY_SUCCESS,
	AxiosConfig
} from '../../Utils/Constants';
import './firstAdminScreen.css';
import Loading from '../common/Loading';
import { useNavigate } from 'react-router';
import { notify, pleaseRefreshError } from '../../Utils/Notificator';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';


const ResetLeaderboards = () => {
	const navigate = useNavigate();
	const [ isLoading, setIsLoading ] = useState(false);

	const resetLeaderboardsHandler = async () => {
		try {
			setIsLoading(true)
			const { data } = await axios.post(
				`${AUTHORIZED_URL_PREFIX}${RESET_LEADERBOARDS_URL}`,
				{reset:true},
				AxiosConfig('multipart/form-data')
			);
			if (data.success === true) {
				notify({
					title: `${t('You have successfully reset your leaderboards.')}`,
					severity: NOTIFICATION_SEVERITY_SUCCESS,
					creationDate: ''
				});
				setIsLoading(false)
				navigate("/")
			}
		} catch (error) {
			console.log(error);
			notify(pleaseRefreshError);
		}
	};

	return (
		<div className="lykioccs-main-content">
			{isLoading ? (
				<Loading />
			) : (
				<div className="lykiocss-center lykiocss-margin-top-2em">
					<Container className="lykiocss-center">
						<Row>
							<Col xs={12} md={12}>
							<div  className="lykiocss-form lykiocss-form-border lykiocss-form-size" style={{paddingBottom:'3rem'}}>
							<div className="lykiocss-center lykiocss-title-light">{t('Reset Leaderboards')}</div>
								<p style={{maxWidth:'40em', marginTop:'0.5em'}}>
									One day after you click on <b>RESET LEADERBOARDS</b> below, the following will happen:
									<li>You will set all users' XP and Impact Index to 0.</li>
									<li>You will reset all Leaderboards and they will start counting from 0.</li>
									Users' stories and comments will remain in the System.
								</p>
									<b>This action cannot be undone.</b>

								<Container style={{marginTop:'1.5em'}}>
									<Row>
										<Col>
											<LykioButton
												variant="contained"
												type="text"
												className="float-end lykiocss-form-button"
												onClick={()=>{navigate("/")}} >
												{t('Do Not Reset')}
											</LykioButton>
										</Col>
										<Col>
											<Button
												type="text"
												className="float-end lykiocss-form-button"
												onClick={()=>{resetLeaderboardsHandler()}} >
												{t('Reset Leaderboards')}
											</Button>
										</Col>
									</Row>
								</Container>
							</div>
							</Col>
						</Row>
					</Container>
				</div>
			)}
		</div>
	);
};

export default ResetLeaderboards;
