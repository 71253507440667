import { useEffect, useState } from 'react';
import axios from 'axios';
import InfoSharpIcon from '@mui/icons-material/InfoSharp';
import { AUTHORIZED_URL_PREFIX, REPORTS_URL, AxiosConfig } from '../../Utils/Constants';
import './reports.css';
import LykioButton from "../common/LykioButton";
import Form from 'react-bootstrap/Form';
import Loading from '../common/Loading';
import { notify, pleaseRefreshError } from '../../Utils/Notificator';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import t from '../../Utils/Translation';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ExportReactCSV from './../common/ExportReactCSV';
import friendlyDate from '../../Utils/FriendlyDate';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {Chart as ChartJS, ArcElement, Tooltip, Legend, Title,BarElement, CategoryScale, LinearScale,} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import { Doughnut } from "react-chartjs-2";
import { Tooltip as MUITooltip } from '@mui/material';

const Reports = () => {
	ChartJS.register(ArcElement, Tooltip, Legend, Title, ChartDataLabels,  CategoryScale, LinearScale, BarElement);
	const [isLoading, setIsLoading] = useState(false);
	const [ usageReporting, setUsageReporting ] = useState(new Map());
	const [ resultsLoaded, setResultsLoaded ] = useState(false);
	const [ groups, setGroups ] = useState([]);
	const [endDate, setEndDate] = useState(new Date());
	const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), 0, 1));
	const [csvFileName, setCsvFileName] = useState('lykio-usage-report')
	const [excelTable, setExcelTable] = useState([]);
	const [storiesCreated, setStoriesCreated] = useState([]);
	const [totalStoriesCreated, setTotalStoriesCreated] = useState(0);
	const [totalPlatformLogins, setTotalPlatformLogins] = useState(0);
	const [totatPlatformUnitViews, setTotatPlatformUnitViews] = useState(0);
	const [totatPlatformStoryViews, setTotatPlatformStoryViews] = useState(0);

	const [doughnutViewsData, setDoughnutViewsData] = useState(undefined);
	const [doughnutStoriesData, setDoughnutStoriesData] = useState(undefined);

	useEffect(()=>{
		const getReports = async() =>{
			try {
				setIsLoading(true);
				const { data } = await axios.post(
					`${AUTHORIZED_URL_PREFIX}${REPORTS_URL}`,
					{ startDate, endDate },
					AxiosConfig()
				);
				if (data.success === true) {
					setUsageReporting(data.usageReporting);
					setStoriesCreated(data.storiesCreated);
					setGroups(data.groups);
					setCsvFileName(`lykio-usage-report_${friendlyDate(startDate)}-${friendlyDate(endDate)}`)
					let excel = [];

					let loginPlatformTotals = 0;
					let storyviewsPlatformTotals = 0;
					let unitviewsPlatformTotals = 0;

					let totalDeptViewsMap = new Map();
					let totalDeptStoriesMap = new Map();

					data.usageReporting.map((usageReportingItem, i) => {
					console.log(usageReportingItem);
					let storyviews = 0;
					let unitviews = 0;

					usageReportingItem.usage.forEach((usage => {
						if(usage.unit && usage.unit!=='undefined' && usage.unit!==undefined){
							if(usage.unit.type.startsWith('Story')){
								storyviews++;
							}else{
								unitviews++;
							}
						}else{
							console.log('type not found');
						}
					}));
	
					let currentUser = usageReportingItem.usage[0].user;
					let groupName;
					if(currentUser.group && Array.isArray(currentUser.group) && currentUser.group.length>0){
						groupName = data.groups.length>0 && data.groups.filter(group => group._id===usageReportingItem.usage[0].user.group[0].toString())[0].name;
					}
					if(currentUser.group && !Array.isArray(currentUser.group)){
						groupName = data.groups.length>0 && data.groups.filter(group => group._id===usageReportingItem.usage[0].user.group)[0].name;
					}
	
					loginPlatformTotals+=usageReportingItem.logins;
					storyviewsPlatformTotals+=storyviews;
					unitviewsPlatformTotals+=unitviews;

					// 
					let dept = usageReportingItem.usage[0].user.department ? `${usageReportingItem.usage[0].user.department}` : 'Unassigned'
					if(totalDeptViewsMap.get(dept)){
						totalDeptViewsMap.set(dept,totalDeptViewsMap.get(dept)+unitviews+storyviews)
					}else{
						totalDeptViewsMap.set(dept,unitviews+storyviews)
					}

					excel.push(
						{
							lykio: i+1,
							name: `${usageReportingItem.usage[0].user.firstname} ${usageReportingItem.usage[0].user.lastname}`,
							email: usageReportingItem.user,
							logins: usageReportingItem.logins,
							totalviews: unitviews+storyviews,
							unitviews: unitviews,
							storyviews: storyviews,
							group: groupName,
							department: usageReportingItem.usage[0].user.department ? `${usageReportingItem.usage[0].user.department}` : 'Unassigned'
						}
					)})

					data.storiesCreated.forEach(entry =>{
						let dept = entry.user.department ? `${entry.user.department}` : 'Unassigned'
						if(totalDeptStoriesMap.get(dept)){
							totalDeptStoriesMap.set(dept,totalDeptStoriesMap.get(dept)+entry.stories)
						}else{
							totalDeptStoriesMap.set(dept,entry.stories)
						}
					})

					// The two maps
					console.log(totalDeptViewsMap);
					console.log(totalDeptStoriesMap);
					let deptViewsData = {
						labels: [],
						datasets: [
						{
							data: [],
							backgroundColor: ["#3e4444", "#82b74b", "#405d27","#c1946a", "#c1336f"],
							hoverBackgroundColor: ["#3e4444", "#82b74b", "#405d27","#c1946a", "#c1336f"],
							borderWidth: 2
						}
						]
					};

					let deptStoriesData = {
						labels: [],
						datasets: [
						{
							data: [],
							backgroundColor: ["#ffef96", "#50394c", "#b2b2b2","#f4e1d2", "#f2e9d2"],
							hoverBackgroundColor: ["#ffef96", "#50394c", "#b2b2b2","#f4e1d2", "#f2e9d2"],
							borderWidth: 2
						}
						]
					};


					totalDeptViewsMap.forEach((value, key) => {
						deptViewsData.labels.push(key);
						deptViewsData.datasets[0].data.push(value);
					});

					totalDeptStoriesMap.forEach((value, key) => {
						deptStoriesData.labels.push(key);
						deptStoriesData.datasets[0].data.push(value);
					});

					setDoughnutViewsData(deptViewsData);
					setDoughnutStoriesData(deptStoriesData);
					
					let calculateTotalStoriesCreated = 0;
					data.storiesCreated.forEach(entry=>{
						calculateTotalStoriesCreated+=entry.stories;
					})
					setTotalStoriesCreated(calculateTotalStoriesCreated);
					setTotalPlatformLogins(loginPlatformTotals);
					setTotatPlatformUnitViews(unitviewsPlatformTotals);
					setTotatPlatformStoryViews(storyviewsPlatformTotals);


					setExcelTable(excel);
					setResultsLoaded(true)
					setIsLoading(false);
				}else {
					notify(pleaseRefreshError);
				}
			}catch(error) {
				console.log(error);
				notify(pleaseRefreshError);
			}
		}
		getReports();
	},[]);


	const getUsagePeriodDataHandler = async() => {
		try {
			setIsLoading(true);
			const { data } = await axios.post(
				`${AUTHORIZED_URL_PREFIX}${REPORTS_URL}`,
				{ startDate, endDate },
				AxiosConfig()
			);
			if (data.success === true) {
				setUsageReporting(data.usageReporting);
				setStoriesCreated(data.storiesCreated);
				setGroups(data.groups);
				setCsvFileName(`lykio-usage-report_${friendlyDate(startDate)}-${friendlyDate(endDate)}`)
				let excel = [];
				data.usageReporting.map((usageReportingItem, i) => {
				console.log(usageReportingItem);
				let storyviews = 0;
				let unitviews = 0;
				usageReportingItem.usage.forEach((usage => {
					if(usage.unit && usage.unit!=='undefined' && usage.unit!==undefined){
						if(usage.unit.type.startsWith('Story')){
							storyviews++;
						}else{
							unitviews++;
						}
					}else{
						console.log('type not found');
					}
				}));

				let currentUser = usageReportingItem.usage[0].user;
				let groupName;
				if(currentUser.group && Array.isArray(currentUser.group) && currentUser.group.length>0){
					groupName = data.groups.length>0 && data.groups.filter(group => group._id===usageReportingItem.usage[0].user.group[0].toString())[0].name;
				}
				if(currentUser.group && !Array.isArray(currentUser.group)){
					groupName = data.groups.length>0 && data.groups.filter(group => group._id===usageReportingItem.usage[0].user.group)[0].name;
				}

				excel.push(
					{
						lykio: i+1,
						name: `${usageReportingItem.usage[0].user.firstname} ${usageReportingItem.usage[0].user.lastname}`,
						email: usageReportingItem.user,
						logins: usageReportingItem.logins,
						totalviews: unitviews+storyviews,
						unitviews: unitviews,
						storyviews: storyviews,
						group: groupName,
						department: usageReportingItem.usage[0].user.department ? `${usageReportingItem.usage[0].user.department}` : 'Unassigned'
					}
				)})
				setExcelTable(excel);
				setResultsLoaded(true)
				setIsLoading(false);
			}else {
				notify(pleaseRefreshError);
			}
		}catch(error) {
			console.log(error);
			notify(pleaseRefreshError);
		}
	}

	return (
		<div className="lykioccs-main-content">
			{isLoading ? (
				<><Loading delay={true}/></>
			) : (
				<div className="lykiocss-center lykiocss-margin-top-2em">
					<Container style={{ backgroundColor: 'white', margin: '1em', padding: '1em', borderRadius: '10px'}}>
					<Row style={{marginBottom:'2em'}}>
						<Col>
						<div style={{borderStyle: 'solid', borderColor: 'lightgray', borderRadius:'8px', borderWidth: 'thin', padding:'1em'}}>
							<h5>{t('Select Usage Period')}</h5>
							<Form.Label style={{padding:'0em'}}>{t('Start Date')}</Form.Label>
							<DatePicker portalId="root-portal" selected={startDate} onChange={(date) => setStartDate(date)} />
							<Form.Label style={{padding:'0em'}}>{t('End Date')}</Form.Label>
							<DatePicker portalId="root-portal" selected={endDate} onChange={(date) => setEndDate(date)} />
							<LykioButton style={{marginTop:'1em'}} onClick={() => getUsagePeriodDataHandler()}>{t('Retrieve Report')}</LykioButton>
						</div>
						</Col>
						<Col>
							<h5 style={{color:'black',paddingTop:'0.5em'}}>{t('Lessons Viewed Per Department')} &nbsp; <MUITooltip title={t("All statistics refer to the selected period")}>
              				<InfoSharpIcon/>
              				</MUITooltip>
							</h5>
							{(doughnutViewsData && doughnutViewsData.labels && doughnutViewsData.labels.length>0) ? <Doughnut data={doughnutViewsData} /> : <p>No data yet</p>}
						</Col>
						<Col>
							<h5 style={{color:'black',paddingTop:'0.5em'}}>{t('Stories Created Per Department')} &nbsp; <MUITooltip title={t("All statistics refer to the selected period")}>
              				<InfoSharpIcon/>
              				</MUITooltip>
							</h5>
							{(doughnutStoriesData && doughnutStoriesData.labels && doughnutStoriesData.labels.length>0)  ? <Doughnut data={doughnutStoriesData} /> : <p>No data yet</p>}
						</Col>
					</Row>
					<Row style={{height:'18em', marginTop:'4em'}}>
					<h5 className="lykiocss-center" style={{color:'black',paddingTop:'0.5em'}}>{t('General Statistics')}&nbsp; <MUITooltip title={t("All statistics refer to the selected period")}>
              				<InfoSharpIcon/>
              				</MUITooltip>
					</h5>
					<Bar
							data={{
								labels: ['Logins','Unit Views','Story Views', 'Stories Created', 'Creators'],
								datasets: [
							{
								label: 'Total',
								backgroundColor: [
									'rgba(27,71,127,1.00)',
								'rgba(255, 99, 132, 0.2)',
								'rgba(54, 162, 235, 0.2)',
								'rgba(255, 206, 86, 0.2)',
								'rgba(75, 192, 192, 0.2)',
								'rgba(150,169,127,1.00)'

								],
								// borderColor: 'rgba(0,0,0,1)',
								data: [
									totalPlatformLogins,
									totatPlatformUnitViews,
									totatPlatformStoryViews,
									totalStoriesCreated,
									storiesCreated.length],
								datalabels: {
								formatter: function(value, context) {
								return value;
							}
							},
							}
								]}}
							options={{
								maintainAspectRatio: false,
								plugins: {
									title:{
										display:true,
										text:'',
										fontSize:20
									},
									legend: {
										display: false
									}
								},



							}}/>
					</Row>
					<Row style={{marginTop:'4em'}}>
					<Col xs={12} md={12}>
						<Form className="lykiocss-form lykiocss-form-size" style={{padding:'0em!important'}}>
						{!resultsLoaded && <div className='lykiocss-title-light lykiocss-center'>{t('Select a period and your results will show up here')}</div>}
						{resultsLoaded &&
						<div className='lykiocss-contrast-text'> 
						<h5 className="lykiocss-center" style={{color:'black',paddingTop:'0.5em'}}>{t('Statistics Per User')}
						&nbsp; <MUITooltip title={t("All statistics refer to the selected period")}>
              				<InfoSharpIcon/>
              			</MUITooltip>
						</h5>
						<ExportReactCSV csvData={excelTable} fileName={csvFileName} buttonText='Export as CSV'/>
						<div className="table-responsive lykiocss-scrollable-table-450 lykiocss-material-table">
						<TableContainer component={Paper}>
						<Table aria-label="simple table" stickyHeader exportButton={true}>
							<TableHead style={{position:'inherit'}}>
							<TableRow>
								<TableCell>#</TableCell>
								<TableCell>{t("Name")}</TableCell>
								<TableCell>{t("Email")}</TableCell>
								<TableCell>{t("Logins")}</TableCell>
								<TableCell>{t("Total Views")}</TableCell>
								<TableCell>{t("Unit Views")}</TableCell>
								<TableCell>{t("Story Views")}</TableCell>
								<TableCell>{t("Group")}</TableCell>
								<TableCell>{t("Department")}</TableCell>
							</TableRow>
							</TableHead>
							<TableBody>
							{usageReporting.map((usageReportingItem, i) => {

								let storyviews = 0;
								let unitviews = 0;
								usageReportingItem.usage.forEach((usage => {
									if(usage.unit && usage.unit!=='undefined' && usage.unit!==undefined){
										if(usage.unit.type.startsWith('Story')){
											storyviews++;
										}else{
											unitviews++;
										}
									}else{
										console.log('type not found');
									}
								}));
								
								let currentUser = usageReportingItem.usage[0].user;
								let groupName;
								if(currentUser.group && Array.isArray(currentUser.group) && currentUser.group.length>0){
									groupName = groups.length>0 && groups.filter(group => group._id===usageReportingItem.usage[0].user.group[0].toString())[0].name;
									
								}
								if(currentUser.group && !Array.isArray(currentUser.group)){
									groupName = groups.length>0 && groups.filter(group => group._id===usageReportingItem.usage[0].user.group)[0].name;
								}

								return <TableRow key={i}>
								<TableCell scope="row">{i + 1}</TableCell>
								<TableCell>{usageReportingItem.usage[0].user.firstname} {usageReportingItem.usage[0].user.lastname}</TableCell>
								<TableCell>{usageReportingItem.user}</TableCell>
								<TableCell>{usageReportingItem.logins}</TableCell>
								<TableCell>{unitviews+storyviews}</TableCell>
								<TableCell>{unitviews}</TableCell>
								<TableCell>{storyviews}</TableCell>
								<TableCell>{groupName}</TableCell>
								<TableCell>{usageReportingItem.usage[0].user.department ? `${usageReportingItem.usage[0].user.department}` : 'Unassigned'} </TableCell>
							</TableRow>})}
							</TableBody>
						</Table>
						</TableContainer>
						</div>
						</div>}
						</Form>
						</Col>
					</Row>
					</Container>
				</div>
			)}
		</div>
	);
};

export default Reports;
