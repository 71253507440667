import './timelineBox.css';
import t from "../../Utils/Translation";
import StoriesUploadBoxEntry from './StoriesUploadBoxEntry';

const StoriesUploadBox = (props)=>{
    const stories = props.stories;
    return <div className="col-xl-4 col-md-6 lykiocss-profile-text-color">
            <div className="card">
                <div className="card-block">
                    <div className="row align-items-center justify-content-center">
                        <div className="col">
                            <div className='lykiocss-center lykiocss-subtitle-light'>{t("Latest uploads")}</div>
                        </div>
                    </div>
                    {(stories && stories.length==0) ? <div>{t("No uploaded stories yet.")}</div> : 
                    <ul className="task-list">
                        {stories && stories.slice(0).reverse().map((story, index) =>{
                                if(index<5){
                                    return <StoriesUploadBoxEntry story={story} />
                                }
                            })
                        }   
                    </ul>
                    }
                </div>
            </div>
        </div>
}

export default StoriesUploadBox;