import React from 'react';
import { useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router'
import {
  PUBLIC_URL_PREFIX,
  UBER_ADMIN_SETTINGS_URL,
  HOME_URL,
  AxiosConfigNoAuth
} from '../../Utils/Constants'

const LykioOpenRoute = ({ children }) => {
  const navigate = useNavigate()

  const changeRootThemeValues = (propertyName, propertyValue) => {
    propertyValue && document.documentElement.style.setProperty(propertyName,propertyValue);
  }

  useEffect(async () => {
    const { data } = await axios.get(
      `${PUBLIC_URL_PREFIX}${UBER_ADMIN_SETTINGS_URL}`,
      AxiosConfigNoAuth()
    );    
    if(data.uberSettings){
      if(data.uberSettings.authScreensLogo){
        changeRootThemeValues('--background-upload-url',`url(${data.uberSettings.authScreensLogo})`);
      }
      if(data.uberSettings.lykioBackground){
        changeRootThemeValues('--background-login-url',`url(${data.uberSettings.lykioBackground})`);
      }
    } 
    const storageAuthTokenValue = localStorage.getItem("authToken");
    if (storageAuthTokenValue) {
      navigate(HOME_URL);
    }
  }, []);

  return <React.Fragment>
		{children}	  
	  </React.Fragment>
}

export default LykioOpenRoute
