import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';
import t from "../../Utils/Translation";
import './categoriesManagement.css';
import LykioButton from "../common/LykioButton";
import Form from 'react-bootstrap/Form';
import {
	AUTHORIZED_URL_PREFIX,
	FRONTEND_LOGIN_URL,
	CATEGORIES_URL,
	CONTENT_MANAGEMENT_CATEGORIES_URL,
	CONTENT_MANAGEMENT_ADD_SUBCATEGORY_URL,
	CONTENT_MANAGEMENT_MODIFY_CATEGORY_URL,
	NOTIFICATION_SEVERITY_SUCCESS,
	AxiosConfig
} from '../../Utils/Constants';
import Loading from '../common/Loading';
import { notify, pleaseRefreshError } from '../../Utils/Notificator';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FloatingLabel from "react-bootstrap/FloatingLabel";

const CategoriesManagement = ({ history }) => {
	const navigate = useNavigate();
	const [ category, setCategory ] = useState('');
	const [ parentCategory , setParentCategory ] = useState('');
	const [ description, setDescription ] = useState('');
	const [ isSubCategory, setIsSubCategory ] = useState(false);
	const [ availableCategories, setAvailableCategories ] = useState([]);
	const [ isLoading, setIsLoading ] = useState(true);
	const [ allCategoriesForEditing, setAllCategoriesForEditing ] = useState([]);

	useEffect(() => {
		const fetchCategoriesData = async () => {
			try {
				const { data } = await axios.get(`${AUTHORIZED_URL_PREFIX}${CATEGORIES_URL}`, AxiosConfig());
				if (data.success) {
					let categories = [];
					allCategoriesForEditing.length<1 && setAllCategoriesForEditing(data.categories);
					data.categories.forEach(category => {
						categories.push(category);
						if(!isSubCategory && category.subCategories && category.subCategories.length > 0){
							category.subCategories.forEach(subCategory => {
								subCategory.name = `${category.name}-${subCategory.name}`;
								categories.push(subCategory);
							})
						}
					});
					console.log('all categories',categories);
					setAvailableCategories(categories);
					setParentCategory(data.categories.length>0 && data.categories[0]._id);
					setIsLoading(false);
				} else {
					notify(pleaseRefreshError);
				}
			} catch (error) {
				console.log(error);
				notify(pleaseRefreshError);
			}
		};
		fetchCategoriesData();
	}, [isSubCategory]);

	const addCategory = async (e) => {
		e.preventDefault();
		try {
			if(isSubCategory){
				const { data } = await axios.post(
					`${AUTHORIZED_URL_PREFIX}${CONTENT_MANAGEMENT_ADD_SUBCATEGORY_URL}`,
					{parentCategoryId: parentCategory, category, description },
					AxiosConfig()
				);
				if (data.success === true) {
					notify({
						title: `${t('Your category was added successfully. You have been redirected to the home page.')}`,
						severity: NOTIFICATION_SEVERITY_SUCCESS,
						creationDate: ''
					});
					navigate("/");
				} else {
					notify(pleaseRefreshError);
				}
			}else{
				const { data } = await axios.post(
					`${AUTHORIZED_URL_PREFIX}${CONTENT_MANAGEMENT_CATEGORIES_URL}`,
					{ category, description },
					AxiosConfig()
				);
				if (data.success === true) {
					notify({
						title: `${t('Your category was added successfully. You have been redirected to the home page.')}`,
						severity: NOTIFICATION_SEVERITY_SUCCESS,
						creationDate: ''
					});
					navigate(FRONTEND_LOGIN_URL);
				} else {
					notify(pleaseRefreshError);
				}
			}
			
		} catch (error) {
			console.log(error);
			notify(pleaseRefreshError);
		}
	};

	const handleParentCategoryChange = (e) => {
		setParentCategory(e.target.value);
	};

	const handleSubCategoryCheckBox = (e) => {
		setIsSubCategory(e.target.checked);
	};

	const modifyCategory = async(categoryId, newStatus) => {
		try {
			const { data } = await axios.post(
				`${AUTHORIZED_URL_PREFIX}${CONTENT_MANAGEMENT_MODIFY_CATEGORY_URL}`,
				{ categoryId, newStatus },
				AxiosConfig()
			);
			if (data.success === true) {
				notify({
					title: `${t('Your category was updated successfully.')}`,
					severity: NOTIFICATION_SEVERITY_SUCCESS,
					creationDate: ''
				});
				window.location.reload();
			} else {
				notify(pleaseRefreshError);
			}
		} catch (error) {
			console.log(error);
			notify(pleaseRefreshError);
		}
	}

	return (
		<div className="lykioccs-main-content">
			{isLoading ? (
				<Loading />
			) : (
				<div className="lykiocss-center lykiocss-margin-top-2em">
					<Container className="lykiocss-center">
						<Row>
							<Col xs={12} md={6}>
							<Form onSubmit={addCategory} style={{paddingBottom: '4rem'}} className="lykiocss-form lykiocss-form-border lykiocss-form-size">
							<div className="lykiocss-center lykiocss-title-light ">{t('Add a new category')}</div>
								<Form.Group className="mb-3 lykiocss-margin-top-1em" controlId="userType" >
									<FloatingLabel controlId="floatingcats" label={isSubCategory ? t('Pick the parent category') : t('Current categories')}>
									<Form.Select onChange={handleParentCategoryChange}>
										{availableCategories.map((category) => (
											<option key={category._id} value={category._id}>
												{category.name}
											</option>
										))}
									</Form.Select>
									</FloatingLabel>
								</Form.Group>
								<Form.Group className="mb-3" controlId="category">
									<FloatingLabel controlId="floatingcats" label={t('Category name')}>
									<Form.Control
										required
										type="text"
										placeholder={t('Enter your category name')}
										defaultValue={category}
										onChange={(e) => {
											setCategory(e.target.value);
										}}
									/>
									</FloatingLabel>
								</Form.Group>
								<Form.Group className="mb-3">
									<FloatingLabel controlId="floatingcats" label={t('Some words to describe your new category')}>
									<Form.Control
										type="text"
										placeholder={t('Enter your description')}
										defaultValue={description}
										onChange={(e) => {
											setDescription(e.target.value);
										}}
									/>
									</FloatingLabel>
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Check type="checkbox" label={t("Add as subcategory")} value={isSubCategory} onChange={handleSubCategoryCheckBox}/>
								</Form.Group>
								<LykioButton variant="contained" type="submit" className="float-end">
									{isSubCategory ? t('Add your subcategory') : t('Add your category')}
								</LykioButton>					
							</Form>
							</Col>
							<Col xs={12} md={6}>	
								<Form onSubmit={addCategory} style={{paddingBottom: '4rem'}} className="lykiocss-form lykiocss-form-border lykiocss-form-size">
									<div className="lykiocss-center lykiocss-title-light ">{t('Activate/Deactivate Root Categories')}</div>
									<br/>
									{allCategoriesForEditing.map((category) => (
										<Form.Group>
										<Form.Check
											onChange={() => {
											alert(`Setting ${category.name} to ${!category.active}`);
											modifyCategory(category.id, !category.active);
											}}
											checked={category.active}
											type="switch"
											id={category.name}
											label={category.name}
										/>
										</Form.Group>
									))}
								</Form>
							</Col>
						</Row>
					</Container>
				</div>
			)}
		</div>
	);
};

export default CategoriesManagement;
