import React, { useState, useEffect, useContext } from 'react';
import { LykioContext } from "../routing/LykioAuthorizedRoute";
import axios from 'axios';
import Modal from 'react-bootstrap/Modal'
import { Link, useParams } from 'react-router-dom';
import {
	AUTHORIZED_URL_PREFIX,
	USER_MANAGEMENT_WITHOUTPARAM_URL,
	USER_MANAGEMENT_ADD_USER_URL,
	USER_MANAGEMENT_ADD_GROUP_URL,
	DEPARTMENTS_URL,
	MASS_USER_UPLOAD_URL,
	NOTIFICATION_SEVERITY_INFO,
	NOTIFICATION_SEVERITY_WARNING,
	AxiosConfig
} from '../../Utils/Constants';
import './userManagement.css';
import t from "../../Utils/Translation";
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import LykioButton from "../common/LykioButton";
import Loading from '../common/Loading';
import { notify, pleaseRefreshError } from '../../Utils/Notificator';
import UsersTable from './UsersTable';
import TextSnippetSharpIcon from '@mui/icons-material/TextSnippetSharp';


const UserManagement = () => {
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const context = useContext(LykioContext);
	const userDetails = context.userDetails;
	const userPermissions = context.userPermissions;
	const tenantProperties = context.tenantProperties;
	const localCanAssignAdmin = localStorage.getItem('localca');
	localCanAssignAdmin===null && (localStorage.setItem('localca', userPermissions ? !userPermissions.includes('teacherusermanagement') : false)) 
	const assignAdminRole = localStorage.getItem('localca')==='true' ? true : false;
	const [ isLoading, setIsLoading ] = useState(true);
	const [file, setFile] = useState();
	const [ users, setUsers ] = useState([]);
	const [ groups, setGroups ] = useState([]);
	const { groupReference } = useParams();
	const [ selectedGroup, setSelectedGroup ] = useState(groupReference);


	const fetchUserManagementData = async () => {
		try {
			setIsLoading(true);
			const { data } = await axios.post(
				`${AUTHORIZED_URL_PREFIX}${USER_MANAGEMENT_WITHOUTPARAM_URL}`,
				{ groupReference },
				AxiosConfig()
			);
			setUsers(data.users);
			setGroups(data.groups);
			setSelectedGroup(data.selectedGroup);
			setIsLoading(false);
			if (!data.success) {
				notify(pleaseRefreshError);
			}
		} catch (error) {
			console.error(error);
			notify(pleaseRefreshError);
		}
	};

	useEffect(
		() => { 
			fetchUserManagementData();
		},
		[ groupReference ]
	);
	if (selectedGroup === undefined) setSelectedGroup(groupReference);

	const massUploadUsers = async()=>{
		if(file){
			try {
			  const formData = new FormData();
			  formData.append('file', file);
			  setIsLoading(true);
			  const { data } = await axios.post(
				`${AUTHORIZED_URL_PREFIX}${MASS_USER_UPLOAD_URL}`,
				formData,
				AxiosConfig('multipart/form-data'),
			  )
			  if(data.success) {
				setFile(null);
				setIsLoading(false);
				handleClose();
				if(data.faultyUsers.length>0){
					let message = 'Some users were not added due to inconsistencies:'
					data.faultyUsers.forEach(user => {
						message+=` ${user} `
					})
					notify({
						title: message,
						severity: NOTIFICATION_SEVERITY_WARNING,
						creationDate: '',
						})
				}else{
					notify({
					title: `${t('Your users were successfully created. You can find them under their corresponding group.')}`,
					severity: NOTIFICATION_SEVERITY_INFO,
					creationDate: '',
					})
				}
			  } else {
				handleClose();
				notify(pleaseRefreshError)
			  }
			}catch (error) {
			  console.log(error)
			  notify(pleaseRefreshError)
			}
			}
			else{
			  notify({
				title: `${t('Please upload a file')}`,
				severity: NOTIFICATION_SEVERITY_INFO,
				creationDate: '',
			  })
			}
	}


	return (
		<div className="lykioccs-main-content">
			{isLoading ? (
				<Loading />
			) : (
				<div>
					<div style={{marginLeft:'3px'}} className='lykiocss-title-light'>{t('User Management')}</div>
					{!userPermissions.includes('teacherusermanagement') &&
					<DropdownButton title={`${t('Select a group')}`} className="lykioccs-usermanagement-btn" style={{marginLeft:'3px'}}>
						{groups.map((group, index) => {
							return (
								<Dropdown.Item
									as={Link}
									key={index}
									to={`${USER_MANAGEMENT_WITHOUTPARAM_URL}/${group.reference}`}
								>
									{group.name}
								</Dropdown.Item>
							);
						})}
					</DropdownButton>}
					&nbsp;&nbsp;
					<Link className="lykiocss-unstyled-link" to={USER_MANAGEMENT_ADD_USER_URL}>
						<LykioButton  variant="contained">{t('Add a new user')}</LykioButton>
					</Link>
					&nbsp;&nbsp;
					{!userPermissions.includes('teacherusermanagement') &&
					<Link className="lykiocss-unstyled-link" to={USER_MANAGEMENT_ADD_GROUP_URL}>
						<LykioButton  variant="contained">{t('Add a new group')}</LykioButton>
					</Link>}
					&nbsp;&nbsp;
					{!userPermissions.includes('teacherusermanagement') &&
					<Link className="lykiocss-unstyled-link" to={DEPARTMENTS_URL}>
						<LykioButton  variant="contained">{t('Add a new department')}</LykioButton>
					</Link>}
					&nbsp;&nbsp;
					{!userPermissions.includes('teacherusermanagement') &&
					<React.Fragment>
						<LykioButton onClick={handleShow}  variant="contained">{t('Import from csv')}</LykioButton>
					</React.Fragment>}
					{users.length > 0 ? (
						<div className="lykiocss-material-table">
							<h5>{selectedGroup===':groupReference' ? tenantProperties.name : selectedGroup} {users && `(${users.length} users)`}</h5>
							<UsersTable users={users} assignAdminRole={assignAdminRole}/>
						</div>
					) : (
						<div className="">
							{t('No entries for')} {selectedGroup}
						</div>
					)}
					<Modal show={show} onHide={handleClose}>
						<Modal.Header closeButton>
						<Modal.Title>{t('Mass Upload')}</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div>
							<Form.Group controlId="formFile" className="mb-3">
								<Form.Label>{t('You can upload upload .xlsx files like the provided sample. If any of the groups or departments you provide does not exist,you will be notified with an error.')}&nbsp;
								<a href="https://lykio-dev-data.s3.eu-central-1.amazonaws.com/assets/mass_upload_template.xlsx"><TextSnippetSharpIcon/>template file</a>
								</Form.Label>
								<Form.Label>
								{t('Newly created users will receive an email to set their password and activate their account. All users will be given the simple learner-user role and you will be able to assign any other role later.')}
								</Form.Label>
								<Form.Control
								filename={file}
								accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
								required
								type="file"
								onChange={(e) => {
									setFile(e.target.files[0]);
								}}
								/>
							</Form.Group>
							</div>
						</Modal.Body>
						<Modal.Footer>
						<LykioButton variant="primary" onClick={()=>{massUploadUsers();}}>
							{t('Upload')}
						</LykioButton>
						</Modal.Footer>
					</Modal>
				</div>
			)}
		</div>
	);
};

export default UserManagement;