import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "./resendActivationScreen.css";
import {
  HOME_URL,
  PUBLIC_URL_PREFIX,
  UBER_ADMIN_SETTINGS_URL,
  AxiosConfigNoAuth,
} from "../../Utils/Constants";
import t from "../../Utils/Translation";
import lykio_logo from "../../multimedia/Default/white_loading.gif";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const DownloadPage = () => {
  const [authScreensLogo, setAuthScreensLogo] = useState();
  const [windowsDownloadLink, setWindowsDownloadLink] = useState("#");
  const [macDownloadLink, setMacDownloadLink] = useState("#");
  const [androidDownloadLink, setAndroidDownloadLink] = useState("#");
  const [iosDownloadLink, setIosDownloadLink] = useState("#");

  useEffect(() => {
    async function fetchUberData() {
      const { data } = await axios.get(
        `${PUBLIC_URL_PREFIX}${UBER_ADMIN_SETTINGS_URL}`,
        AxiosConfigNoAuth()
      );
      data.uberSettings &&
        setAuthScreensLogo(data.uberSettings.authScreensLogo);
      data.uberSettings &&
        setWindowsDownloadLink(data.uberSettings.windowsDownloadLink);
      data.uberSettings &&
        setMacDownloadLink(data.uberSettings.macDownloadLink);
      data.uberSettings &&
        setAndroidDownloadLink(data.uberSettings.androidDownloadLink);
      data.uberSettings &&
        setIosDownloadLink(data.uberSettings.iosDownloadLink);
    }
    fetchUberData();
  }, []);

  return (
    <div className="lykiocss-generic-background lykiocss-forms-container">
      <div className="lykiocss-screen-center">
        <div className="LykioForm lykiocss-white-background lykiocss-form-border lykiocss-form-size">
          <Link to={HOME_URL}>
            <div className="lykiocss-center">
              <img
                className="lykiocss-small-login-logo"
                src={authScreensLogo ? authScreensLogo : lykio_logo}
                alt=""
              />
            </div>
          </Link>
          <h3 className="resendactivation-screen__title">
            {t("Choose your version")}
          </h3>
          {windowsDownloadLink.length > 1 && (
            <Form.Group className="mb-3" controlId="email">
              <Button
                href={windowsDownloadLink}
                download
                variant="primary"
                type="submit"
                className="lykiocss-center"
                size="sm"
              >
                <i class="bi bi-microsoft"></i> {t("Download for Windows")}
              </Button>
            </Form.Group>
          )}
          {macDownloadLink.length > 1 && (
            <Form.Group className="mb-3" controlId="email">
              <Button
                href={macDownloadLink}
                download
                variant="primary"
                type="submit"
                className="lykiocss-center"
                size="sm"
              >
                <i class="bi bi-command"></i> {t("Download for Mac ")}
              </Button>
            </Form.Group>
          )}
          {androidDownloadLink.length > 1 && (
            <Form.Group className="mb-3" controlId="email">
              <Button
                href={androidDownloadLink}
                download
                variant="primary"
                type="submit"
                className="lykiocss-center"
                size="sm"
              >
                <i class="bi bi-phone"></i> {t("Download for Android")}
              </Button>
            </Form.Group>
          )}
          {iosDownloadLink.length > 1 && (
            <Form.Group className="mb-3" controlId="email">
              <Button
                href={iosDownloadLink}
                download
                variant="primary"
                type="submit"
                className="lykiocss-center"
                size="sm"
              >
                <i class="bi bi-apple"></i> {t("Download for iOS")}
              </Button>
            </Form.Group>
          )}
        </div>
      </div>
    </div>
  );
};

export default DownloadPage;
