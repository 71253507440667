import friendlyDate from '../../Utils/FriendlyDate';
import './timelineBox.css';
import t from "../../Utils/Translation";
import {
	UNITOVERVIEW_URL_WITHOUT_PARAM
} from '../../Utils/Constants';
import { Link } from 'react-router-dom';


const FavouriteUnits = (props)=>{
    const stories = props.stories;
    return <div className="col-xl-4 col-md-6 lykiocss-profile-text-color">
            <div className="card">
                <div className="card-block">
                    <div className="row align-items-center justify-content-center">
                        <div className="col">
                            <div className='lykiocss-center lykiocss-subtitle-light'>{t("Latest favourite lessons")}</div>
                        </div>
                    </div>
                    {(stories && stories.length===0) ? <div>{t("No favourite lessons yet.")}</div> : 
                    <ul className="task-list">
                        {stories && stories.slice(0).reverse().map((story, index) =>{
                                if(index<5 && story.type==='System-Lykio'){
                                    return <li>
                                                <i className="task-icon bg-c-green"></i>
                                                <Link to={`${UNITOVERVIEW_URL_WITHOUT_PARAM}${story._id}`}>
                                                <span className="float-right"><b className="lykiocss-uploadBoxEntry-active-text">{story.title}</b></span>
                                                </Link>
                                                <p className="text-muted">{t("on")} {friendlyDate(story.creationDate)}</p>
                                            </li>
                                    
                                    // <StoriesUploadBoxEntry story={story} />
                                }
                            })
                        }   
                    </ul>
                    }
                </div>
            </div>
        </div>
}

export default FavouriteUnits;