import * as React from 'react';
import t from "../../Utils/Translation";
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Tooltip from '@mui/material/Tooltip';
import {Box, Grid, IconButton, Paper, Typography} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';

export default function NotificationItem(props) {
    const notificationId = props.notificationId; 
    const title = props.title;
    const content = props.content;
    const createdOn = props.createdOn;
    const timeSince = props.timeSince;
    const markNotificationAsRead = props.markNotificationAsRead;
    const severity = props.severity;

  const [open, setOpen] = React.useState(false);

  const getSeverityColor = (severity) => {
      switch (severity) {
          case 'success' :
              return '#07bc0c'
          case 'info' :
              return '#3498db'
          case 'warning':
              return  '#f1c40f'
          case 'error' :
              return '#e74c3c'

      }
  }

  const handleClick = () => {
    setOpen(!open);
  };

  return (
      <Paper sx={{p:2, borderLeft:5, borderColor:getSeverityColor(severity) }} elevation={2}   >
          <Grid container >
              <Grid item xs={10}>
                  <Typography variant="h6" gutterBottom component="div">
                      {`${title}`}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                      {content}
                  </Typography>
                  <div className="lykiocss-notification-date">{`${timeSince}`}</div>
              </Grid>
              <Grid item xs={2} container direction={"row"} justifyContent={"flex-end"} alignItems={"center"}>
                  <Tooltip title={t('Mark as read and remove')}>
                      <IconButton>
                          <ClearIcon
                              onClick={() => {
                                  markNotificationAsRead(notificationId);
                                  handleClick();
                              }}/>
                      </IconButton>
                  </Tooltip>
              </Grid>
          </Grid>
          {/*<span className='lykiocss-notification-date float-end'>{createdOn}</span>*/}
      </Paper>
  );
}


