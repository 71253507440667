import { NOTIFICATION_SEVERITY_INFO, NOTIFICATION_SEVERITY_SUCCESS, NOTIFICATION_SEVERITY_WARNING } from './Constants';
import timeSince from './TimeSince';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import t from './Translation';

export const pleaseRefreshError = {
	title: `${t('Error Please refresh')}`,
	severity: NOTIFICATION_SEVERITY_WARNING,
	creationDate: ''
};

export const notify = (notification) => {
	switch (notification.severity) {
		case NOTIFICATION_SEVERITY_SUCCESS:
			toast.success(`${notification.title} ${timeSince(notification.creationDate)}`, {
				position: 'bottom-right',
				autoClose: 10000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
			break;
		case NOTIFICATION_SEVERITY_WARNING:
			toast.warn(`${notification.title} ${timeSince(notification.creationDate)}`, {
				position: 'top-center',
				autoClose: false,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
			break;
		default:
			toast.info(`${notification.title} ${timeSince(notification.creationDate)}`, {
				position: 'bottom-right',
				autoClose: 10000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
	}
};

export default notify;
