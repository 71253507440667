import { useState, useEffect } from 'react';
import axios from 'axios';
import t from "../../Utils/Translation";
import LykioButton from "../common/LykioButton";
import Form from 'react-bootstrap/Form';
import {
	AUTHORIZED_URL_PREFIX,
	AWARD_URL,
	NOTIFICATION_SEVERITY_SUCCESS,
	AxiosConfig
} from '../../Utils/Constants';
import './firstAdminScreen.css';
import { useNavigate } from 'react-router';
import { notify, pleaseRefreshError } from '../../Utils/Notificator';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FloatingLabel from "react-bootstrap/FloatingLabel";
import ProfileAwards from './../profile/ProfileAwards';
import InfoSharpIcon from '@mui/icons-material/InfoSharp';
import Tooltip from '@mui/material/Tooltip';

const AddAward = () => {
	const navigate = useNavigate();
	const [ file, setFile ] = useState();
	const [ title, setTitle ] = useState();
	const [ tagline, setTagline ] = useState();
	const [ goal, setGoal ] = useState(1);
	const [ type, setType ] = useState('Story');
	const [ notification, setNotification ] = useState();
	const [ awards, setAwards] = useState([]);
	const types = [
		{name:'Upload X number of Stories', value: 'Story'},
		{name:'View X number of Lessons', value: 'System'},
		{name:'Get 5-star rating for X number of your stories', value: 'Rating'},
		{name:'Reach Impact Index of', value: 'Impact'}
	]

	useEffect(async() => {
		const { data } = await axios.get(
			`${AUTHORIZED_URL_PREFIX}${AWARD_URL}`,
			AxiosConfig()
		);
		setAwards(data.awards ? data.awards : [])
	  }, []);

	const addAwardHandler = async (e) => {
		e.preventDefault();
		try {
			const formData = new FormData();
			formData.append('file', file);
			formData.append('title', title);
			formData.append('tagline', tagline);
			formData.append('goal', goal);
			formData.append('type', type);
			formData.append('notification', notification);

			console.log(formData);
			const { data } = await axios.post(
				`${AUTHORIZED_URL_PREFIX}${AWARD_URL}`,
				formData,
				AxiosConfig('multipart/form-data')
			);

			if (data.success === true) {
				notify({
					title: `${t('You have successfully added your new award.')}`,
					severity: NOTIFICATION_SEVERITY_SUCCESS,
					creationDate: ''
				});
				window.location.reload();
			}
		} catch (error) {
			console.log(error);
			notify(pleaseRefreshError);
		}
	};

	return (
		<div className="lykioccs-main-content">
			<div className="lykiocss-center lykiocss-margin-top-2em">
				<Container>
					<Row>
						<Col>
						<Form onSubmit={addAwardHandler} className="lykiocss-form lykiocss-form-border lykiocss-form-size" style={{paddingBottom:'3rem'}}>
						<div className="lykiocss-center lykiocss-title-light">{t('Add a new award')}</div>
						<Tooltip title={t('You can view and enable the default set of awards from the Customize Lykio screen')}>
						<div style={{marginBottom:'0.5em'}} className="lykiocss-center"><InfoSharpIcon/> {t(`Learn about the default awards`)}</div>
						</Tooltip>
							<Form.Group className="mb-3" controlId="title">
							<FloatingLabel controlId="floatingtitle" label={t(`The award's title (e.g. Ambassador)`)}>
							<Form.Control
								type="text"
								required
								onChange={(e) => {
								setTitle(e.target.value);
								}}
							/>
							</FloatingLabel>
							</Form.Group>

							<Form.Group className="mb-3" controlId="tagline">
							<FloatingLabel controlId="floatingtag" label={t(`The award's tagline (e.g. Achieve Impact Index of 50)`)}>
							<Form.Control
								type="text"
								required
								onChange={(e) => {
								setTagline(e.target.value);
								}}
							/>
							</FloatingLabel>
							</Form.Group>

							<Form.Group className="mb-3" controlId="notification">
							<FloatingLabel controlId="floatinginitiative" label={t(`The notification message the user will get`)}>
							<Form.Control
								type="text"
								required
								onChange={(e) => {
								setNotification(e.target.value);
								}}
							/>
							</FloatingLabel>
							</Form.Group>

							<Form.Group className="mb-3" controlId="group">
							<FloatingLabel controlId="floatingtheme" label={t(`Type (what will the user have to do)`)}>
							<Form.Select onChange={(e)=> setType(e.target.value)}>
								{types.map((availabletype) => (
								<option
									selected = {availabletype.value===type}
									value={availabletype.value}
								>
									{availabletype.name}
								</option>
								))}
							</Form.Select>
							</FloatingLabel>
							</Form.Group>

							<Form.Group className="mb-3" controlId="goal">
							<FloatingLabel controlId="floatinggoal" label={t(`How many items (e.g. view X amount of lessons)`)}>
							<Form.Control
								type="number"
								required
								onChange={(e) => {
								setGoal(e.target.value);
								}}
							/>
							</FloatingLabel>
							</Form.Group>

							<Form.Group controlId="formFile" className="mb-3">
							<Form.Control
								filename={file}
								accept="file/*"
								type="file"
								onChange={(e) => setFile(e.target.files[0])}
							/>
							</Form.Group>

							<LykioButton
								variant="contained"
								type="text"
								className="float-end lykiocss-form-button">
								{t('Add Award')}
							</LykioButton>
						</Form>
						</Col>
						<Col>
						<Form className="lykiocss-form lykiocss-form-border lykiocss-form-size">
						<div className="lykiocss-center lykiocss-title-light">{t(`All Awards`)}</div>
						<ProfileAwards awards={awards} />
						</Form>
						</Col>
					</Row>
				</Container>

				
			</div>
		</div>
	);
};

export default AddAward;
