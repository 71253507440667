import { useState, useEffect, useContext } from 'react';
import { LykioContext } from "../routing/LykioAuthorizedRoute";
import axios from 'axios';
import { useNavigate } from 'react-router';
import './addUser.css';
import t from "../../Utils/Translation";
import LykioButton from "../common/LykioButton";
import Form from 'react-bootstrap/Form';
import {
	AUTHORIZED_URL_PREFIX,
	GROUPS_URL,
	USER_MANAGEMENT_ADD_USER_URL,
	USER_MANAGEMENT_URL,
	NOTIFICATION_SEVERITY_SUCCESS,
	NOTIFICATION_SEVERITY_WARNING,
	AxiosConfig
} from '../../Utils/Constants';
import Loading from '../common/Loading';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { notify, pleaseRefreshError } from '../../Utils/Notificator';
import FloatingLabel from "react-bootstrap/FloatingLabel";

const AddUser = () => {
	const context = useContext(LykioContext);
	const userDetails = context.userDetails;
	const userPermissions = context.userPermissions;
	const navigate = useNavigate();
	const [ group, setGroup ] = useState('');
	const [ userType, setUserType ] = useState('ROLE_USER');
	const [ email, setEmail ] = useState('');
	const [ availableGroups, setAvailableGroups ] = useState([]);
	const [ availableDepartments, setAvailableDepartments ]= useState([]);
	const [ department, setDepartment] = useState();
	const [ isLoading, setIsLoading ] = useState(true);

	useEffect(() => {
		const fetchGroupsData = async () => {
			try {
				const { data } = await axios.get(`${AUTHORIZED_URL_PREFIX}${GROUPS_URL}`, AxiosConfig());
				if (data.success) {
					console.log(userPermissions);
					userPermissions.includes('teacherusermanagement') ? setAvailableGroups(data.groups.filter(group => group._id===userDetails.group._id)) : setAvailableGroups(data.groups);
					let departmentsForAutoComplete = [{item: {}, label: `none`}];
					data.departments.forEach((dept) => {
						departmentsForAutoComplete.push({item: dept, label: `${dept.name}`});
					})
					
					setAvailableDepartments(departmentsForAutoComplete);

					data.departments.length===0 && setDepartment(undefined);
					setGroup(data.groups[0]._id);
					setIsLoading(false);
				} else {
					notify(pleaseRefreshError);
				}
			} catch (error) {
				console.error(error);
				notify(pleaseRefreshError);
			}
		};
		fetchGroupsData();
	}, []);

	const addNewUser = async (e) => {
		e.preventDefault();
		try {
			group === '' && setGroup(availableGroups[0]._id);
			const { data } = await axios.post(
				`${AUTHORIZED_URL_PREFIX}${USER_MANAGEMENT_ADD_USER_URL}`,
				{email, userType, group, department},
				AxiosConfig()
			);
			if (data.success === true) {
				notify({
					title: `${t('Your user was added successfully.')}`,
					severity: NOTIFICATION_SEVERITY_SUCCESS,
					creationDate: ''
				});
				navigate(USER_MANAGEMENT_URL);
			} else {
				if(data.message){
					notify({
						title: data.message,
						severity: NOTIFICATION_SEVERITY_WARNING,
						creationDate: ''
					});
				}else{
					notify(pleaseRefreshError);
				}
			}
		}catch(error) {
			console.log(error);
			notify(pleaseRefreshError);
		}
	};

	const handleGroupChange = (e) => {
		setGroup(e.target.value);
	};

	const handleUserTypeChange = (e) => {
		setUserType(e.target.value);
	};

	return (
		<div className="lykioccs-main-content">
			{isLoading ? (
				<Loading />
			) : (
				<div className="lykiocss-center lykiocss-margin-top-2em">
					<Form onSubmit={addNewUser} className="lykiocss-form lykiocss-form-border lykiocss-form-size">
						<div className="lykiocss-center lykiocss-title-light">{t('Add a new user')}</div>
						<Form.Group className="mb-3 lykiocss-margin-top-1em" controlId="email">
							<FloatingLabel controlId="floatingemail" label={t('Email')}>
							<Form.Control required type="email" placeholder="name@example.com" 
							defaultValue={email}
							onChange={(e) => {
								setEmail(e.target.value);
							}}
							/>
							</FloatingLabel>
						</Form.Group>

						<Form.Group className="mb-3" controlId="group">
							<FloatingLabel controlId="floatinggroup" label={t('Choose group')}>
							<Form.Select onChange={handleGroupChange}>
								{availableGroups.map((group) => (
										<option key={group._id} value={group._id}>
											{group.name}
										</option>
								))}
							</Form.Select>
							</FloatingLabel>
						</Form.Group>

						<Form.Group>
						<Autocomplete
		 				disablePortal
		 				id="combo-box-searchTerms"
		 				options={availableDepartments}
		 				onChange={(event, value) => {
		 					setDepartment(value ? value.item.name : undefined)
		 				}	
		 				}
		 				renderInput={(params) => (
		 					<TextField {...params} label={t('Assign user to department')} />
		 				)}
		 				/>
						</Form.Group>

						<Form.Group className="mb-3 lykiocss-margin-top-1em" controlId="group">
							<FloatingLabel controlId="floatinguser" label={t('User type')}>
							<Form.Select onChange={handleUserTypeChange}>
								<option value="ROLE_USER">{t('Learner')}</option>
								<option value="ROLE_TEACHER">{t('Educator')}</option>
								<option value="ROLE_MANAGER">{t('Manager')}</option>
								{!userPermissions.includes('teacherusermanagement') && <option value="ROLE_ADMIN">{t('Administrator')}</option>}
							</Form.Select>
							</FloatingLabel>
						</Form.Group>

						<LykioButton variant="contained" type="submit"  className="float-end">
								{t('Save')}
						</LykioButton>
					</Form>
				</div>
			)}
		</div>
	);
};

export default AddUser;
