import { useState, useEffect, useContext } from 'react';
import { LykioContext } from "../routing/LykioAuthorizedRoute";
import axios from 'axios';
import t from '../../Utils/Translation';
import {
	AUTHORIZED_URL_PREFIX,
	STORIES_FOLLOWING_URL,
	AxiosConfig
} from '../../Utils/Constants';
import Story from './Story';
import GridLoading from './GridLoading';
import { notify, pleaseRefreshError } from '../../Utils/Notificator';
import './stories.css';

const StoriesFollowing = () => {
	const [ storiesFollowing, setStoriesFollowing ] = useState([]);
	const [ isLoading, setIsLoading ] = useState(true);
	const context = useContext(LykioContext);
	const tenantProperties = context.tenantProperties;
	const makeHeadersDark = tenantProperties && tenantProperties.makeHeadersDark;


	useEffect(() => {
		const fetchStoriesFollowing = async () => {
			try {
				const { data } = await axios.get(`${AUTHORIZED_URL_PREFIX}${STORIES_FOLLOWING_URL}`, AxiosConfig());
				setStoriesFollowing(data.allFollowingStories);
				setIsLoading(false);
				if (!data.success) {
					notify(pleaseRefreshError);
				}
			} catch (error) {
				console.log(error);
				notify(pleaseRefreshError);
			}
		};
		fetchStoriesFollowing();
	}, []);

	return (
		<div className="lykioccs-main-content lykiocss-stories">
		{isLoading ? (
			<GridLoading t={t} storiesName={t(`My Feed`)} makeHeadersDark={makeHeadersDark}/>
		) : (
			<div>
				<h1 className={`lykiocss-page-title lykiocss-center ${makeHeadersDark && 'lykiocss-dark-color'}`}>{t(`My Feed`)}</h1>
					<div className="lykiocss-stories-container">
						{storiesFollowing.length < 1 ? (
							<div className="lykiocss-center lykiocss-subtitle-light">{t('No stories yet from people you follow!')}</div>
						) : (
							<div className="lykiocss-stories-container">
							<div className="lykiocss-stories-gallery">
								{storiesFollowing.map((story, index) => {
									return <Story story={story} />;
								})}
								
							</div>
							</div>
						)}
					</div>
			</div>
		)}
	</div>
	);
};

export default StoriesFollowing;
