import './Units.css';
import t from '../../Utils/Translation';
import UnitSplider from './../common/UnitSplider';
import Story from '../stories/Story';
import { LIBRARY_PROGRAMS, LIBRARY_UNITS } from '../../Utils/Constants';


const Units = (props) => {
	const title = props.title;
	let units = props.units;
	const hideTitle = props.hideTitle;
	const makeHeadersDark = props.makeHeadersDark;
	const libraryType = props.libraryType;
	const teacherbookActivated = props.teacherbookActivated;
	const studentbookActivated = props.studentbookActivated;
	const workbookActivated = props.workbookActivated;

	// this is the educational TT stuff
	if(props.libraryType && props.libraryType===LIBRARY_PROGRAMS){
		return <div className="lykiocss-stories-container">
			<div className="lykiocss-stories-container">
			<div className="lykiocss-stories-gallery">
				{units.map((unit, index) => {
					return <Story story={unit} hideStatistics={true} hideOverviewPage={true}/>;
				})}
				
			</div>
			</div>
		</div>
	}else if(props.libraryType && props.libraryType===LIBRARY_UNITS){ // this is the normal units
		return (
			<div className={`lykiocss-units-div`}>
			{!hideTitle &&  
				<h1 className={`lykiocss-slider-title ${makeHeadersDark && 'lykiocss-dark-color'}`}>{t(title)}</h1>
			}
			{units.length < 1 ? (
			<div className={`no-content ${makeHeadersDark && 'lykiocss-dark-color'}`} style={{ margin: '0 auto', textAlign: 'center' }}>{t('No content yet!')}</div>
			) : <UnitSplider libraryType={LIBRARY_UNITS} units={units} hideStatistics={false} hideOverviewPage={false}/>
			}
			</div>
		);
	}
	else{
	return (
			<div className={`lykiocss-units-div`}>
			{!hideTitle &&  
				<h1 className={`lykiocss-slider-title ${makeHeadersDark && 'lykiocss-dark-color'}`}>{t(title)}</h1>
			}
			{units.length < 1 ? (
			<div className={`no-content ${makeHeadersDark && 'lykiocss-dark-color'}`} style={{ margin: '0 auto', textAlign: 'center' }}>{t('No content yet!')}</div>
			) : <UnitSplider libraryType={libraryType} units={units} hideStatistics={true} hideOverviewPage={true}/>
			}
			</div>
	);
	}
};

export default Units;
