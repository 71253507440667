import { useState, useEffect, useContext } from "react";
import React from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  AUTHORIZED_URL_PREFIX,
  PROFILE_URL_WITHOUT_PARAM,
  AxiosConfig,
} from "../../Utils/Constants";
import "./profile.css";
import Loading from "../common/Loading";
import { notify, pleaseRefreshError } from "../../Utils/Notificator";
import { LykioContext } from "../routing/LykioAuthorizedRoute";
import TimelineBox from "./TimelineBox";
import StoriesUploadBox from "./StoriesUploadBox";
import ProfileAwards from "./ProfileAwards";
import ProfileStats from "./ProfileStats";
import FavouriteUnits from "./FavouriteUnits";
import ActiveProfileImage from "../common/ActiveProfileImage";

const Progress = () => {
  const [isLoading, setIsLoading] = useState(true);
  const context = useContext(LykioContext);
  const contextUser = context.userDetails;
  const { userId } = useParams();
  console.log("userId", userId);
  const [user, setUser] = useState("");
  const [stories, setStories] = useState([]);
  const [favourites, setFavourites] = useState([]);
  const [userHistoryItems, setUserHistoryItems] = useState([]);

  useEffect(() => {
    const fetchProfileData = async () => {
      console.log("useEffect runs");
      try {
        const { data } = await axios.post(
          `${AUTHORIZED_URL_PREFIX}${PROFILE_URL_WITHOUT_PARAM}`,
          { userId },
          AxiosConfig()
        );
        setUser(data.user);
        setStories(data.stories);
        setFavourites(data.favourites);
        setUserHistoryItems(data.userHistoryItems);
        setIsLoading(false);
        if (!data.success) {
          notify(pleaseRefreshError);
        }
      } catch (error) {
        console.log(error);
        notify(pleaseRefreshError);
      }
    };
    fetchProfileData();
  }, [userId]);

  return (
    <div className="lykioccs-main-content">
      {isLoading ? (
        <Loading />
      ) : (
        <React.Fragment>
          <section>
            <div className="container py-5">
              <div className="row">
                <div className="col-lg-4">
                  <ActiveProfileImage user={user} showName={true} />
                  <div className="card mb-4 mb-lg-0">
                    <ProfileStats user={user} />
                  </div>
                </div>

                <div className="col-lg-8">
                  <ProfileAwards user={contextUser} />

                  <div className="row">
                    <TimelineBox userHistoryItems={userHistoryItems} />
                    <StoriesUploadBox stories={stories} />
                    <FavouriteUnits stories={favourites} />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </React.Fragment>
      )}
    </div>
  );
};

export default Progress;
