import React, {useState} from 'react';
import t from "../../Utils/Translation";
import './storiesUploadBoxEntry.css';
import friendlyDate from '../../Utils/FriendlyDate';
import UnitModal from './../common/UnitModal';

const StoriesUploadBoxEntry = (props) => {
    const story = props.story;
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <React.Fragment>
        <li className='lykiocss-profile-text-color'>
            <i className="task-icon bg-c-green"></i>
            <span className="float-right" onClick={handleShow}>{t("uploaded")} <b className="lykiocss-uploadBoxEntry-active-text">{story.title}</b></span>
            <p className="text-muted">{t("on")} {friendlyDate(story.creationDate)}</p>
        </li>
        <UnitModal unit={story} show={show} handleClose={handleClose} />
        </React.Fragment>
    )
}

export default StoriesUploadBoxEntry
