import { useState, useEffect } from 'react';
import axios from 'axios';
import LykioButton from "../common/LykioButton";
import Form from 'react-bootstrap/Form';
import {
	AUTHORIZED_URL_PREFIX,
	GROUPS_URL,
	DEPARTMENTS_URL,
	USER_MANAGEMENT_URL,
	NOTIFICATION_SEVERITY_SUCCESS,
	NOTIFICATION_SEVERITY_WARNING,
	AxiosConfig
} from '../../Utils/Constants';
import './addGroup.css';
import t from "../../Utils/Translation";
import Loading from '../common/Loading';
import { useNavigate } from 'react-router';
import { notify, pleaseRefreshError } from '../../Utils/Notificator';
import FloatingLabel from "react-bootstrap/FloatingLabel";

const AddDepartment = () => {
	const navigate = useNavigate();
	const [ department, setDepartment ] = useState('');
	const [ availableDepartments, setAvailableDepartments ] = useState([]);
	const [ isLoading, setIsLoading ] = useState(true);

	useEffect(() => {
		const fetchDepartmentsData = async () => {
			try {
				const { data } = await axios.get(`${AUTHORIZED_URL_PREFIX}${GROUPS_URL}`, AxiosConfig());
				if (data.success) {
					setAvailableDepartments(data.departments);
					setIsLoading(false);
				} else {
					notify(pleaseRefreshError);
				}
			} catch (error) {
				console.error(error);
				notify(pleaseRefreshError);
			}
		};

		fetchDepartmentsData();
	}, []);

	const addNewDepartment = async (e) => {
		e.preventDefault();
		try {
			const { data } = await axios.post(
				`${AUTHORIZED_URL_PREFIX}${DEPARTMENTS_URL}`,
				{ department },
				AxiosConfig()
			);
			if (data.success === true) {
				notify({
					title: `${t('Your department was added successfully.')}`,
					severity: NOTIFICATION_SEVERITY_SUCCESS,
					creationDate: ''
				});
				navigate(USER_MANAGEMENT_URL);
			}else{
				notify(pleaseRefreshError);
			}
		} catch (error) {
			console.log(error);
			notify(pleaseRefreshError);
		}
	};

	return <div className="lykioccs-main-content">
		{isLoading ? <Loading /> : <div className="lykiocss-center lykiocss-margin-top-2em">
		<Form onSubmit={addNewDepartment} className="lykiocss-form lykiocss-form-border lykiocss-form-size">
			<div className="lykiocss-center lykiocss-title-light">{t('Add a new department')}</div>
			<Form.Group className="mb-3 lykiocss-margin-top-1em" controlId="userType">
				<FloatingLabel controlId="floatingdepts" label={t('Current departments')}>
				<Form.Select>
					<option>
						{t("Available departments")}
					</option>
					{availableDepartments.map((department, index) => (
						<option key={index} value={department.name}>
							{department.name}
						</option>
					))}
				</Form.Select>
				</FloatingLabel>
			</Form.Group>

			<Form.Group className="mb-3" controlId="group">
				<FloatingLabel controlId="floatingdept" label={t('New Department Name')}>
				<Form.Control
					required
					type="text"
					placeholder={t('Enter name')}
					onChange={(e) => {
						setDepartment(e.target.value);
					}}
				/>
				</FloatingLabel>
			</Form.Group>

			<LykioButton
				variant="contained"
				type="submit"
				
				className="float-end"
			>
				{t('Save')}
			</LykioButton>
		</Form>
		</div>}
		</div>
};

export default AddDepartment;
