import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import t from "../../Utils/Translation";

import LykioButton from "../common/LykioButton";
import { useNavigate } from "react-router-dom";

function EmptyCard(props) {
  const maxWidth = props.maxWidth;
  const title = props.title;
  const destination = props.destination;
  const destinationTitle = props.destinationTitle;
  const navigate = useNavigate();

  return (
    <Card>
      <CardContent>
        {title && (
          <div className="lykiocss-card-title">
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              {title}
            </Typography>
          </div>
        )}
        <div className="lykiocss-card-content">
          <Typography variant="body2">{props.children}</Typography>
        </div>
      </CardContent>
      {destination && (
        <CardActions>
          <LykioButton variant="text" onClick={() => navigate(destination)}>
             {t("Go to")} {destinationTitle}
          </LykioButton>
        </CardActions>
      )}
    </Card>
  );
}

export default EmptyCard;
