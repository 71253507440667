import t from './Translation';

function timeSince(date) {
	if (typeof date === 'string' && date === '') {
		console.log('no date was given');
		return '';
	}
	if (typeof date === 'string') {
		date = new Date(date);
	}
	let seconds = Math.floor((new Date() - date) / 1000);
	let interval = seconds / 31536000;
	if (interval > 1) {
		return Math.floor(interval) === 1
			? Math.floor(interval) + ' ' + t('year ago')
			: Math.floor(interval) + ' ' + t('years ago');
	}
	interval = seconds / 2592000;
	if (interval > 1) {
		return Math.floor(interval) === 1
			? Math.floor(interval) + ' ' + t('month ago')
			: Math.floor(interval) + ' ' + t('months ago');
	}
	interval = seconds / 86400;
	if (interval > 1) {
		return Math.floor(interval) === 1
			? Math.floor(interval) + ' ' + t('day ago')
			: Math.floor(interval) + ' ' + t('days ago');
	}
	interval = seconds / 3600;
	if (interval > 1) {
		return Math.floor(interval) === 1
			? Math.floor(interval) + ' ' + t('hour ago')
			: Math.floor(interval) + ' ' + t('hours ago');
	}
	interval = seconds / 60;
	if (interval > 1) {
		return Math.floor(interval) === 1
			? Math.floor(interval) + ' ' + t('minute ago')
			: Math.floor(interval) + ' ' + t('minutes ago');
	}
	return t('moments ago');
}

export default timeSince;
