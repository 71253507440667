import React from 'react'
import {STRIPE_PUBLISHABLE_KEY} from '../../Utils/Constants';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import PaymentForm from './PaymentForm';

function StripeContainer() {
    const stripeTestPromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
    return (<div className="lykioccs-main-content">
        <Elements stripe={stripeTestPromise}>
            <PaymentForm/>
        </Elements>
        </div>
    )
}

export default StripeContainer;
