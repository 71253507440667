import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import t from '../../Utils/Translation'
import './starRating.css';
import {
	AUTHORIZED_URL_PREFIX,
	ADD_RATING_URL,
	GET_RATINGS_URL,
	AxiosConfig
} from '../../Utils/Constants';
import { LykioContext } from '../routing/LykioAuthorizedRoute';
import { notify, pleaseRefreshError } from '../../Utils/Notificator';
import { Tooltip } from '@mui/material';

const StarRating = (props) => {
	const context = useContext(LykioContext);
	const user = context.userDetails;
	const { unitId } = useParams();
	const rating = 0;
	const [ averageUnitRating, setAverageUnitRating ] = useState();
	const [ isUnitRated, setIsUnitRated ] = useState(true);
	const [ isUnitAlreadyRated, setIsUnitAlreadyRated ] = useState(true);
	const [ isLoading, setIsLoading ] = useState(true);
	let storageUserId;

	useEffect(
		() => {
			if (!user) {
				storageUserId = JSON.parse(localStorage.getItem('userId'));
			} else {
				localStorage.setItem('userId', JSON.stringify(user.id));
				storageUserId = user.id;
			}
			const fetchRatings = async () => {
				try {
					let storyId;
					unitId ? (storyId = unitId) : (storyId = props.rating._id); // this is needed for the commments comp to work for both units and stories
					const { data } = await axios.post(
						`${AUTHORIZED_URL_PREFIX}${GET_RATINGS_URL}`,
						{ unitId: storyId, userId: storageUserId },
						AxiosConfig()
					);

					if (data.success) {
						setAverageUnitRating(parseInt(data.averageRating));
						setIsUnitRated(data.isUnitRated);
						if (data.averageRating === 0) {
							setIsUnitAlreadyRated(false);
						}
						setIsLoading(false);
					} else {
						notify(pleaseRefreshError);
					}
				} catch (error) {
					console.log(error);
					notify(pleaseRefreshError);
				}
			};
			fetchRatings();
		},
		[ isUnitRated ]
	);

	const handleUnitRating = async (rating) => {
		try {
			let storyId;
			unitId ? (storyId = unitId) : (storyId = props.rating._id); // this is needed for the commments comp to work for both units and stories
			const { data } = await axios.post(
				`${AUTHORIZED_URL_PREFIX}${ADD_RATING_URL}`,
				{ unitId: storyId, rating },
				AxiosConfig()
			);
			if (data.success === true) {
				setIsUnitRated(true);
			} else {
				notify(pleaseRefreshError);
			}
		} catch (error) {
			console.log(error);
			notify(pleaseRefreshError);
		}
	};

	return !isUnitRated ? (
		<div className="lykiocss-starrating-container">
			{[ ...Array(5) ].map((star, index) => {
				index += 1;
				return (
					<Tooltip title={t('Rate')}>
					<div
						key={index}
						className={rating === 0 ? 'lykiocss-starrating-button' : 'lykiocss-starrating-buttonoff'}
						onClick={() => {
							rating === 0 && handleUnitRating(index);
						}}
					>
						{index <= rating || (isUnitAlreadyRated && averageUnitRating >= index) ? (
							<span>&#9733;</span>
						) : (
							<span>&#9734;</span>
						)}
					</div>
					</Tooltip>
				);
			})}
		</div>
	) : (
		<Tooltip title={t('Already rated')}>
		<span>
			{[ ...Array(5) ].map((star, index) => {
				return parseInt(averageUnitRating) > index ? (
					<span key={index}>&#9733;</span>
				) : (
					<span key={index}>&#9734;</span>
				);
			})}
		</span>
		</Tooltip>
	);
};

export default StarRating;
