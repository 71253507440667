import React, { useState, useEffect, useContext } from 'react';
import { LykioContext } from "../routing/LykioAuthorizedRoute";
import axios from 'axios'
import {
  AUTHORIZED_URL_PREFIX,
  CREATE_URL,
  AxiosConfig,
  NOTIFICATION_SEVERITY_INFO,
  NOTIFICATION_SEVERITY_SUCCESS,
  NOTIFICATIONS_URL,
  DELETE_NOTIFICATION_BY_ID,
  NOTIFICATION_STORY_UPLOAD,
  GET_ACTIVE_TENANT_USERS
} from '../../Utils/Constants'
import './create.css'
import t from "../../Utils/Translation";
import LykioButton from "../common/LykioButton";
import Form from 'react-bootstrap/Form';
import { notify, pleaseRefreshError } from '../../Utils/Notificator'
import Loading from '../common/Loading'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { createReactEditorJS } from 'react-editor-js'
import { EDITOR_JS_TOOLS } from './EditorJSTools'
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Ratio from 'react-bootstrap/Ratio';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import RefreshIcon from '@mui/icons-material/Refresh';

const ReactEditorJS = createReactEditorJS()
const editorjsHTML = require('editorjs-html')

const Create = () => {
  const context = useContext(LykioContext);
  const tenantProperties = context.tenantProperties;

  const [title, setTitle] = useState('');
  const [file, setFile] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [tenantUsers, setTenantUsers] = useState([]);
  const [uploaderId, setUploaderId] = useState(undefined);

  const [image, setImage] = useState(null)
  const [video, setVideo] = useState(null)

  const onImageChange = (event) => {
   if (event.target.files && event.target.files[0]) {
     const file = event.target.files[0];
     if(file.type.startsWith("image")){
      setImage(URL.createObjectURL(event.target.files[0]));
     }
     else{
      setVideo(URL.createObjectURL(event.target.files[0]));
     }
   }
  }


  useEffect(() => {
    const getUsers = async()=>{
      const { data } = await axios.get(
        `${AUTHORIZED_URL_PREFIX}${GET_ACTIVE_TENANT_USERS}`,
        AxiosConfig(),
      )
      if(data.success){
        setTenantUsers(data.tenantUsers);
      }
    }
    getUsers();
  },[])

  useEffect(() => {
    const interval = setInterval(() => {
      const fetchNotifications = async () => {
        try {
          const { data } = await axios.get(
            `${AUTHORIZED_URL_PREFIX}${NOTIFICATIONS_URL}`,
            AxiosConfig(),
          )
          let uploadsNotifications = data.notifications.filter(
            (notification) => notification.type === NOTIFICATION_STORY_UPLOAD,
          )
          if (uploadsNotifications.length > 0) {
            setIsLoading(false)
            notify({
              title: `${t('Your story was uploaded and is now under review')}`,
              severity: NOTIFICATION_SEVERITY_SUCCESS,
              creationDate: '',
            })
            await axios.post(
              `${AUTHORIZED_URL_PREFIX}${DELETE_NOTIFICATION_BY_ID}`,
              { notificationId: uploadsNotifications[0]._id },
              AxiosConfig(),
            )
          }
        } catch (error) {
          console.log(error);
        }
      }

      fetchNotifications()
    }, 3000)
    return () => clearInterval(interval)
  }, [])

  const initEditorJSData = null
  const [editorJSDataHTML, setEditorJSDataHTML] = useState('')

  // EDITOR JS INIT & SETUP
  const editorJSInstance = React.useRef(null)

  // Initialize the HTML parser
  const edjsParser = editorjsHTML()

  const handleEditorJsInitialize = React.useCallback((instance) => {
    editorJSInstance.current = instance
  }, [])

  const handleEditorJsSave = React.useCallback(async () => {
    const savedEditorJSData = await editorJSInstance.current.save()
    setEditorJSDataHTML(edjsParser.parse(savedEditorJSData))
  }, [])

  const onEditorJSChange = () => {
    handleEditorJsSave()
  }

  const storyUploadHandler = async () => {
    if(title && file){
    // Editor JS DATA
    const editorJSDataHTMLString =
      editorJSDataHTML && editorJSDataHTML.map((htmlTags) => htmlTags).join(' ')

    try {
      const formData = new FormData();
      formData.append('title', title);
      formData.append('userText', editorJSDataHTMLString);
      formData.append('file', file);
      formData.append('filetype', file.type);
      formData.append('uploaderId', uploaderId);
      setIsLoading(true)
      const { data } = await axios.post(
        `${AUTHORIZED_URL_PREFIX}${CREATE_URL}`,
        formData,
        AxiosConfig('multipart/form-data'),
      )
      if (data.success) {
        setTitle('');
        setFile(null);
        setImage(null);
        setVideo(null);
        notify({
          title: `${t('Your story is being uploaded. Please stay on the page until the process is completed')}`,
          severity: NOTIFICATION_SEVERITY_INFO,
          creationDate: '',
        })
      } else {
        notify(pleaseRefreshError)
      }
    } catch (error) {
      console.log(error)
      notify(pleaseRefreshError)
    }
    }
    else{
      notify({
        title: `${t('Please fill out at least the title and a media file')}`,
        severity: NOTIFICATION_SEVERITY_INFO,
        creationDate: '',
      })
    }
  }

  return <div className="lykioccs-main-content">
	{isLoading ? (
	  <Loading />
	) : (
		<div className="lykioccs-main-content lykiocss-upload-bg">
		  <div className="lykiocss-center" style={{marginTop:'1.5em'}}>
		 	<Form className="lykiocss-form lykiocss-form-border lykiocss-form-size">
            <div className="lykiocss-center lykiocss-title-light">{t("Your Story")}</div>
            <div className='float-end'>
            <Tooltip title="Clear the form and start over">
            <Button variant="text" onClick={()=> window.location.reload()}><RefreshIcon/></Button>
            </Tooltip>
            </div>
            <Container className="lykiocss-margin-top-1em">
              <Row>
                  <Form.Group className="mb-3" controlId="title">
                      <FloatingLabel controlId="floatingtitle" label={t("Title")}>
                      <Form.Control
                        required
                        type="text"
                        maxLength={50}
                        placeholder={t("Enter title")}
                        value={title}
                        onChange={(e) => {
                          setTitle(e.target.value);
                        }}
                      />
                      </FloatingLabel>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="story">
                      <Form.Label>{t("Type your story here")}&nbsp;
                      <Tooltip title='You can upload all popular image files. Video files .mp4 and .m4v are also accepted'>
                      <i className="bi bi-info-square"></i>
                      </Tooltip>
                      </Form.Label>
                      <ReactEditorJS
                        style={{ border: "1px solid", paddingbottom: "300px" }}
                        onInitialize={handleEditorJsInitialize}
                        onChange={onEditorJSChange}
                        defaultValue={initEditorJSData}
                        /*data={initEditorJSData}*/
                        tools={EDITOR_JS_TOOLS}
                        minHeight={0}
                        inlineToolbar={true}
                      />
                    </Form.Group>

                    {tenantProperties && tenantProperties.enableUploadForOtherUsers && (
                      <Form.Group className="mb-3" controlId="title">
                        <Autocomplete
                          disablePortal
                          id="combo-box-users"
                          options={tenantUsers}
                          onChange={(event, value) =>
                            setUploaderId(value ? value.id : undefined)
                          }
                          renderInput={(params) => (
                            <TextField {...params} label={t("Uploading on behalf of")} />
                          )}
                        />
                      </Form.Group>
                    )}

                  <Form.Group controlId="formFile" className="mb-3">
                      <Form.Label>{t('Your file')} &nbsp;
                      <Tooltip title='You can upload all popular image files. Video files .mp4 and .m4v are also accepted'>
                      <i className="bi bi-info-square"></i>
                      </Tooltip>
                      </Form.Label>
                      <Form.Control
                      filename={file}
                      accept="image/*,video/mp4,video/x-m4v"
                      required
                      type="file"
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                        onImageChange(e);
                      }}
                    />
                  </Form.Group>
                  
                  <Form.Group controlId="formFileShow" className="mb-3">
                    {!image && !video ? 
                    <div className="lykiocss-create-file-showcase-message lykiocss-text-showcase-light">{t("Your file will show up here")}</div>
                    : <React.Fragment>
                      {image && <div className="lykiocss-create-file-showcase">
                        <Image fluid src={image} alt="preview image"/>
                      </div> }
                      {video && <div className="lykiocss-create-file-showcase">
                      <Ratio aspectRatio="1x1">
                        <div className="lykiocss-story-image">
                          <video width="100%" height="100%">
                            <source
                              src={`${video}#t=1`}
                              type={file.mediatype}
                            ></source>
                          </video>
                        </div>
                      </Ratio>
                      </div> }
                    </React.Fragment>
                    
                    }

                  </Form.Group>
              </Row>
              <LykioButton
                  onClick={()=> storyUploadHandler()}
                  variant="contained"
                  type="submit"
                  className="float-end lykiocss-form-button "
                  
                  >
                  {t("Upload Story")}
              </LykioButton>
            </Container>
          </Form>
          </div>
		</div>
	)}
	 </div>
}

export default Create;
