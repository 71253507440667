import React, { useState, useEffect, useContext } from 'react';
import { LykioContext } from "../routing/LykioAuthorizedRoute";
import axios from 'axios';
import {
	AUTHORIZED_URL_PREFIX,
	NOTIFICATIONS_URL,
	READ_NOTIFICATION_URL,
	AxiosConfig
} from '../../Utils/Constants';
import './notifications.css';
import t from "../../Utils/Translation";
import NotificationsList from './NotificationsList';
import Loading from '../common/Loading';
import Form from 'react-bootstrap/Form';
import {Box, Grid, Pagination, Stack, Typography} from "@mui/material";
import NotificationsIcon from '@mui/icons-material/Notifications';
import Paper from "@mui/material/Paper";

const Notifications = () => {
	const context = useContext(LykioContext);
	const tenantProperties = context.tenantProperties;
	const makeHeadersDark = tenantProperties && tenantProperties.makeHeadersDark;
	const [ notifications, setNotifications ] = useState([]);
	const [ currentPage, setCurrentPage ] = useState(1);
	const [ notificationsPerPage ] = useState(6);
	const [isLoading, setIsLoading] = useState(true)

	// Get current notifications
	const indexOfLastNotification = currentPage * notificationsPerPage;
	const indexOfFirstNotification = indexOfLastNotification - notificationsPerPage;
	const currentNotifications = notifications.slice(indexOfFirstNotification, indexOfLastNotification);

	// Change page
	const count = Math.ceil(notifications.length / notificationsPerPage);
	console.log(count);
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	const markNotificationAsRead = async (notificationId) => {
		try {
			const { data } = await axios.post(
				`${AUTHORIZED_URL_PREFIX}${READ_NOTIFICATION_URL}`,
				{ notificationId: notificationId },
				AxiosConfig()
			);
			if (data.success === true) {
				setNotifications(notifications.filter(notification => notification.id !== notificationId));
				console.log('Successfully read notification');
			}
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		const fetchNotifications = async () => {
			try {
				const { data } = await axios.get(`${AUTHORIZED_URL_PREFIX}${NOTIFICATIONS_URL}`, AxiosConfig());
				if(data.success){
					setNotifications(data.notifications);
					setIsLoading(false);
				}
			} catch (error) {
				console.log(error);
			}
		};
		fetchNotifications();
	}, []);

	return (<div className="lykioccs-main-content">
			{isLoading ? (
				<Loading />
			) : (
				<Grid container justifyContent='center'>
				{notifications.length > 0 ? (
					<Grid item xs={10}>
						<Typography mt={4} variant="h5" gutterBottom component="div">
							{t('NOTIFICATIONS')}<NotificationsIcon sx={{ml:1}}/>
						</Typography>
						<Box mt={2} bgcolor='white' sx={{ borderRadius: '1%', p:4}}>
							<NotificationsList
								notifications={currentNotifications}
								markNotificationAsRead={markNotificationAsRead}
							/>
							<Stack spacing={2} alignItems={'center'} mt={2}>
								<Pagination
									count={count}
									variant="outlined"
									shape={"rounded"}
									page={currentPage}
									onChange={(e, p) => paginate(p)}
								/>
							</Stack>
						</Box>
					</Grid>
				) : (
					<div>{t('No Notifications')}</div>
				)}
				</Grid>
			)}
		</div>
	);
};

export default Notifications;
