import {useState} from 'react';
import { Link } from 'react-router-dom';
import { FRONTEND_LOGIN_URL } from '../../Utils/Constants';
import lykio_logo from '../../multimedia/lykio_logo_transparent.png';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import t from "../../Utils/Translation";

const API = () => {
    const [key, setKey] = useState('token');

	return (
    <div className="lykiocss-generic-background lykiocss-forms-container">
		<div className="lykiocss-screen-center">
            <Form className="LykioForm lykiocss-white-background lykiocss-login-form-border lykiocss-form-size">
            <Link to={FRONTEND_LOGIN_URL}>
					<div className="lykiocss-center">
						<img className="lykiocss-small-login-logo" src={lykio_logo} alt="" />
					</div>
			</Link>
			<h3 className="lykiocss-center">{t("API Documentation")}</h3>
            <Tabs defaultActiveKey="profile" 
            id="uncontrolled-tab-example" 
            activeKey={key}
            onSelect={(k) => setKey(k)} 
            className="mb-3">
            <Tab eventKey="token" title="Get Token">
            <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Get Token</Accordion.Header>
                    <Accordion.Body>
                    <p>
                    This is a POST call performed at <i><b>{`{BASE_URL}`}/api/auth/token</b></i> <br/>
                    where BASE_URL is the hosting website's domain e.g. "https://dev.lykio.com"
                    </p>
                    <p>
                    The required body is a JSON object with the following properties<br/>
                    <code>{
                    `{
                        "username":"somebody@email.com"
                        "password":"whatever123"
                     }`    
                    }</code>
                    </p>
                    <p>
                    The response is an object containing the access_token property<br/>
                    <code>{
                    `{
                        "access_token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxZDdmODN"
                     }`    
                    }</code>
                    </p>
                    </Accordion.Body>
                </Accordion.Item>    
            </Accordion>
            </Tab>
            <Tab eventKey="details" title="User Details">
            <Accordion defaultActiveKey="1" flush>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Get User Details</Accordion.Header>
                    <Accordion.Body>
                    <p>
                This is a GET call performed at <i><b>{`{BASE_URL}`}/api/private/user/details</b></i> <br/>
                where BASE_URL is the hosting website's domain e.g. "https://dev.lykio.com"
                </p>
                <p>
                The required Headers should include the following <br/>
                <Table striped bordered hover>
                <tbody>
                    <tr>
                    <td>Accept</td>
                    <td>application/json</td>
                    </tr>
                    <tr>
                    <td>Authorization</td>
                    <td>Bearer {`{access_token}`}</td>
                    </tr>
                </tbody>
                </Table>
                </p>
                <p>
                The response is an object containing the following<br/>
                <code>{
                `{
                      "username": "user1@mailinator.com",
                      "userId": "61d7f83b9ab097143f12d245",
                      "activated": true,
                      "enabled": true,
                      "classCode": "exampleClass",
                      "levels": [1, 2, 3]
                  }
                `    
                }</code>
                </p>
                    </Accordion.Body>
                </Accordion.Item>    
            </Accordion>
            </Tab>
            <Tab eventKey="data" title="User Data">
            <Accordion defaultActiveKey="1" flush>
                <Accordion.Item eventKey="1">
                <Accordion.Header>Get User Data</Accordion.Header>
                <Accordion.Body>
                <p>
                This is a GET call performed at <i><b>{`{BASE_URL}`}/api/private/user/getData</b></i> <br/>
                where BASE_URL is the hosting website's domain e.g. "https://dev.lykio.com"
                </p>
                <p>
                The required Headers should include the following <br/>
                <Table striped bordered hover>
                <tbody>
                    <tr>
                    <td>Accept</td>
                    <td>application/json</td>
                    </tr>
                    <tr>
                    <td>Authorization</td>
                    <td>Bearer {`{access_token}`}</td>
                    </tr>
                </tbody>
                </Table>
                </p>
                <p>
                The response is an object containing the following<br/>
                <code>{`
                {
                  "currentLevelId": 1,
                  "userAvatarSettings": {
                    "avatarId": 1,
                    "backColorId": 1
                  },
                  "backgroundStatus": [
                    {
                      "level": 1,
                      "selectedBackgroundId": 1,
                      "backgroundStates": "110000"
                    },
                    {
                      "level": 2,
                      "selectedBackgroundId": 1,
                      "backgroundStates": "110000"
                    },
                    {
                      "level": 3,
                      "selectedBackgroundId": 1,
                      "backgroundStates": "110000"
                    },
                    {
                      "level": 4,
                      "selectedBackgroundId": 1,
                      "backgroundStates": "110000"
                    },
                    {
                      "level": 5,
                      "selectedBackgroundId": 1,
                      "backgroundStates": "110000"
                    },
                    {
                      "level": 6,
                      "selectedBackgroundId": 1,
                      "backgroundStates": "110000"
                    }
                  ]
                }
                `}</code>
                </p>
                    </Accordion.Body>
                </Accordion.Item>    
            </Accordion>

            <Accordion defaultActiveKey="1" flush>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Save User Data</Accordion.Header>
                    <Accordion.Body>
                    <p>
                This is a POST call performed at <i><b>{`{BASE_URL}`}/api/private/user/saveData</b></i> <br/>
                where BASE_URL is the hosting website's domain e.g. "https://dev.lykio.com"
                </p>
                <p>
                The required Headers should include the following <br/>
                <Table striped bordered hover>
                <tbody>
                    <tr>
                    <td>Accept</td>
                    <td>application/json</td>
                    </tr>
                    <tr>
                    <td>Authorization</td>
                    <td>Bearer {`{access_token}`}</td>
                    </tr>
                </tbody>
                </Table>
                <p>
                The required body is a JSON object with the following properties<br/>
                    <code>{`
                {
                  "currentLevelId": 1,
                  "userAvatarSettings": {
                    "avatarId": 1,
                    "backColorId": 1
                  },
                  "backgroundStatus": [
                    {
                      "level": 1,
                      "selectedBackgroundId": 1,
                      "backgroundStates": "110000"
                    },
                    {
                      "level": 2,
                      "selectedBackgroundId": 1,
                      "backgroundStates": "110000"
                    },
                    {
                      "level": 3,
                      "selectedBackgroundId": 1,
                      "backgroundStates": "110000"
                    },
                    {
                      "level": 4,
                      "selectedBackgroundId": 1,
                      "backgroundStates": "110000"
                    },
                    {
                      "level": 5,
                      "selectedBackgroundId": 1,
                      "backgroundStates": "110000"
                    },
                    {
                      "level": 6,
                      "selectedBackgroundId": 1,
                      "backgroundStates": "110000"
                    }
                  ]
                }
                `}</code>
                </p>
                
                </p>
                <p>
                The response is an object containing the following (which contains the userSaveJson that was just updated)<br/>
                <code>{`
                {
                  "currentLevelId": 1,
                  "userAvatarSettings": {
                    "avatarId": 1,
                    "backColorId": 1
                  },
                  "backgroundStatus": [
                    {
                      "level": 1,
                      "selectedBackgroundId": 1,
                      "backgroundStates": "110000"
                    },
                    {
                      "level": 2,
                      "selectedBackgroundId": 1,
                      "backgroundStates": "110000"
                    },
                    {
                      "level": 3,
                      "selectedBackgroundId": 1,
                      "backgroundStates": "110000"
                    },
                    {
                      "level": 4,
                      "selectedBackgroundId": 1,
                      "backgroundStates": "110000"
                    },
                    {
                      "level": 5,
                      "selectedBackgroundId": 1,
                      "backgroundStates": "110000"
                    },
                    {
                      "level": 6,
                      "selectedBackgroundId": 1,
                      "backgroundStates": "110000"
                    }
                  ]
                }
                `}</code>
                </p>
                    </Accordion.Body>
                </Accordion.Item>    
            </Accordion>
            </Tab>
            <Tab eventKey="progressdata" title="Progress Data">
            <Accordion defaultActiveKey="1" flush>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Get Progress Data</Accordion.Header>
                    <Accordion.Body>
                    <p>
                This is a GET call performed at <i><b>{`{BASE_URL}`}/api/private/user/getProgressData</b></i> <br/>
                where BASE_URL is the hosting website's domain e.g. "https://dev.lykio.com"
                </p>
                <p>
                The required Headers should include the following <br/>
                <Table striped bordered hover>
                <tbody>
                    <tr>
                    <td>Accept</td>
                    <td>application/json</td>
                    </tr>
                    <tr>
                    <td>Authorization</td>
                    <td>Bearer {`{access_token}`}</td>
                    </tr>
                </tbody>
                </Table>
                </p>
                <p>
                The response is an object containing the following<br/>
                <code>{
                `{
                  "levels": [
                    {
                      "levelId": 1,
                      "currentUnitId": 101,
                      "avgStars": 0,
                      "units": [
                        {
                          "unitId": 101,
                          "unitState": "available",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 1011,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 1012,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1013,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1014,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 102,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 1021,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 1022,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1023,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1024,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 103,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 1031,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 1032,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1033,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1034,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 104,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 1041,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 1042,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 105,
                          "unitState": "available",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 1051,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 1052,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1053,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1054,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 106,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 1061,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 1062,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1063,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1064,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 107,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 1071,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 1072,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1073,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1074,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 108,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 1081,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 1082,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 109,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 1091,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 1092,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1093,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1094,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 110,
                          "unitState": "available",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 1101,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 1102,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1103,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1104,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 111,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 1111,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 1112,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1113,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1114,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 112,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 1121,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 1122,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 113,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 1131,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 1132,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1133,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1134,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 114,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 1141,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 1142,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1143,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1144,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 115,
                          "unitState": "available",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 1151,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 1152,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1153,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1154,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 116,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 1161,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 1162,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "levelId": 2,
                      "currentUnitId": 201,
                      "avgStars": 0,
                      "units": [
                        {
                          "unitId": 201,
                          "unitState": "available",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 2011,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 2012,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2013,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2014,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 202,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 2021,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 2022,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2023,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2024,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 203,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 2031,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 2032,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2033,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2034,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 204,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 2041,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 2042,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 205,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 2051,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 2052,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2053,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2054,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 206,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 2061,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 2062,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2063,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2064,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 207,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 2071,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 2072,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2073,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2074,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 208,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 2081,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 2082,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 209,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 2091,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 2092,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2093,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2094,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 210,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 2101,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 2102,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2103,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2104,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 211,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 2111,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 2112,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2113,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2114,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 212,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 2121,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 2122,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 213,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 2131,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 2132,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2133,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2134,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 214,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 2141,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 2142,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2143,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2144,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 215,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 2151,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 2152,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2153,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2154,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 216,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 2161,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 2162,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "levelId": 3,
                      "currentUnitId": 301,
                      "avgStars": 0,
                      "units": [
                        {
                          "unitId": 301,
                          "unitState": "available",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 3011,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 3012,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3013,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3014,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 302,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 3021,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 3022,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3023,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3024,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 303,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 3031,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 3032,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3033,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3034,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 304,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 3041,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 3042,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 305,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 3051,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 3052,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3053,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3054,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 306,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 3061,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 3062,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3063,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3064,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 307,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 3071,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 3072,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3073,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3074,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 308,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 3081,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 3082,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 309,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 3091,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 3092,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3093,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3094,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 310,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 3101,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 3102,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3103,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3104,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 311,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 3111,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 3112,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3113,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3114,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 312,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 3121,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 3122,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 313,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 3131,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 3132,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3133,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3134,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 314,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 3141,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 3142,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3143,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3144,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 315,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 3151,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 3152,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3153,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3154,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 316,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 3161,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 3162,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "levelId": 4,
                      "currentUnitId": 401,
                      "avgStars": 0,
                      "units": [
                        {
                          "unitId": 401,
                          "unitState": "available",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 4011,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 4012,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4013,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4014,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 402,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 4021,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 4022,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4023,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4024,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 403,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 4031,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 4032,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4033,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4034,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 404,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 4041,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 4042,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 405,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 4051,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 4052,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4053,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4054,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 406,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 4061,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 4062,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4063,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4064,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 407,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 4071,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 4072,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4073,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4074,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 408,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 4081,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 4082,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 409,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 4091,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 4092,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4093,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4094,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 410,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 4101,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 4102,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4103,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4104,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 411,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 4111,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 4112,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4113,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4114,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 412,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 4121,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 4122,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 413,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 4131,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 4132,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4133,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4134,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 414,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 4141,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 4142,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4143,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4144,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 415,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 4151,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 4152,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4153,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4154,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 416,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 4161,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 4162,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "levelId": 5,
                      "currentUnitId": 501,
                      "avgStars": 0,
                      "units": [
                        {
                          "unitId": 501,
                          "unitState": "available",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 5011,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 5012,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5013,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5014,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 502,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 5021,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 5022,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5023,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5024,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 503,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 5031,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 5032,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5033,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5034,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 504,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 5041,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 5042,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 505,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 5051,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 5052,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5053,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5054,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 506,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 5061,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 5062,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5063,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5064,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 507,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 5071,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 5072,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5073,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5074,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 508,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 5081,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 5082,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 509,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 5091,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 5092,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5093,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5094,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 510,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 5101,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 5102,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5103,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5104,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 511,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 5111,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 5112,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5113,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5114,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 512,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 5121,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 5122,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 513,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 5131,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 5132,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5133,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5134,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 514,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 5141,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 5142,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5143,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5144,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 515,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 5151,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 5152,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5153,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5154,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 516,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 5161,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 5162,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "levelId": 6,
                      "currentUnitId": 601,
                      "avgStars": 0,
                      "units": [
                        {
                          "unitId": 601,
                          "unitState": "available",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 6011,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 6012,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6013,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6014,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 602,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 6021,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 6022,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6023,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6024,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 603,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 6031,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 6032,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6033,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6034,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 604,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 6041,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 6042,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 605,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 6051,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 6052,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6053,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6054,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 606,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 6061,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 6062,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6063,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6064,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 607,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 6071,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 6072,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6073,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6074,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 608,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 6081,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 6082,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 609,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 6091,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 6092,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6093,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6094,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 610,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 6101,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 6102,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6103,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6104,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 611,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 6111,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 6112,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6113,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6114,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 612,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 6121,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 6122,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 613,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 6131,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 6132,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6133,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6134,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 614,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 6141,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 6142,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6143,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6144,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 615,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 6151,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 6152,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6153,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6154,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 616,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 6161,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 6162,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
                `    
                }</code>
                </p>
                    </Accordion.Body>
                </Accordion.Item>    
            </Accordion>

            <Accordion defaultActiveKey="1" flush>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Save Progress Data</Accordion.Header>
                    <Accordion.Body>
                    <p>
                This is a POST call performed at <i><b>{`{BASE_URL}`}/api/private/user/saveProgressData</b></i> <br/>
                where BASE_URL is the hosting website's domain e.g. "https://dev.lykio.com"
                </p>
                <p>
                The required Headers should include the following <br/>
                <Table striped bordered hover>
                <tbody>
                    <tr>
                    <td>Accept</td>
                    <td>application/json</td>
                    </tr>
                    <tr>
                    <td>Authorization</td>
                    <td>Bearer {`{access_token}`}</td>
                    </tr>
                </tbody>
                </Table>
                <p>
                The required body is a JSON object with the following properties<br/>
                    <code>{
                    `{
                      "levels": [
                        {
                          "levelId": 1,
                          "currentUnitId": 101,
                          "avgStars": 0,
                          "units": [
                            {
                              "unitId": 101,
                              "unitState": "available",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 1011,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 1012,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 1013,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 1014,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 102,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 1021,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 1022,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 1023,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 1024,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 103,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 1031,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 1032,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 1033,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 1034,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 104,
                              "unitState": "locked",
                              "bonusType": "gift",
                              "lessons": [
                                {
                                  "lessonId": 1041,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 1042,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 105,
                              "unitState": "available",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 1051,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 1052,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 1053,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 1054,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 106,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 1061,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 1062,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 1063,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 1064,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 107,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 1071,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 1072,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 1073,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 1074,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 108,
                              "unitState": "locked",
                              "bonusType": "gift",
                              "lessons": [
                                {
                                  "lessonId": 1081,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 1082,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 109,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 1091,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 1092,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 1093,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 1094,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 110,
                              "unitState": "available",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 1101,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 1102,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 1103,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 1104,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 111,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 1111,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 1112,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 1113,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 1114,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 112,
                              "unitState": "locked",
                              "bonusType": "gift",
                              "lessons": [
                                {
                                  "lessonId": 1121,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 1122,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 113,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 1131,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 1132,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 1133,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 1134,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 114,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 1141,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 1142,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 1143,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 1144,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 115,
                              "unitState": "available",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 1151,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 1152,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 1153,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 1154,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 116,
                              "unitState": "locked",
                              "bonusType": "gift",
                              "lessons": [
                                {
                                  "lessonId": 1161,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 1162,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            }
                          ]
                        },
                        {
                          "levelId": 2,
                          "currentUnitId": 201,
                          "avgStars": 0,
                          "units": [
                            {
                              "unitId": 201,
                              "unitState": "available",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 2011,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 2012,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 2013,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 2014,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 202,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 2021,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 2022,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 2023,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 2024,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 203,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 2031,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 2032,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 2033,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 2034,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 204,
                              "unitState": "locked",
                              "bonusType": "gift",
                              "lessons": [
                                {
                                  "lessonId": 2041,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 2042,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 205,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 2051,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 2052,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 2053,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 2054,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 206,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 2061,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 2062,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 2063,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 2064,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 207,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 2071,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 2072,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 2073,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 2074,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 208,
                              "unitState": "locked",
                              "bonusType": "gift",
                              "lessons": [
                                {
                                  "lessonId": 2081,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 2082,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 209,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 2091,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 2092,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 2093,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 2094,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 210,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 2101,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 2102,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 2103,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 2104,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 211,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 2111,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 2112,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 2113,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 2114,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 212,
                              "unitState": "locked",
                              "bonusType": "gift",
                              "lessons": [
                                {
                                  "lessonId": 2121,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 2122,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 213,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 2131,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 2132,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 2133,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 2134,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 214,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 2141,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 2142,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 2143,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 2144,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 215,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 2151,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 2152,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 2153,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 2154,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 216,
                              "unitState": "locked",
                              "bonusType": "gift",
                              "lessons": [
                                {
                                  "lessonId": 2161,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 2162,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            }
                          ]
                        },
                        {
                          "levelId": 3,
                          "currentUnitId": 301,
                          "avgStars": 0,
                          "units": [
                            {
                              "unitId": 301,
                              "unitState": "available",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 3011,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 3012,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 3013,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 3014,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 302,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 3021,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 3022,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 3023,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 3024,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 303,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 3031,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 3032,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 3033,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 3034,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 304,
                              "unitState": "locked",
                              "bonusType": "gift",
                              "lessons": [
                                {
                                  "lessonId": 3041,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 3042,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 305,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 3051,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 3052,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 3053,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 3054,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 306,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 3061,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 3062,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 3063,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 3064,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 307,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 3071,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 3072,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 3073,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 3074,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 308,
                              "unitState": "locked",
                              "bonusType": "gift",
                              "lessons": [
                                {
                                  "lessonId": 3081,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 3082,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 309,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 3091,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 3092,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 3093,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 3094,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 310,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 3101,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 3102,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 3103,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 3104,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 311,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 3111,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 3112,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 3113,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 3114,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 312,
                              "unitState": "locked",
                              "bonusType": "gift",
                              "lessons": [
                                {
                                  "lessonId": 3121,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 3122,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 313,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 3131,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 3132,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 3133,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 3134,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 314,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 3141,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 3142,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 3143,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 3144,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 315,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 3151,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 3152,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 3153,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 3154,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 316,
                              "unitState": "locked",
                              "bonusType": "gift",
                              "lessons": [
                                {
                                  "lessonId": 3161,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 3162,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            }
                          ]
                        },
                        {
                          "levelId": 4,
                          "currentUnitId": 401,
                          "avgStars": 0,
                          "units": [
                            {
                              "unitId": 401,
                              "unitState": "available",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 4011,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 4012,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 4013,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 4014,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 402,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 4021,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 4022,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 4023,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 4024,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 403,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 4031,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 4032,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 4033,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 4034,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 404,
                              "unitState": "locked",
                              "bonusType": "gift",
                              "lessons": [
                                {
                                  "lessonId": 4041,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 4042,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 405,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 4051,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 4052,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 4053,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 4054,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 406,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 4061,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 4062,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 4063,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 4064,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 407,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 4071,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 4072,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 4073,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 4074,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 408,
                              "unitState": "locked",
                              "bonusType": "gift",
                              "lessons": [
                                {
                                  "lessonId": 4081,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 4082,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 409,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 4091,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 4092,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 4093,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 4094,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 410,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 4101,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 4102,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 4103,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 4104,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 411,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 4111,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 4112,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 4113,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 4114,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 412,
                              "unitState": "locked",
                              "bonusType": "gift",
                              "lessons": [
                                {
                                  "lessonId": 4121,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 4122,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 413,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 4131,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 4132,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 4133,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 4134,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 414,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 4141,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 4142,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 4143,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 4144,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 415,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 4151,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 4152,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 4153,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 4154,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 416,
                              "unitState": "locked",
                              "bonusType": "gift",
                              "lessons": [
                                {
                                  "lessonId": 4161,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 4162,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            }
                          ]
                        },
                        {
                          "levelId": 5,
                          "currentUnitId": 501,
                          "avgStars": 0,
                          "units": [
                            {
                              "unitId": 501,
                              "unitState": "available",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 5011,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 5012,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 5013,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 5014,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 502,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 5021,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 5022,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 5023,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 5024,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 503,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 5031,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 5032,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 5033,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 5034,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 504,
                              "unitState": "locked",
                              "bonusType": "gift",
                              "lessons": [
                                {
                                  "lessonId": 5041,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 5042,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 505,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 5051,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 5052,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 5053,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 5054,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 506,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 5061,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 5062,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 5063,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 5064,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 507,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 5071,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 5072,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 5073,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 5074,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 508,
                              "unitState": "locked",
                              "bonusType": "gift",
                              "lessons": [
                                {
                                  "lessonId": 5081,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 5082,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 509,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 5091,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 5092,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 5093,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 5094,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 510,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 5101,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 5102,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 5103,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 5104,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 511,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 5111,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 5112,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 5113,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 5114,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 512,
                              "unitState": "locked",
                              "bonusType": "gift",
                              "lessons": [
                                {
                                  "lessonId": 5121,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 5122,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 513,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 5131,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 5132,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 5133,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 5134,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 514,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 5141,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 5142,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 5143,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 5144,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 515,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 5151,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 5152,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 5153,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 5154,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 516,
                              "unitState": "locked",
                              "bonusType": "gift",
                              "lessons": [
                                {
                                  "lessonId": 5161,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 5162,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            }
                          ]
                        },
                        {
                          "levelId": 6,
                          "currentUnitId": 601,
                          "avgStars": 0,
                          "units": [
                            {
                              "unitId": 601,
                              "unitState": "available",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 6011,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 6012,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 6013,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 6014,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 602,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 6021,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 6022,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 6023,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 6024,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 603,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 6031,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 6032,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 6033,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 6034,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 604,
                              "unitState": "locked",
                              "bonusType": "gift",
                              "lessons": [
                                {
                                  "lessonId": 6041,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 6042,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 605,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 6051,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 6052,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 6053,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 6054,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 606,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 6061,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 6062,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 6063,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 6064,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 607,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 6071,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 6072,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 6073,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 6074,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 608,
                              "unitState": "locked",
                              "bonusType": "gift",
                              "lessons": [
                                {
                                  "lessonId": 6081,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 6082,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 609,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 6091,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 6092,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 6093,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 6094,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 610,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 6101,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 6102,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 6103,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 6104,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 611,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 6111,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 6112,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 6113,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 6114,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 612,
                              "unitState": "locked",
                              "bonusType": "gift",
                              "lessons": [
                                {
                                  "lessonId": 6121,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 6122,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 613,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 6131,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 6132,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 6133,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 6134,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 614,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 6141,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 6142,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 6143,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 6144,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 615,
                              "unitState": "locked",
                              "bonusType": "",
                              "lessons": [
                                {
                                  "lessonId": 6151,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 6152,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 6153,
                                  "gameState": "locked",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 6154,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            },
                            {
                              "unitId": 616,
                              "unitState": "locked",
                              "bonusType": "gift",
                              "lessons": [
                                {
                                  "lessonId": 6161,
                                  "gameState": "available",
                                  "stars": 0
                                },
                                {
                                  "lessonId": 6162,
                                  "gameState": "locked",
                                  "stars": 0
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }`    
                    }</code>
                </p>
                
                </p>
                <p>
                The response is an object containing the following (the progress data that was just updated)<br/>
                <code>{
                `{
                  "levels": [
                    {
                      "levelId": 1,
                      "currentUnitId": 101,
                      "avgStars": 0,
                      "units": [
                        {
                          "unitId": 101,
                          "unitState": "available",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 1011,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 1012,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1013,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1014,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 102,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 1021,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 1022,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1023,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1024,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 103,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 1031,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 1032,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1033,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1034,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 104,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 1041,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 1042,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 105,
                          "unitState": "available",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 1051,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 1052,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1053,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1054,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 106,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 1061,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 1062,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1063,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1064,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 107,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 1071,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 1072,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1073,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1074,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 108,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 1081,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 1082,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 109,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 1091,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 1092,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1093,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1094,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 110,
                          "unitState": "available",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 1101,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 1102,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1103,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1104,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 111,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 1111,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 1112,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1113,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1114,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 112,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 1121,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 1122,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 113,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 1131,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 1132,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1133,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1134,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 114,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 1141,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 1142,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1143,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1144,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 115,
                          "unitState": "available",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 1151,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 1152,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1153,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 1154,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 116,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 1161,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 1162,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "levelId": 2,
                      "currentUnitId": 201,
                      "avgStars": 0,
                      "units": [
                        {
                          "unitId": 201,
                          "unitState": "available",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 2011,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 2012,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2013,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2014,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 202,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 2021,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 2022,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2023,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2024,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 203,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 2031,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 2032,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2033,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2034,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 204,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 2041,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 2042,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 205,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 2051,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 2052,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2053,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2054,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 206,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 2061,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 2062,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2063,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2064,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 207,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 2071,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 2072,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2073,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2074,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 208,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 2081,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 2082,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 209,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 2091,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 2092,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2093,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2094,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 210,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 2101,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 2102,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2103,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2104,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 211,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 2111,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 2112,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2113,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2114,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 212,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 2121,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 2122,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 213,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 2131,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 2132,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2133,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2134,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 214,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 2141,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 2142,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2143,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2144,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 215,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 2151,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 2152,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2153,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 2154,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 216,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 2161,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 2162,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "levelId": 3,
                      "currentUnitId": 301,
                      "avgStars": 0,
                      "units": [
                        {
                          "unitId": 301,
                          "unitState": "available",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 3011,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 3012,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3013,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3014,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 302,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 3021,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 3022,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3023,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3024,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 303,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 3031,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 3032,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3033,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3034,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 304,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 3041,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 3042,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 305,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 3051,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 3052,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3053,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3054,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 306,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 3061,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 3062,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3063,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3064,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 307,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 3071,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 3072,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3073,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3074,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 308,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 3081,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 3082,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 309,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 3091,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 3092,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3093,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3094,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 310,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 3101,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 3102,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3103,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3104,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 311,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 3111,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 3112,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3113,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3114,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 312,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 3121,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 3122,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 313,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 3131,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 3132,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3133,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3134,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 314,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 3141,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 3142,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3143,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3144,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 315,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 3151,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 3152,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3153,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 3154,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 316,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 3161,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 3162,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "levelId": 4,
                      "currentUnitId": 401,
                      "avgStars": 0,
                      "units": [
                        {
                          "unitId": 401,
                          "unitState": "available",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 4011,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 4012,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4013,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4014,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 402,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 4021,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 4022,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4023,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4024,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 403,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 4031,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 4032,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4033,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4034,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 404,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 4041,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 4042,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 405,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 4051,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 4052,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4053,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4054,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 406,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 4061,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 4062,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4063,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4064,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 407,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 4071,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 4072,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4073,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4074,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 408,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 4081,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 4082,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 409,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 4091,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 4092,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4093,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4094,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 410,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 4101,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 4102,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4103,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4104,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 411,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 4111,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 4112,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4113,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4114,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 412,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 4121,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 4122,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 413,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 4131,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 4132,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4133,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4134,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 414,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 4141,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 4142,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4143,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4144,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 415,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 4151,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 4152,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4153,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 4154,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 416,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 4161,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 4162,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "levelId": 5,
                      "currentUnitId": 501,
                      "avgStars": 0,
                      "units": [
                        {
                          "unitId": 501,
                          "unitState": "available",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 5011,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 5012,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5013,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5014,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 502,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 5021,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 5022,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5023,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5024,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 503,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 5031,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 5032,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5033,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5034,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 504,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 5041,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 5042,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 505,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 5051,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 5052,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5053,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5054,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 506,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 5061,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 5062,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5063,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5064,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 507,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 5071,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 5072,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5073,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5074,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 508,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 5081,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 5082,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 509,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 5091,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 5092,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5093,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5094,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 510,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 5101,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 5102,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5103,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5104,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 511,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 5111,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 5112,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5113,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5114,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 512,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 5121,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 5122,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 513,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 5131,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 5132,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5133,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5134,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 514,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 5141,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 5142,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5143,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5144,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 515,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 5151,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 5152,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5153,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 5154,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 516,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 5161,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 5162,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "levelId": 6,
                      "currentUnitId": 601,
                      "avgStars": 0,
                      "units": [
                        {
                          "unitId": 601,
                          "unitState": "available",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 6011,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 6012,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6013,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6014,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 602,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 6021,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 6022,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6023,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6024,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 603,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 6031,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 6032,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6033,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6034,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 604,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 6041,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 6042,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 605,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 6051,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 6052,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6053,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6054,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 606,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 6061,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 6062,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6063,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6064,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 607,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 6071,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 6072,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6073,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6074,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 608,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 6081,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 6082,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 609,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 6091,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 6092,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6093,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6094,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 610,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 6101,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 6102,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6103,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6104,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 611,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 6111,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 6112,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6113,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6114,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 612,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 6121,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 6122,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 613,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 6131,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 6132,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6133,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6134,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 614,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 6141,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 6142,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6143,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6144,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 615,
                          "unitState": "locked",
                          "bonusType": "",
                          "lessons": [
                            {
                              "lessonId": 6151,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 6152,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6153,
                              "gameState": "locked",
                              "stars": 0
                            },
                            {
                              "lessonId": 6154,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        },
                        {
                          "unitId": 616,
                          "unitState": "locked",
                          "bonusType": "gift",
                          "lessons": [
                            {
                              "lessonId": 6161,
                              "gameState": "available",
                              "stars": 0
                            },
                            {
                              "lessonId": 6162,
                              "gameState": "locked",
                              "stars": 0
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }`    
                }</code>
                </p>
                    </Accordion.Body>
                </Accordion.Item>    
            </Accordion>
            </Tab>
            <Tab eventKey="leveldata" title="Level Data">
            <Accordion defaultActiveKey="1" flush>
                <Accordion.Item eventKey="1">
                <Accordion.Header>Get Level Data</Accordion.Header>
                <Accordion.Body>
                <p>
                This is a GET call performed at <i><b>{`{BASE_URL}`}/api/private/user/getLevelData</b></i> <br/>
                where BASE_URL is the hosting website's domain e.g. "https://dev.lykio.com"
                </p>
                <p>
                The required Headers should include the following <br/>
                <Table striped bordered hover>
                <tbody>
                    <tr>
                    <td>Accept</td>
                    <td>application/json</td>
                    </tr>
                    <tr>
                    <td>Authorization</td>
                    <td>Bearer {`{access_token}`}</td>
                    </tr>
                </tbody>
                </Table>
                </p>
                <p>
                The response is an object containing the following<br/>
                <code>
                  {`
                  {
                    "level": 1,
                    "classCode": "Global",
                    "units": [
                      {
                        "unitId": 101,
                        "users": [
                          {
                            "userId": "62d953927e2f19f325e706cb",
                            "userName": "teacher1",
                            "userAvatarSettings": {
                              "avatarId": 1,
                              "backColorId": 1
                            }
                          }
                        ]
                      },
                      {
                        "unitId": 103,
                        "users": [
                          {
                            "userId": "2",
                            "userName": "chris",
                            "userAvatarSettings": {
                              "avatarId": 3,
                              "backColorId": 3
                            }
                          },
                          {
                            "userId": "3",
                            "userName": "douk",
                            "userAvatarSettings": {
                              "avatarId": 4,
                              "backColorId": 4
                            }
                          }
                        ]
                      },
                      {
                        "unitId": 105,
                        "users": [
                          {
                            "userId": "41",
                            "userName": "telis",
                            "userAvatarSettings": {
                              "avatarId": 5,
                              "backColorId": 5
                            }
                          },
                          {
                            "userId": "34",
                            "userName": "kalios",
                            "userAvatarSettings": {
                              "avatarId": 16,
                              "backColorId": 3
                            }
                          },
                          {
                            "userId": "33",
                            "userName": "martines",
                            "userAvatarSettings": {
                              "avatarId": 17,
                              "backColorId": 2
                            }
                          }
                        ]
                      },
                      {
                        "unitId": 107,
                        "users": [
                          {
                            "userId": "22",
                            "userName": "ria",
                            "userAvatarSettings": {
                              "avatarId": 43,
                              "backColorId": 5
                            }
                          }
                        ]
                      },
                      {
                        "unitId": 109,
                        "users": [
                          {
                            "userId": "12",
                            "userName": "sim",
                            "userAvatarSettings": {
                              "avatarId": 62,
                              "backColorId": 4
                            }
                          },
                          {
                            "userId": "3",
                            "userName": "chris",
                            "userAvatarSettings": {
                              "avatarId": 35,
                              "backColorId": 5
                            }
                          }
                        ]
                      },
                      {
                        "unitId": 113,
                        "users": [
                          {
                            "userId": "2",
                            "userName": "chris",
                            "userAvatarSettings": {
                              "avatarId": 40,
                              "backColorId": 6
                            }
                          }
                        ]
                      },
                      {
                        "unitId": 114,
                        "users": [
                          {
                            "userId": "201",
                            "userName": "haris",
                            "userAvatarSettings": {
                              "avatarId": 27,
                              "backColorId": 1
                            }
                          }
                        ]
                      }
                    ]
                  }
                  `}
                </code>
                </p>
                    </Accordion.Body>
                </Accordion.Item>    
            </Accordion>
            </Tab>
            <Tab eventKey="reportData" title="Report">
            <Accordion defaultActiveKey="1" flush>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Save Report</Accordion.Header>
                    <Accordion.Body>
                    <p>
                This is a POST call performed at <i><b>{`{BASE_URL}`}/api/private/user/saveReport</b></i> <br/>
                where BASE_URL is the hosting website's domain e.g. "https://dev.lykio.com"
                </p>
                <p>
                The required Headers should include the following <br/>
                <Table striped bordered hover>
                <tbody>
                    <tr>
                    <td>Accept</td>
                    <td>application/json</td>
                    </tr>
                    <tr>
                    <td>Authorization</td>
                    <td>Bearer {`{access_token}`}</td>
                    </tr>
                </tbody>
                </Table>
                <p>
                The required body is a JSON object with the following properties<br/>
                    <code>{
                    `{
                        "reportData": {
                          TBD
                        }
                      }`    
                    }</code>
                </p>
                
                </p>
                <p>
                The response is an object containing the following (the progress data that was just updated)<br/>
                <code>{
                `{
                  "reportData": {
                    TBD
                  }
                }`     
                }</code>
                </p>
                    </Accordion.Body>
                </Accordion.Item>    
            </Accordion>
            </Tab>
            <Tab eventKey="leaderboards" title="Leaderboards">
            <Accordion defaultActiveKey="1" flush>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Get User Details</Accordion.Header>
                    <Accordion.Body>
                    <p>
                This is a GET call performed at <i><b>{`{BASE_URL}`}/api/private/user/getLeaderBoard</b></i> <br/>
                where BASE_URL is the hosting website's domain e.g. "https://dev.lykio.com"
                </p>
                <p>
                The required Headers should include the following <br/>
                <Table striped bordered hover>
                <tbody>
                    <tr>
                    <td>Accept</td>
                    <td>application/json</td>
                    </tr>
                    <tr>
                    <td>Authorization</td>
                    <td>Bearer {`{access_token}`}</td>
                    </tr>
                </tbody>
                </Table>
                </p>
                <p>
                The response is an object containing the following<br/>
                <code>{
                `{
                    "leaderboardData": {
                      "users": [
                        {
                          "username": "user1@mailinator.com",
                          "finalScore": 35,
                          "avatarId": 4
                        },
                        {
                          "username": "user2@mailinator.com",
                          "finalScore": 30,
                          "avatarId": 4
                        },
                        {
                          "username": "dev@mailinator.com",
                          "finalScore": 25,
                          "avatarId": 4
                        }
                      ]
                    }
                  }
                `    
                }</code>
                </p>
                    </Accordion.Body>
                </Accordion.Item>    
            </Accordion>
            </Tab>
            <Tab eventKey="clientSafeVersion" title="Client Safe Version">
            <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Get Client Safe Version</Accordion.Header>
                    <Accordion.Body>
                    <p>
                    This is a GET call performed at <i><b>{`{BASE_URL}`}/api/private/getClientSafeVersion</b></i> <br/>
                    where BASE_URL is the hosting website's domain e.g. "https://dev.lykio.com"
                    </p>
                    <p>
                    The call is open. No specific Headers, Auth or Body are needed for it.
                    </p>
                    <p>
                    The response is an object containing the clientSafeVersion property<br/>
                    <code>{
                    `{
                      "clientSafeVersion": "v0.7.0"
                    }`    
                    }</code>
                    </p>
                    </Accordion.Body>
                </Accordion.Item>    
            </Accordion>
            </Tab>
            </Tabs>
            </Form>
		</div>
    </div>
	);
};

export default API;
