import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import Story from '../stories/Story';
import AddableUnit from './AddableUnit';

function PlaylistItemsSplider(props) {
  const addable = props.addable;

  const options = {
    type: 'slide',
    width: '100%',
    perPage: 4,
    perMove: 3,
    breakpoints: {
      990: {
        perPage: 3,
        perMove: 2
      },
      767: {
        perPage: 2,
        perMove: 1
      },
      640: {
        perPage: 1,
        perMove: 1
      }
    },
    rewind: true,
    gap: '0.1em',
    ...props
  }
    return ( <Splide
        options={ options }>
          {props.units.map((unit, i) => {
            return (
              addable ?  <SplideSlide key={i}>
              <AddableUnit result={unit} unitsToAdd={props.unitsToAdd} modifyUnitsToAdd={props.modifyUnitsToAdd}/>
            </SplideSlide> :
              <SplideSlide key={i}>
                <Story story={unit} addableUnit={true} ></Story>
              </SplideSlide>
            );
          })}
        </Splide> );
}

export default PlaylistItemsSplider;