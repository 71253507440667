import React, { useContext, useState } from 'react';
import { LykioContext } from '../routing/LykioAuthorizedRoute';
import LykioButton from "../common/LykioButton";
import Modal from 'react-bootstrap/Modal';

function SubCategoryModal(props) {
    const context = useContext(LykioContext);
    const tenantProperties = context.tenantProperties;
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const subCategory = props.subCategory;
    const allCategories = props.allCategories;

    const getFullCategoryName = (categoryreference)=>{
		let fullCategoryName;
		if(tenantProperties && tenantProperties.categories){
			tenantProperties.categories.forEach((category) => {
		   	if(category.reference === categoryreference){
				fullCategoryName = category.name;
		   	}
		   	});
		   if(!fullCategoryName){
			tenantProperties.categories.forEach((category) => {
				for(let i = 0; i < category.subCategories.length; i++){
					if(category.subCategories[i].reference === categoryreference){
						fullCategoryName = category.subCategories[i].name;
					}
				}
			});
		   }
		}
		console.log(categoryreference);
		return fullCategoryName;
	}

    return ( <React.Fragment>
        <LykioButton variant="text" onClick={handleShow}>{(allCategories.filter(category=> category.reference===subCategory.reference)[0]) && allCategories.filter(category=> category.reference===subCategory.reference)[0].name}</LykioButton>
        <Modal show={show} onHide={handleClose}>
        <Modal.Body>
           <div className='lykiocss-subcategory-modal-title'>{getFullCategoryName(subCategory.reference)}</div>
           <p>{subCategory.description}</p>
        </Modal.Body>
      </Modal>
    </React.Fragment> );
}

export default SubCategoryModal;