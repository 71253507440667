import { useState, useContext } from 'react';
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import axios from 'axios';
import {AUTHORIZED_URL_PREFIX, STRIPE_PAYMENT_URL, AxiosConfig} from '../../Utils/Constants';
import t from "../../Utils/Translation";
import LykioButton from "../common/LykioButton";
import Form from 'react-bootstrap/Form';
import { LykioContext } from '../routing/LykioAuthorizedRoute';

function PaymentForm() {
    const context = useContext(LykioContext);
    const [success, setSuccess] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const userDetails = context.userDetails;


    const CARD_OPTIONS = {
        iconStyle:'solid',
        style: {
          base: {
            iconColor: 'grey',
            color: 'black',
            fontWeight: '500',
            fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
            fontSize: '16px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': {
              color: 'white',
            },
            '::placeholder': {
              color: 'black',
            },
          },
          invalid: {
            iconColor: 'black',
            color: 'black',
          },
        },
    };

const handlePayment = async(e)=> {
    e.preventDefault();
    const {error, paymentMethod} = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement)
    }) 

    if(!error){
        try {
            const {id} = paymentMethod;
            const response = await axios.post(
                `${AUTHORIZED_URL_PREFIX}${STRIPE_PAYMENT_URL}`,
                { amount:1000, id, payer: userDetails.email },
                AxiosConfig()
            );
            if(response.data.success){
                console.log('successful payment');
                setSuccess(true);
            }
        } catch (err) {
            console.log("Error", err);
        }
    }else{
        console.log("Error", error);
    }
}

return (
    <div>
        {!success ? 
        <div className="lykiocss-center">
        <Form onSubmit={handlePayment} className="lykiocss-form lykiocss-form-border lykiocss-form-size" style={{width:'30em'}}>
            <Form.Group className="mb-3">
                <fieldset>
                    <div >
                        <CardElement options={CARD_OPTIONS}/>
                    </div>
                </fieldset>
            </Form.Group>
            
            <Form.Group className="mb-3">
                <LykioButton variant="contained" type="submit">
                    {t("Pay")}
                </LykioButton>
            </Form.Group>
        </Form>
        </div> : 
        <div>
            <h2>{t("You have just upgraded your plan")}</h2>
        </div>
        }
        
    </div>
)
}

export default PaymentForm;
