import React, { useState } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Badge from 'react-bootstrap/Badge';
import Image from 'react-bootstrap/Image';
import t from '../../Utils/Translation';

import { Tooltip } from '@mui/material';
import LinearWithValueLabel from './ProgressBar';
import './profileBox.css';

const ProfileBox = (props) => {
    const userDetails = props.userDetails;
    const [elementToDisplay, setElementToDisplay] = useState('awards'); // 'xp', 'impact'
    
    /* 
    setTimeout(() => {
        setElementToDisplay(getNextElement());
    }, 2000);

    const getNextElement = ()=>{
        if (elementToDisplay === 'awards') {
            console.log('awards')
            return 'xp';
        }  
        if (elementToDisplay === 'xp'){
            console.log('xp')
            return 'impact';
        }
        return 'awards';
    } 
    */
	return <div className="profile-box-container lykiocss-navigation-hide-on-mobile" style={{paddingRight:'0em'}}>
    <div className="profile-box-top">
        <div>{t("Your Progress")}</div>
        <div className="lykiocss-profile-box-top-level" >
            <Badge>
                <Tooltip title={t("This is your level")}>
                    <span>
                    {userDetails.level}
                    </span>
                </Tooltip>
            </Badge>
        </div>
    </div>
    <div className="profile-box-middle">

        {userDetails.streak.percent===100 ? 
        <Tooltip title={t("Your login streak is greater than 8 days! You will get double XP for everything you do!")}>
        <ProgressBar now={100} title={t('Your login streak is greater than 8 days! You will get double XP for everything you do!')} label={t('Double XP: On')} />
        </Tooltip>
        : 
        <Tooltip title={t("This is your login streak. Beyond 8, you get double XP!")}>
        <ProgressBar variant="progress-bar-custom" title={t('This is your login streak. Beyond 8, you get double XP!')} now={userDetails.streak.percent} label={`${userDetails.streak.consecutiveDays}-${t('day streak')}`} />
        </Tooltip>} 


        <div className='lykiocss-profilebox-text-style'>
            <span><i className="bi bi-bullseye"></i> {userDetails.earnedXP} XP /  </span>
            <Tooltip title={t("This is your impact calculated by your overall interactivity")}>
                <span>
                    {t('Impact')}: {Math.floor(userDetails.impactIndex)}
                </span>
            </Tooltip>
        </div>

    </div>


    <div className="lykiocss-profile-box-achievements profile-box-bottom" style={userDetails.awards.length>5 ? {marginTop:'-0.5rem'} : {marginTop:'0rem'}}>        
        <div>
            {(userDetails.awards && userDetails.awards.length > 0 ) ? (
						userDetails.awards.map((award, index) => {
							return (
								<React.Fragment>
                                    {userDetails.earnedAwards.includes(award._id) ?
                                        (<Tooltip title={`You have earned the ${award.title} badge`}>
                                            <Image key={index} className="lykiocss-navigation-badge" src={award.image}/>
                                        </Tooltip>
                                        ) : (
                                            <Tooltip title={`${award.tagline} to earn the ${award.title} badge`}>
                                                <Image style = {{ filter: 'grayscale(100%)' }} key={index} className="lykiocss-navigation-badge" src={award.image}/>
                                            </Tooltip>
                                        )
                                    }
								</React.Fragment>
							);
						})
					) : (
					<div>{t("No earned awards yet")}</div>
			)}
        </div>
    </div>
    
</div>
};

export default ProfileBox;
