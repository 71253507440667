import React, { useMemo, useState } from 'react';
import { useSortBy, useTable, usePagination } from 'react-table';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import {
	AUTHORIZED_URL_PREFIX,
	MODERATE_UNIT_URL,
	NOTIFICATION_SEVERITY_SUCCESS,
	UNIT_EDIT_WITHOUTPARAM_URL,
	AxiosConfig,
	APPROVED_UNIT,
	PENDING_UNIT,
	ACTIVE_UNIT,
	REJECTED_UNIT,
	INACTIVE_UNIT
} from '../../Utils/Constants';
//import Loading from '../common/Loading';
import friendlyDate from '../../Utils/FriendlyDate';
import t from '../../Utils/Translation';
import { notify, pleaseRefreshError } from '../../Utils/Notificator';
import LykioButton from "../common/LykioButton";
import UnitModal from './../common/UnitModal';
import './unitsTable.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Divider from '@mui/material/Divider';

const UnitsTable = ({ units, type }) => {
	const navigate = useNavigate();
	const [ myTableData, setmyTableData ] = useState(useMemo(() => [ ...units ], []));

	//Table Design Headers/Columns
	const myTableColumns = useMemo(
		() => [
			{
				Header: t('Title'),
				accessor: 'title'
			},
			{
				Header: t('Language'),
				accessor: 'defaultLanguage'
			},
			{
				Header: t('Category'),
				accessor: 'categoryreference',
				accessor: unit => unit.categoryreference===undefined ? 'Story' : unit.categoryreference
			},
			{
				Header: t('Uploader'),
				accessor: 'uploader.fullName'
			},
			{
				id: 'featured',
				Header: t('Featured'),
				accessor: unit => unit.featured ? 'yes' : 'no'
			},
			{
				Header: t('Uploaded'),
				accessor: 'creationDate',
				Cell: ({ value }) => {
					return friendlyDate(new Date(value));
				}
			},
			{
				Header: t('Status'),
				accessor: 'status',
				Cell: ({ value }) => {
					return t(value);
				}
			}
		],
		[]
	);

	//Insert column with Actions buttons
	const tableHooks = (hooks) => {
		hooks.visibleColumns.push((columns) => [
			...columns,
			{
				id: 'Actions',
				Header: t('Actions'),
				Cell: ({ row }) => {
					const [show, setShow] = useState(false);
					const handleClose = () => setShow(false);
					const handleShow = () => setShow(true);
					return <React.Fragment>
						{getButtons(units[row.id])}
						{type==='Stories' && 
							<React.Fragment>
							{unitTableButtonComponent('View', () => {handleShow()})}
							<UnitModal key={row.id} unit={units[row.id]} show={show} handleClose={handleClose} />
							</React.Fragment>
						}
						&nbsp;
						{type==='System' &&
						<React.Fragment>{unitTableButtonComponent('Edit', () => navigate(`${UNIT_EDIT_WITHOUTPARAM_URL}/${units[row.id]._id}`)
						)}</React.Fragment>
						}
					</React.Fragment>
				}
			}
		]);
	};

	//React Table Setup
	const tableInstance = useTable(
		{ columns: myTableColumns, data: myTableData },
		tableHooks,
		useSortBy,
		usePagination
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		pageOptions,
		gotoPage,
		pageCount,
		setPageSize,
		state,
		prepareRow
	} = tableInstance;

	const { pageIndex, pageSize } = state;

	//LykioButton Component
	const unitTableButtonComponent = (label, buttonHandler) => {
		return (
			<LykioButton variant="text"
				onClick={() => {
					buttonHandler();
				}}
			>
				{t(`${label}`)}
			</LykioButton>
		);
	};

	//Decide what buttons to show
	const getButtons = (unit) => {
		return (
			<React.Fragment>
				{(unit.type === 'System-Lykio' && unit.status === INACTIVE_UNIT) && 
				<React.Fragment>{unitTableButtonComponent(t('Activate'), () =>
					updateUnitHandler(unit._id, ACTIVE_UNIT))}&nbsp;</React.Fragment>
				}

				{(unit.type === 'System-Lykio' && unit.status === ACTIVE_UNIT) && 
				<React.Fragment>{unitTableButtonComponent(t('Deactivate'), () =>
					updateUnitHandler(unit._id, INACTIVE_UNIT))}&nbsp;</React.Fragment>
				}

				{(unit.type.startsWith('Story') && unit.status === PENDING_UNIT) && 
				<React.Fragment>{unitTableButtonComponent(t('Approve'), () =>
				updateUnitHandler(unit._id, APPROVED_UNIT)
				)}&nbsp;</React.Fragment>
				}

				{(unit.type.startsWith('Story') && unit.status === APPROVED_UNIT) && 
				<React.Fragment>{unitTableButtonComponent(t('Reject'), () =>
				updateUnitHandler(unit._id, REJECTED_UNIT)
				)}&nbsp;</React.Fragment>
				}

				{(unit.type.startsWith('Story') && unit.status === REJECTED_UNIT) && 
				<React.Fragment>{unitTableButtonComponent(t('Approve'), () =>
				updateUnitHandler(unit._id, APPROVED_UNIT)
				)}&nbsp;</React.Fragment>
				}

				{(unit.type.startsWith('Story') && unit.featured) && 
				<React.Fragment>{unitTableButtonComponent(t('Stop Featuring'), () =>
				updateUnitHandler(unit._id, unit.status, false)
				)}&nbsp;</React.Fragment>
				}
				
				{(unit.type.startsWith('Story') && !unit.featured) && 
				<React.Fragment>{unitTableButtonComponent(t('Feature'), () =>
				updateUnitHandler(unit._id, unit.status, true)
				)}&nbsp;</React.Fragment>
				}
			</React.Fragment>
		);
	};

	//LykioButton Handlers
	// const viewUnitHandler = (unitId) => {
	// 	handleShow();
	// 	console.log(`ViewButton ${unitId}`);
	// };

	const editUnitHandler = (unitId) => {
		console.log(`EditButton ${unitId}`);
	};

	const updateUnitHandler = async (unitId, newstatus, featured) => {
		try {
			const { data } = await axios.post(
				`${AUTHORIZED_URL_PREFIX}${MODERATE_UNIT_URL}`,
				{ unitId, newstatus: newstatus, featured },
				AxiosConfig()
			);
			if (data.success === true) {
				notify({
					title: `${t('Your action was completed successfully.')}`,
					severity: NOTIFICATION_SEVERITY_SUCCESS,
					creationDate: ''
				});

				const unitIndex = units.findIndex((obj) => obj._id === unitId);
				units[unitIndex].status = newstatus;
				featured!== undefined && (units[unitIndex].featured = featured);
				const dataCopy = [ ...units ];
				setmyTableData(dataCopy);
			} else {
				notify(pleaseRefreshError);
			}
		} catch (error) {
			console.log(error);
			notify(pleaseRefreshError);
		}
	};

	//Return Table creation/Pagination
	return (
		<React.Fragment>
			<Table  {...getTableProps()}>
				<TableHead>
					{headerGroups.map((headerGroup) => (
						<TableRow {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<th {...column.getHeaderProps(column.getSortByToggleProps())}>
									{column.render('Header')}
									{column.isSorted ? column.isSortedDesc ? ' ↓' : ' ↑' : ''}
								</th>
							))}
						</TableRow>
					))}
				</TableHead>
				<TableBody {...getTableBodyProps()}>
					{page.map((row) => {
						prepareRow(row);
						return (
							<TableRow {...row.getRowProps()}>
								{row.cells.map((cell) => {
									return <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>;
								})}
							</TableRow>
						);
					})}
				</TableBody>
			</Table>

			{/*Pagination*/}
			<div className="lykiocss-pagination lykiocss-stick-to-bottom">
				<LykioButton variant="contained" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
					{t('First')}
				</LykioButton>&nbsp;
				<LykioButton variant="contained" onClick={() => previousPage()} disabled={!canPreviousPage}>
					{t('Previous')}
				</LykioButton>&nbsp;
				<span className="lykiocss-pageNum">
					{' '}
					{t('Page')} {pageIndex + 1} / {pageOptions.length}{' '}
				</span>&nbsp;
				<span>
					{' '}
					| {t('Page')}{' '}
					<input
						className="lykiocss-pageSelector"
						type="number"
						value={pageIndex + 1}
						onChange={(e) => gotoPage(e.target.value ? Number(e.target.value) - 1 : 0)}
					/>{' '}
				</span>&nbsp;
				<LykioButton variant="contained" onClick={() => nextPage()} disabled={!canNextPage}>
					{t('Next')}
				</LykioButton>&nbsp;
				<LykioButton
					variant="contained"
					onClick={() => gotoPage(pageCount - 1)}
					disabled={!canNextPage}
				>
					{t('Last')}
				</LykioButton>&nbsp;
				<select
					variant="contained"
					value={pageSize}
					onChange={(e) => setPageSize(Number(e.target.value))}
				>
					{[ 5, 10, 25, 50, 100 ].map((pageSize) => (
						<option key={pageSize} value={pageSize}>
							{' '}
							{pageSize}
						</option>
					))}
				</select>
			</div>
		</React.Fragment>
	);
};

export default UnitsTable;
