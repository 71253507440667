import { useState, useEffect } from 'react';
import axios from 'axios';
import LykioButton from "../common/LykioButton";
import Form from 'react-bootstrap/Form';
import {
	AUTHORIZED_URL_PREFIX,
	GROUPS_URL,
	USER_MANAGEMENT_URL,
	USER_MANAGEMENT_ADD_GROUP_URL,
	NOTIFICATION_SEVERITY_SUCCESS,
	NOTIFICATION_SEVERITY_WARNING,
	AxiosConfig
} from '../../Utils/Constants';
import './addGroup.css';
import t from "../../Utils/Translation";
import Loading from '../common/Loading';
import { useNavigate } from 'react-router';
import { notify, pleaseRefreshError } from '../../Utils/Notificator';
import FloatingLabel from "react-bootstrap/FloatingLabel";


const AddGroup = () => {
	const navigate = useNavigate();
	const [ group, setGroup ] = useState('');
	const [ availableGroups, setAvailableGroups ] = useState([]);
	const [ isLoading, setIsLoading ] = useState(true);

	useEffect(() => {
		const fetchGroupsData = async () => {
			try {
				const { data } = await axios.get(`${AUTHORIZED_URL_PREFIX}${GROUPS_URL}`, AxiosConfig());
				if (data.success) {
					setAvailableGroups(data.groups);
					setIsLoading(false);
				} else {
					notify(pleaseRefreshError);
				}
			} catch (error) {
				console.error(error);
				notify(pleaseRefreshError);
			}
		};

		fetchGroupsData();
	}, []);

	const addNewGroup = async (e) => {
		e.preventDefault();
		try {
			let createGroup = true;
			availableGroups.forEach(existingGroup =>{ 
				if(existingGroup.name===group){
					createGroup=false;
				}
			})
			if(createGroup){
				const { data } = await axios.post(
					`${AUTHORIZED_URL_PREFIX}${USER_MANAGEMENT_ADD_GROUP_URL}`,
					{ group },
					AxiosConfig()
				);
				if (data.success === true) {
					notify({
						title: `${t('Your group was added successfully.')}`,
						severity: NOTIFICATION_SEVERITY_SUCCESS,
						creationDate: ''
					});
					navigate(USER_MANAGEMENT_URL);
				}else{
					notify(pleaseRefreshError);
				}
			}else{
				notify({
					title: `${t('A group of that name already exists.')}`,
					severity: NOTIFICATION_SEVERITY_WARNING,
					creationDate: ''
				});
			}
		} catch (error) {
			console.log(error);
			notify(pleaseRefreshError);
		}
	};

	return <div className="lykioccs-main-content">
		{isLoading ? <Loading /> : <div className="lykiocss-center lykiocss-margin-top-2em">
		<Form onSubmit={addNewGroup} className="lykiocss-form lykiocss-form-border lykiocss-form-size">
			<div className="lykiocss-center lykiocss-title-light">{t('Add a new group')}</div>
			<Form.Group className="mb-3 lykiocss-margin-top-1em" controlId="userType">
				<FloatingLabel controlId="floatinggroups" label={t('Current groups')}>
				<Form.Select>
					{availableGroups.map((group) => (
						<option key={group._id} value={group._id}>
							{group.name}
						</option>
					))}
				</Form.Select>
				</FloatingLabel>
			</Form.Group>
			<Form.Group className="mb-3" controlId="group">
				<FloatingLabel controlId="floatinggroup" label={t('Group name')}>
				<Form.Control
					required
					type="text"
					placeholder={t('Enter your group name')}
					defaultValue={group}
					onChange={(e) => {
						setGroup(e.target.value);
					}}
				/>
				</FloatingLabel>
			</Form.Group>
			<LykioButton
				variant="contained"
				type="submit"
				
				className="float-end"
			>
				{t('Save')}
			</LykioButton>
		</Form>
		</div>}
		</div>
};

export default AddGroup;
