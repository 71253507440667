import { useState, useEffect } from 'react';
import axios from 'axios';
import t from "../../Utils/Translation";
import Tooltip from '@mui/material/Tooltip';
import {
    AUTHORIZED_URL_PREFIX,
	IS_UNIT_FAVOURITE,
	UPDATE_FAVOURITES,
	AxiosConfig
} from '../../Utils/Constants';
import { notify, pleaseRefreshError } from '../../Utils/Notificator';


const Favourite = (props) => {
    const unitId = props.unitId;
    const [isFavourite, setIsFavourite] = useState(false);

    useEffect(
		() => {
            const isUnitFavourite = async () => {
                try {
                    const { data } = await axios.post(
                        `${AUTHORIZED_URL_PREFIX}${IS_UNIT_FAVOURITE}`,
                        { unitId },
                        AxiosConfig()
                    );
                    setIsFavourite(data.isFavourite);
                    console.log(`favourite: ${isFavourite}`);
                } catch (error) {
                    console.log(error);
                    notify(pleaseRefreshError);
                }
            };
            isUnitFavourite();
		},
		[]
	);

    const updateFavouritesHandler = async(addToFavourites)=>{
        const { data } = await axios.post(
            `${AUTHORIZED_URL_PREFIX}${UPDATE_FAVOURITES}`,
            { unitId, addToFavourites },
            AxiosConfig()
        );
        setIsFavourite(addToFavourites);
        if (!data.success) {
            notify(pleaseRefreshError);
        }
    }

    return ( isFavourite ? (<Tooltip title={t("Remove from my Favourites playlist")}>
            <i className="bi bi-bookmark-check-fill" onClick={()=>{updateFavouritesHandler(false)}}/>
            </Tooltip>) : (<Tooltip title={t("Add to my Favourites playlist")}>
            <i className="bi bi-bookmark" onClick={()=>{updateFavouritesHandler(true)}}/>
            </Tooltip>)
    );
}

export default Favourite;