import axios from "axios";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./verifyAccountScreen.css";
import {
  HOME_URL,
  FRONTEND_LOGIN_URL,
  PUBLIC_URL_PREFIX,
  UBER_ADMIN_SETTINGS_URL,
  VERIFYACCOUNT_URL,
  NOTIFICATION_SEVERITY_SUCCESS,
  AxiosConfigNoAuth,
} from "../../Utils/Constants";
import t from "../../Utils/Translation";
import lykio_logo from "../../multimedia/Default/white_loading.gif";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { notify, pleaseRefreshError } from "../../Utils/Notificator";

const VerifyAccountScreen = () => {
  const [authScreensLogo, setAuthScreensLogo] = useState();
  const navigate = useNavigate();
  const { verificationToken } = useParams();

  useEffect(async () => {
    const { data } = await axios.get(
      `${PUBLIC_URL_PREFIX}${UBER_ADMIN_SETTINGS_URL}`,
      AxiosConfigNoAuth()
    );
    data.uberSettings && setAuthScreensLogo(data.uberSettings.authScreensLogo);
  }, []);

  const verifyAccountHandler = async (e) => {
    // stop the form from submitting
    e.preventDefault();

    try {
      const { data } = await axios.post(
        VERIFYACCOUNT_URL,
        { verificationToken },
        AxiosConfigNoAuth()
      );
      if (data.success) {
        notify({
          title: `${t(
            "You have successfully verified your account. You can now login."
          )}`,
          severity: NOTIFICATION_SEVERITY_SUCCESS,
          creationDate: "",
        });
      }
      navigate(FRONTEND_LOGIN_URL);
    } catch (error) {
      notify(pleaseRefreshError);
    }
  };

  return (
    <div className="lykiocss-generic-background lykiocss-forms-container">
      <div className="lykiocss-screen-center">
        <Form
          onSubmit={verifyAccountHandler}
          className="LykioForm lykiocss-white-background lykiocss-login-form-border lykiocss-form-size"
        >
          <Link to={HOME_URL}>
            <div className="lykiocss-center">
              <img
                className="lykiocss-small-login-logo"
                src={authScreensLogo ? authScreensLogo : lykio_logo}
                alt=""
              />
            </div>
          </Link>
          <h3 className="verifyaccount-screen__title">
            {t("Account Verification")}
          </h3>
          <Button type="submit" className="btn btn-primary">
            {t("Click to verify your account")}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default VerifyAccountScreen;
