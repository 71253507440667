import React, { useState } from 'react';
import t from "../../Utils/Translation";
import Modal from 'react-bootstrap/Modal';
import Button from '@mui/material/Button';
import PlaylistItemsSplider from './PlaylistItemsSplider';


function Playlist(props) {
    const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
    const playlist = props.playlist;
	console.log(playlist.groupsAssigned)
	console.log(props.groups)

    return ( <React.Fragment>
				<span>{playlist.name}</span>
				&nbsp;<Button onClick={()=>{handleShow()}}>{t("Preview")}</Button>
                <Modal size="lg" scrollable={true} centered show={show} onHide={handleClose} closeButton>
					<Modal.Header closeButton>
					<div className='lykiocss-title-light'>{playlist.name}</div>
					</Modal.Header>
					<Modal.Body>
					{playlist.units.length>0 ? 
					<div>
						<div className='lykiocss-subtitle-light'>{t("Included in this playlist")}</div>
						<PlaylistItemsSplider units={playlist.units}/>
					</div> 
					: <div className='lykiocss-center lykiocss-subtitle-light'>{t("No units added")}</div>}
					</Modal.Body>
					</Modal>
            </React.Fragment> );
}

export default Playlist;